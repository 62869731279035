import React from "react";
import { ICollaboratorBase, WebAppRole } from "../../../../shared/types";
import { Degree, Gender, PaymentType } from "../../../../shared/enums";
import { CollaboratorForm } from "../components";
// import { PublicHolidayForm } from "../components/";
// import { IPublicHolidays } from "../../../../../shared/types";

export const NewCollaborator = () => {
  const initialValues: ICollaboratorBase = {
    first_name: "",
    last_name: "",
    gender: Gender.Female,
    email: "",
    phoneNumber: "",
    phoneNumber2: "",
    address: {
      line1: "",
      line2: "",
      city: "Mérida",
      state: "Yucatán",
      zip: "",
      country: "México",
    },
    curp: "",
    imssNumber: "",
    rfcCode: "",
    emergencyContact: "",
    emergencyContactPhone: "",

    // webApp information
    role: WebAppRole.user,
    imgUrl: "",
    images: [],
    accessCode: "",
    isRegistered: false,
    password: "",
    isDisplayedWeb: true,
    textPresentation: "Hola soy un texto de presentación",
    registeredDate: undefined,
    // TODO: remove this
    vacationsTakenBefore2021: 0,

    // Job information
    col_code: "",
    col_numId: 0,
    // this could be computed
    isActive: true,
    startDate: new Date(),
    endDate: undefined,
    // TODO: remove this
    position: "Collaborador",
    coverShift: true,
    weeklyHours: 48,
    jobId: "",
    contractDate: undefined,
    hasIMSS: false,
    imssEnrollmentDate: undefined,
    // Payroll information
    // TODO: set in a parameter the value of the compensation
    paymentType: PaymentType.INFORMAL,
    additionalCompensation: 0, // based in the hours he goes
    // TODO: enum
    degree: Degree.HighSchool,
  };

  return <CollaboratorForm initialValues={initialValues} />;
};
