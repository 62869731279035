import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { IAttendanceRecord } from "../../../../shared/types";
import { useCollaboratorHook } from "../../../../hooks/useCollaboratorsHook";
import { AttendanceRecordService } from "../../../../services";
import { formatDatetime, getCurrentUcdDate } from "../../../../shared/helpers";
import { useBranchHook } from "../../../../hooks";

type AttendingCollaborator = {
  id: string;
  col_code: string;
  branch: string;
  startTime?: string;
  endTime?: string;
};

const BRANCHES = ["Urban", "Harbor", "Montejo"];

export const TodayCollaboratorsList = () => {
  const service = new AttendanceRecordService();
  const { getBranchNameById } = useBranchHook();

  const { currentAttendanceRecords } = useAppSelector(
    (state) => state.attendanceRecordsReducer
  );

  const { getCollaboratorCodeById } = useCollaboratorHook();

  const [collaborators, setCollaborators] = useState<AttendingCollaborator[]>(
    []
  );

  console.log({ currentAttendanceRecords });
  useEffect(() => {
    const fetchCollaborators = async () => {
      try {
        const response = await service.getAll({
          shiftDate: getCurrentUcdDate().toISOString(),
        });
        const todayAttendances = response.data;

        const todayCollaborators = todayAttendances.map(
          (record: IAttendanceRecord) => ({
            id: record.collaborator,
            col_code: getCollaboratorCodeById(record.collaborator)!,
            branch: getBranchNameById(record.clockInBranch)!,
            startTime: record.startTime,
            endTime: record.endTime,
          })
        );

        // Sort the collaborators first by branch and then by endTime
        const sortedCollaborators = todayCollaborators.sort((a, b) => {
          const branchSort =
            BRANCHES.indexOf(a.branch) - BRANCHES.indexOf(b.branch);
          if (branchSort !== 0) return branchSort;
          return a.endTime && !b.endTime ? 1 : -1;
        });

        setCollaborators(sortedCollaborators);
      } catch (error) {
        console.error("Error fetching today's collaborators:", error);
      }
    };

    fetchCollaborators();
  }, [currentAttendanceRecords, getCollaboratorCodeById]); // Removed getCollaboratorCodeById from dependencies
  return (
    <Box sx={{ m: 5 }}>
      <Typography variant="h5" gutterBottom>
        Today's Collaborators
      </Typography>
      {collaborators.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Branch</TableCell>
                <TableCell>Col Code</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {collaborators.map((collaborator) => (
                <TableRow
                  key={collaborator.id!}
                  style={{
                    backgroundColor: collaborator.endTime ? "coral" : "green",
                  }}
                >
                  <TableCell>{collaborator.branch}</TableCell>
                  <TableCell>{collaborator.col_code}</TableCell>
                  <TableCell>
                    {formatDatetime(collaborator.startTime)}
                  </TableCell>
                  <TableCell>{formatDatetime(collaborator.endTime)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1">No collaborators found today.</Typography>
      )}
    </Box>
  );
};
