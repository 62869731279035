import { Box, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  buildRangeParam,
  getCollaboratorShifts,
  getCollaboratorsShifts,
  isDevMode,
} from "../../../../shared/helpers";
import dayjs from "dayjs";
import {
  IAttendanceRecord,
  CollaboratorDayShift,
  AxiosParams,
  AttendanceReport,
} from "../../../../shared/types";
import {
  AttendanceRecordService,
  WeekShiftService,
} from "../../../../services";
import { getAttendanceRecordsReport } from "../../../../shared/helpers/attendance-records.helpers";
import { AttendanceReportTable } from "../components/AttendanceReportTable";
import { AttendanceRecordReportViewSelector } from "../components/AttendanceRecordReportViewSelector";
import { useCollaboratorHook } from "../../../../hooks";

export const AttendanceRecordReportsIndex = () => {
  const { getCollaboratorById } = useCollaboratorHook();
  const weekShiftService = new WeekShiftService();
  const attendanceRecordService = new AttendanceRecordService();

  const [attendanceRecords, setAttendanceRecords] = useState<
    IAttendanceRecord[]
  >([]);
  const [collaboratorsDayShifts, setCollaboratorsDayShifts] = useState<
    CollaboratorDayShift[]
  >([]);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
  const [collaboratorsIds, setCollaboratorsIds] = useState<string[]>([]);

  // Ref to track if data is being fetched
  const isFetching = useRef(false);

  const fetchWeekShifts = async (params: AxiosParams) => {
    const data = await weekShiftService.getAll(params);
    const weekShifts = data.data;
    const collaboratorsShifts = getCollaboratorsShifts(weekShifts);
    setCollaboratorsDayShifts(collaboratorsShifts);
  };

  const fetchAttendanceRecords = async (params: AxiosParams) => {
    const data = await attendanceRecordService.getAll(params);
    const attendanceRecords = data.data;
    setAttendanceRecords(attendanceRecords);
  };

  useEffect(() => {
    if (!startDate || !endDate || isFetching.current) return;

    const fetchData = async () => {
      isFetching.current = true; // Set fetching state to true
      await Promise.all([
        fetchWeekShifts({
          $startingDate: `$gte: ${startDate?.toISOString()}`,
          $endingDate: `$lte: ${endDate?.toISOString()}`,
        }),
        fetchAttendanceRecords({
          $shiftDate: buildRangeParam(startDate, endDate!),
        }),
      ]);
      isFetching.current = false; // Reset fetching state
    };

    fetchData();
  }, [startDate, endDate]);

  useEffect(() => {
    const uniqueCollaboratorIds = new Set();
    collaboratorsDayShifts.forEach((s) =>
      uniqueCollaboratorIds.add(s.collaboratorId)
    );
    setCollaboratorsIds([...uniqueCollaboratorIds] as string[]);
  }, [collaboratorsDayShifts]);

  const attendanceReports = useMemo(() => {
    return collaboratorsIds.map((collaboratorId) => ({
      collaboratorId,
      report: getAttendanceRecordsReport(
        attendanceRecords,
        collaboratorsDayShifts,
        collaboratorId,
        startDate?.toDate() || new Date(),
        endDate?.toDate() || new Date()
      ),
    }));
  }, [
    collaboratorsIds,
    attendanceRecords,
    collaboratorsDayShifts,
    startDate,
    endDate,
  ]);

  console.log("******RENDER*******", {
    collaboratorsDayShiftsLength: collaboratorsDayShifts.length,
    attendanceRecordsLength: attendanceRecords.length,
    collaboratorsIdsLength: collaboratorsIds.length,
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <pre>{JSON.stringify({ startDate, endDate }, null, 2)}</pre>
      <AttendanceRecordReportViewSelector
        handleSetStartDate={setStartDate}
        handleSetEndDate={setEndDate}
      />

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {attendanceReports.map(({ collaboratorId, report }) => (
          <Box key={collaboratorId}>
            <Typography>
              {getCollaboratorById(collaboratorId)?.col_code}
            </Typography>
            <AttendanceReportTable data={report} />
          </Box>
        ))}
      </Box>

      {isDevMode && (
        <Box>
          <pre>{JSON.stringify({ collaboratorsIds }, null, 2)}</pre>
        </Box>
      )}
    </Box>
  );
};
