import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { IOption } from "../../shared/types";

type Props = {
  options: IOption[];
  label: string;
  fullWidth?: boolean;
  withNoneOption?: boolean; // New prop to include a "None" option
  noneLabel?: string; // Custom label for "None" option
} & Omit<SelectProps, "onChange"> & {
    onChange: SelectProps["onChange"];
  };

export const CustomSelect: React.FC<Props> = ({
  options,
  label,
  onChange,
  fullWidth = true, // Default to true
  withNoneOption = false, // Default to not include "None" option
  noneLabel = "None", // Default "None" label
  ...selectProps
}) => {
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-label`}
        label={label}
        onChange={onChange}
        {...selectProps}
      >
        {/* Optional "None" option */}
        {withNoneOption && (
          <MenuItem value="">
            <em>{noneLabel}</em>
          </MenuItem>
        )}

        {/* Render the actual options */}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
