import { fireSwalError } from "../../helpers";
import { WebAppRole } from "../types";

export const isManager = (role?: WebAppRole) => {
  if (!role) return false;
  return role === WebAppRole.manager || role === WebAppRole.admin;
};

export const isAdmin = (role: WebAppRole) => {
  return role === WebAppRole.admin;
};

export const checkManagerAuthorization = async (
  role: WebAppRole | undefined
): Promise<boolean> => {
  if (!isManager(role)) {
    await fireSwalError("You are not authorized. You need to be a manager.");
    return false;
  }
  return true;
};

export const checkOwnerAuthorization = async (
  ownerId: string,
  collaboratorId: string
): Promise<boolean> => {
  if (ownerId !== collaboratorId) {
    await fireSwalError("You are not authorized. You are not the owner.");
    return false;
  }
  return true;
};

export const checkOwnerOrManagerAuthorization = async (
  role: WebAppRole | undefined,
  ownerId: string,
  userId: string
): Promise<boolean> => {
  console.log({ role, ownerId, userId });
  if (!isManager(role) && ownerId !== userId) {
    await fireSwalError(
      "You are not authorized. You need to be a manager or the owner."
    );
    return false;
  }
  return true;
};
