import React, { useState } from "react";

import { DashboardRouter } from "../../routes/DashboardRouter";
import { Sidebar } from "./components/sidebar/Sidebar";
import { Topbar } from "./components/topbar/Topbar";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { setMenuView, setSmallScreen } from "../../actions/dbUiActions";
import { useEffect } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { collaboratorsStartInitialLoading } from "../../actions/collaboratorActions";
import { Box, CircularProgress } from "@mui/material";
import { RootState } from "../../store/store";
import { collaboratorThunks, jobThunks } from "../../store/thunks";
import { branchThunks } from "../../store/thunks/branch.thunks";

export const DashboardLayout = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { isMenuOpen, isSmallScreen } = useSelector((state) => state.dbUi);
  // Assume loading is true on first render
  const { isLoading: isLoadingJobs } = useAppSelector((state) => state.jobs);
  const { isLoading: isLoadingBranches } = useAppSelector(
    (state) => state.branches
  );
  const { isInitialLoading: isLoadingCollaborators } = useAppSelector(
    (state) => state.collaborator
  );

  const isASmallScreen = useMediaQuery({ query: "(max-width: 900px)" });

  // @ts-ignore
  const { menuState } = useSelector((state: RootState) => state.dbUi);

  // State to track if the initial loading process has started
  const [loadingStarted, setLoadingStarted] = useState(false);
  // State to control rendering based on the loading process
  const [allowRender, setAllowRender] = useState(false);

  useEffect(() => {
    dispatch(setSmallScreen(isASmallScreen));
  }, [isASmallScreen, dispatch]);

  // set the view according both values
  useEffect(() => {
    dispatch(setMenuView(isMenuOpen, isSmallScreen));
  }, [isMenuOpen, isSmallScreen, dispatch]);

  useEffect(() => {
    dispatch(collaboratorThunks.fetchItems());
    dispatch(jobThunks.fetchItems());
    dispatch(branchThunks.fetchItems());
    setLoadingStarted(true); // Mark that loading has started
  }, [dispatch]);

  useEffect(() => {
    if (
      loadingStarted &&
      !isLoadingCollaborators &&
      !isLoadingJobs &&
      !isLoadingBranches
    ) {
      setAllowRender(true); // Allow rendering only after loading has completed
    }
  }, [
    isLoadingCollaborators,
    loadingStarted,
    isLoadingJobs,
    isLoadingBranches,
  ]);

  if (!allowRender) {
    return <CircularProgress />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh", // Full viewport height
      }}
    >
      {/* Topbar */}
      <Box>
        <Topbar />
      </Box>

      {/* Main Content Area */}
      <Box sx={{ display: "flex", width: "100%" }}>
        {/* Sidebar */}
        {menuState !== "close" && (
          <Box
            sx={{
              flexShrink: 1, // Prevent shrinking below its content
              flexGrow: 0, // Allows the sidebar to grow if needed
              backgroundColor: "#dfeef5",
              padding: 1,
              maxWidth: "200px",
              minHeight: "100vh",
              position: "sticky",
              top: 0,
              height: "calc(100vh - 64px)", // Adjust based on Topbar height
              overflowY: "auto", // Allow scrolling within sidebar if needed
              minWidth: menuState === "open" ? "200px" : "80px",
            }}
          >
            <Sidebar />
          </Box>
        )}

        {/* Dashboard Main */}
        <Box
          sx={{
            flexGrow: 1, // Ensures this takes up the remaining space
            overflow: "hidden", // As per your requirement
            padding: "2rem",
            backgroundColor: "#ebe5e5",
          }}
        >
          <DashboardRouter />
        </Box>
      </Box>
    </Box>
  );
};
