import React from "react";
import { Field, FieldProps } from "formik";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const SimpleFormikDatePicker: React.FC<Props> = ({
  name,
  label,
  disabled,
  fullWidth,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={disabled}
              label={label}
              value={field.value ? dayjs(field.value) : null}
              onChange={(date) => {
                form.setFieldValue(
                  name,
                  date ? dayjs(date).toISOString() : null
                );
              }}
              slots={{
                textField: TextField,
              }}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  fullWidth: fullWidth,
                  error: meta.touched && !!meta.error,
                  helperText: meta.touched && meta.error,
                  ...props,
                },
                actionBar: {
                  actions: ["clear"],
                },
              }}
            />
          </LocalizationProvider>
        );
      }}
    </Field>
  );
};
