import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { Typography, Box, Paper, Button } from "@mui/material";
import { getFormattedMxDate } from "../../../../helpers/dateHelpers";
import LabelValuePair from "../../../../components/labelValuePair/LabelValuePair";
import { getPriorityColor } from "../../../../helpers/tasksHelpers";
import { LabelValueAvatars } from "../../../../components/labelValuePair/LabelValueAvatars";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import {
  startDeletingTask,
  startUpdatingTask,
} from "../../../../store/thunks/taskThunks";
import {
  Task,
  TaskPriority,
  TaskStatus,
  TaskTag,
} from "../../../../shared/types/Task";
import { RowFlexBoxContainer } from "./TaskViewComponents/RowFlexBoxContainer";
import { PropertyLabel } from "./TaskViewComponents/PropertyLabel";
import { ColumnFlexBoxContainer } from "./TaskViewComponents/ColumnFlexboxContainer";
import { EditableFieldString } from "./TaskViewComponents/EditableFieldString";
import { EditableDateFields } from "./TaskViewComponents/EditableDateField";
import { EditableSelectField } from "./TaskViewComponents/EditableSelectField";
import { EditableTagsField } from "./TaskViewComponents/EditableTagsField";
import { EditableAssignees } from "./TaskViewComponents/EditableAssignees";
import { EditableArrayField } from "./TaskViewComponents/EditableArrayField";
import { EditableAuthor } from "./TaskViewComponents/EditableAuthor";
import { EditableActivity } from "./TaskViewComponents/EditableActivity";
import { AddNewActivity } from "./TaskViewComponents/AddNewActivity";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { EditableCheckboxField } from "./TaskViewComponents/EditableCheckbox";
import { getSortedCollaborators } from "../../../../helpers";

export const TaskView = () => {
  const { id } = useParams();

  const { tasks } = useAppSelector((state) => state.taskReducer);
  const navigate = useNavigate();
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);
  const sortedCollaborators = getSortedCollaborators(collaborators);

  const task = tasks.find((task) => task._id === id);
  const [workingTask, setworkingTask] = useState<Task>(task!);
  const dispatch = useAppDispatch();
  const [showNewActivity, setshowNewActivity] = useState(false);

  useEffect(() => {
    setworkingTask(task!);
  }, [task, id]);

  const handleSave = async (inputTask: Task) => {
    try {
      const { status, completedAt } = inputTask;
      if (status === TaskStatus.Completed || status === TaskStatus.Canceled) {
        if (!inputTask.completedAt) {
          await Swal.fire({
            icon: "error",
            text: "CompletedAt must be set",
          });
          console.log({ status: task!.status });
          return;
        }
      } else {
        if (completedAt && !workingTask.completedAt) {
          // If completedAt is being set, change the status to Completed
          inputTask.status = TaskStatus.Completed;
        } else {
          // If completedAt is not being set, set it to null
          inputTask.completedAt = null;
        }
      }
      const updatedTask = await dispatch(startUpdatingTask(inputTask, id!));
      console.log({ inputTask, updatedTask });

      setworkingTask(updatedTask!);
    } catch (error) {}
  };

  const handleDelete = async () => {
    try {
      await dispatch(startDeletingTask(id!));
      navigate(`/dashboard/tasks/`); // Updated path
    } catch (error) {}
  };

  const handleAddNewActivity = () => {
    setshowNewActivity(true);
  };

  if (!task) {
    return <div>Task not found</div>;
  }

  return (
    <>
      <Paper
        elevation={3}
        style={{
          borderRadius: 8,
          border: "1px solid grey",
          padding: 16,
          marginBottom: 16,
        }}
      >
        {/* header */}
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Use space-between to push items to both ends
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <EditableAuthor
                task={workingTask}
                field="author"
                key="author"
                onSave={handleSave}
              />
              <Typography variant="h5">
                <span style={{ color: "#333" }}>#{workingTask.number}</span> -{" "}
                <span>
                  <EditableFieldString
                    task={workingTask}
                    field="title"
                    key="title"
                    onSave={handleSave}
                    fontColor="#666"
                  />
                </span>
              </Typography>
            </Box>
            <Button onClick={handleDelete}>
              <DeleteIcon style={{ color: "red" }} />
            </Button>
          </Box>
        </Box>
        {/* Content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: 2,
          }}
        >
          <RowFlexBoxContainer>
            <PropertyLabel label="Requested At" />
            <EditableDateFields
              task={workingTask}
              field="requestedAt"
              key="requestedAt"
              onSave={handleSave}
              isRequiredField={true}
            />
          </RowFlexBoxContainer>
          <RowFlexBoxContainer>
            <PropertyLabel label="Status" />
            <EditableSelectField
              task={workingTask}
              field="status"
              key="status"
              onSave={handleSave}
              enumValues={Object.values(TaskStatus)}
            />
          </RowFlexBoxContainer>
          <RowFlexBoxContainer>
            <PropertyLabel label="Priority" />
            <EditableSelectField
              task={workingTask}
              field="priority"
              key="priority"
              onSave={handleSave}
              enumValues={Object.values(TaskPriority)}
              fontColor={getPriorityColor(workingTask.priority)}
            />
          </RowFlexBoxContainer>
          <RowFlexBoxContainer>
            <PropertyLabel label="Is Restricted View" />
            <EditableCheckboxField
              task={workingTask}
              field="isRestrictedView"
              key="isRestrictedView"
              onSave={handleSave}
            />
          </RowFlexBoxContainer>
          <RowFlexBoxContainer>
            <PropertyLabel label="Tags" />
            <EditableTagsField
              task={workingTask}
              key="tags"
              onSave={handleSave}
              enumValues={Object.values(TaskTag)}
            />
          </RowFlexBoxContainer>
          <ColumnFlexBoxContainer>
            <PropertyLabel label="Description" />
            <RowFlexBoxContainer>
              <EditableFieldString
                task={workingTask}
                field="description"
                key="description"
                onSave={handleSave}
              />
            </RowFlexBoxContainer>
          </ColumnFlexBoxContainer>
          <ColumnFlexBoxContainer>
            <PropertyLabel label="Assignees" />
            <RowFlexBoxContainer>
              <EditableAssignees
                onSave={handleSave}
                task={workingTask}
                key="assignees"
              />
            </RowFlexBoxContainer>
          </ColumnFlexBoxContainer>
          <RowFlexBoxContainer>
            <PropertyLabel label="Due Date" />
            <EditableDateFields
              task={workingTask}
              field="dueDate"
              key="dueDate"
              onSave={handleSave}
            />
          </RowFlexBoxContainer>
          <RowFlexBoxContainer>
            <PropertyLabel label="Completed At" />
            <EditableDateFields
              task={workingTask}
              field="completedAt"
              key="completedAt"
              onSave={handleSave}
            />
          </RowFlexBoxContainer>
          <ColumnFlexBoxContainer>
            <PropertyLabel label="Completion Criteria" />
            <EditableArrayField
              task={workingTask}
              field="completionCriteria"
              key="completionCriteria"
              onSave={handleSave}
            />
          </ColumnFlexBoxContainer>
          <ColumnFlexBoxContainer>
            <PropertyLabel label="Notes" />
            <RowFlexBoxContainer>
              <EditableFieldString
                task={workingTask}
                field="notes"
                key="notes"
                onSave={handleSave}
              />
            </RowFlexBoxContainer>
          </ColumnFlexBoxContainer>
          <LabelValuePair
            label="Created At"
            value={getFormattedMxDate(workingTask.createdAt)}
          />
          <LabelValueAvatars
            collaboratorsIds={[workingTask.createdBy!]}
            collaborators={sortedCollaborators}
            label="CreatedBy"
            key="createdBy"
          />
          <LabelValuePair
            label="Updated At"
            value={getFormattedMxDate(workingTask.updatedAt!)}
          />
          <LabelValueAvatars
            collaboratorsIds={[workingTask.updatedBy!]}
            collaborators={sortedCollaborators}
            label="UpdatedBy"
            key="updatedBy"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 1,
            }}
          >
            <Typography
              variant="body1"
              style={{ marginBottom: 1, fontWeight: "bold" }}
            >
              Activities:
            </Typography>

            {workingTask.activities &&
              workingTask.activities.map((activity) => (
                <EditableActivity
                  task={workingTask}
                  activityId={activity._id!}
                  onSave={handleSave}
                  key={activity._id}
                />
              ))}
            <Button onClick={handleAddNewActivity}>Add new Activity</Button>
            {showNewActivity && (
              <AddNewActivity
                handleSave={handleSave}
                setshowNewTaskForm={setshowNewActivity}
                workingTask={workingTask}
              />
            )}
          </Box>
        </Box>
      </Paper>
    </>
  );
};
