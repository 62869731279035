import hvpAuthenticatedApi from "../api/hvpAuthenticatedApi";
import {
  ApiResponseCreate,
  ApiResponseDelete,
  ApiResponseList,
  ApiResponseOne,
  ApiResponseUpdate,
  AxiosParams,
} from "../shared/types";

export class BaseService<T, B = T> {
  protected API_URL: string;

  constructor(API_URL: string) {
    this.API_URL = API_URL;
  }

  async getAll(params?: AxiosParams): Promise<ApiResponseList<T>> {
    const response = await hvpAuthenticatedApi.get<ApiResponseList<T>>(
      this.API_URL,
      { params }
    );
    return response.data;
  }

  async getById(id: string): Promise<ApiResponseOne<T>> {
    const response = await hvpAuthenticatedApi.get<ApiResponseOne<T>>(
      `${this.API_URL}/${id}`
    );
    return response.data;
  }

  async create(resource: B): Promise<ApiResponseCreate<T>> {
    const response = await hvpAuthenticatedApi.post<ApiResponseCreate<T>>(
      this.API_URL,
      resource
    );
    return response.data;
  }

  async update(id: string, resource: B): Promise<ApiResponseUpdate<T>> {
    const response = await hvpAuthenticatedApi.patch<ApiResponseUpdate<T>>(
      `${this.API_URL}/${id}`,
      resource
    );
    return response.data;
  }

  async delete(id: string): Promise<ApiResponseDelete> {
    const response = await hvpAuthenticatedApi.delete<ApiResponseDelete>(
      `${this.API_URL}/${id}`
    );
    return response.data;
  }
}
