import React from "react";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Divider,
  Button,
  Box,
} from "@mui/material";
import { ISalaryData } from "../../../../../shared/types";
import { ImssRatesData } from "./ImssRatesData";
import { useNavigate } from "react-router-dom";
import { fireSwalError } from "../../../../../helpers";

type Props = {
  data: ISalaryData;
};
export const WageDataCard = ({ data }: Props) => {
  const navigate = useNavigate();
  const { year, minimumWage, uma, ocupationalRisk } = data;

  return (
    <Card>
      <CardContent>
        {/* General Information */}
        <Box display="flex" justifyContent="space-around" alignItems="center">
          <Typography variant="h4" gutterBottom>
            Wage Data for {year}
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              navigate(`/dashboard/parameters/salary-data/edit/${data.id}`)
            }
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => fireSwalError("Is not possible to delete")}
          >
            Delete
          </Button>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Minimum Wage</Typography>
            <Typography variant="body1">{`$${minimumWage.toFixed(
              2
            )}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">UMA</Typography>
            <Typography variant="body1">{`$${uma.toFixed(2)}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Occupational Risk</Typography>
            <Typography variant="body1">{`${(ocupationalRisk * 100).toFixed(
              2
            )}%`}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />
        <ImssRatesData salaryData={data} />
      </CardContent>
    </Card>
  );
};
