import { useMemo, useState } from "react";

import { sortBy as lodashSortBy } from "lodash";
import { useAppSelector } from "../../../../hooks";
import { WebAppRole } from "../../../../shared/types";
import { PaymentType } from "../../../../shared/enums";

export type CollaboratorFilters = {
  role?: WebAppRole;
  isRegistered?: true | false | undefined;
  isActive?: boolean;
  paymentType?: PaymentType;
  job?: string;
};

export type CollaboratorSortOption =
  | "col_code"
  | "first_name"
  | "job_position"
  | "role"
  | "startDate"
  | "paymentType";

export const useFilteredSortedCollaboratorsHook = () => {
  const [filters, setFilters] = useState<CollaboratorFilters>({
    isActive: true, // default state
  });
  const [sortBy, setSortBy] = useState<CollaboratorSortOption>("col_code");

  const { collaborators } = useAppSelector((state) => state.collaborator);
  const { jobs } = useAppSelector((state) => state.jobs);

  const updateFilter = (field: keyof CollaboratorFilters, value: any) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const displayCollaborators = collaborators.filter((collaborator) => {
    const { isActive, isRegistered, paymentType, role, job } = filters;
    const filterChecks = {
      isActive: () =>
        isActive === undefined ||
        isActive === false ||
        collaborator.isActive === true,
      role: () => !role || collaborator.role === role,
      isRegistered: () =>
        isRegistered === undefined ||
        collaborator.isRegistered === isRegistered,
      paymentType: () =>
        !paymentType || collaborator.paymentType === paymentType,
      job: () => !job || collaborator.jobId === job,
    };

    return Object.values(filterChecks).every((check) => check());
  });

  const sortedCollaborators = useMemo(() => {
    return lodashSortBy(displayCollaborators, (c) => {
      switch (sortBy) {
        case "col_code":
          return c.col_code;
        case "first_name":
          return c.first_name;
        case "job_position":
          const job = jobs.find((job) => job.id === c.jobId);
          return job ? job.sortingOrder : Infinity;
        case "role":
          return c.role;
        case "startDate":
          return c.startDate || new Date(0);
        case "paymentType":
          return c.paymentType || "";
        default:
          return c.col_code;
      }
    });
  }, [displayCollaborators, sortBy, jobs]);

  return {
    filters,
    setFilters,
    sortBy,
    setSortBy,
    sortedCollaborators,
    updateFilter,
  };
};
