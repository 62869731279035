import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { differenceInMinutes } from "date-fns";
import { useCollaboratorHook } from "../../../../hooks/useCollaboratorsHook";
import { useNavigate } from "react-router-dom";
import { IAttendanceRecord } from "../../../../shared/types";
import { useAuthHook, useBranchHook } from "../../../../hooks";
import { BRANCH_COORDINATES } from "../../../../shared/constants/branch.constants";

type Props = {
  attendanceRecords: IAttendanceRecord[];
};

const findNearestDistance = (lat: number, lon: number) => {
  const distances = BRANCH_COORDINATES.map((coord) =>
    calculateDistance(lat, lon, coord.lat, coord.lng)
  );
  return Math.min(...distances); // Returns the shortest distance
};

// Haversine formula to calculate distance in meters
const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const toRad = (value: number) => (value * Math.PI) / 180;
  const R = 6371000; // Radius of the Earth in meters
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in meters
  return distance;
};

const isWithinDistance = (lat: number, lon: number, distance: number) => {
  return BRANCH_COORDINATES.some(
    (coord) => calculateDistance(lat, lon, coord.lat, coord.lng) < distance
  );
};

// Start Latitude: 37.3959728
// Start Longitude: -5.9996942

export const AttendanceRecordsTable = ({ attendanceRecords }: Props) => {
  const { isAdmin } = useAuthHook();
  const navigate = useNavigate();
  const { getCollaboratorCodeById } = useCollaboratorHook();
  const { getBranchNameById } = useBranchHook();

  const handleClick = (id: string) => {
    navigate(`/dashboard/attendance-records/${id}`);
  };

  const calculateDuration = (
    startTime?: string | Date,
    endTime?: Date | string
  ) => {
    if (!startTime || !endTime) return "N/A";
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diffInMinutes = differenceInMinutes(end, start);
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;
    return `${hours}h ${minutes}m`;
  };
  const sortedRecords = [...attendanceRecords].sort((a, b) => {
    const timeA = a.startTime ? new Date(a.startTime).getTime() : 0;
    const timeB = b.startTime ? new Date(b.startTime).getTime() : 0;
    return timeB - timeA;
  });

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Branch</TableCell>
            <TableCell>Col Code</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>End Time</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Start Location</TableCell>
            <TableCell>End Location</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRecords.map((record) => {
            const startLocationDistance =
              record.startLatitude !== undefined &&
              record.startLongitude !== undefined
                ? findNearestDistance(
                    record.startLatitude,
                    record.startLongitude
                  )
                : null;

            const endLocationDistance =
              record.endLatitude !== undefined &&
              record.endLongitude !== undefined
                ? findNearestDistance(record.endLatitude, record.endLongitude)
                : null;

            const isWithinStartLocationDistance =
              record.startLatitude !== undefined &&
              record.startLongitude !== undefined &&
              isWithinDistance(
                record.startLatitude,
                record.startLongitude,
                500
              );

            const isWithinEndLocationDistance =
              record.endLatitude !== undefined &&
              record.endLongitude !== undefined &&
              isWithinDistance(record.endLatitude, record.endLongitude, 500);

            const startLocationColor = isWithinStartLocationDistance
              ? "green"
              : "red";

            const endLocationColor = isWithinEndLocationDistance
              ? "green"
              : "red";

            const handleMapClick = (latitude: number, longitude: number) => {
              if (latitude && longitude) {
                const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
                window.open(googleMapsUrl, "_blank");
              }
            };

            return (
              <TableRow
                key={record.id}
                style={{
                  backgroundColor: record.endTime ? "inherit" : "coral",
                }}
              >
                <TableCell>
                  {getBranchNameById(record.clockInBranch) || "N/A"}
                </TableCell>
                <TableCell>
                  {getCollaboratorCodeById(record.collaborator) || "N/A"}
                </TableCell>
                <TableCell>
                  {record.startTime
                    ? new Date(record.startTime).toLocaleString()
                    : "N/A"}
                </TableCell>
                <TableCell>
                  {record.endTime
                    ? new Date(record.endTime).toLocaleString()
                    : "N/A"}
                </TableCell>
                <TableCell>
                  {calculateDuration(record?.startTime, record?.endTime)}
                </TableCell>
                <TableCell>
                  {isAdmin ? (
                    <Tooltip
                      title={
                        !isWithinStartLocationDistance &&
                        startLocationDistance !== null
                          ? `${startLocationDistance.toFixed(2)} meters away`
                          : ""
                      }
                    >
                      <div
                        style={{
                          width: 20,
                          height: 20,
                          borderRadius: "50%",
                          backgroundColor: startLocationColor,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleMapClick(
                            record.startLatitude || 0,
                            record.startLongitude || 0
                          )
                        }
                      />
                    </Tooltip>
                  ) : (
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        backgroundColor: startLocationColor,
                      }}
                    />
                  )}
                </TableCell>

                <TableCell>
                  {isAdmin ? (
                    <Tooltip
                      title={
                        !isWithinEndLocationDistance &&
                        endLocationDistance !== null
                          ? `${(endLocationDistance / 1000).toFixed(0)} km away`
                          : ""
                      }
                    >
                      <div
                        style={{
                          width: 20,
                          height: 20,
                          borderRadius: "50%",
                          backgroundColor: endLocationColor,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleMapClick(
                            record.endLatitude || 0,
                            record.endLongitude || 0
                          )
                        }
                      />
                    </Tooltip>
                  ) : (
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        backgroundColor: endLocationColor,
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleClick(record.id!)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
