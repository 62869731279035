import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { DateSelector } from "../../../components";
import {
  getMonday,
  getStringDateAsDDMMYYYY,
  getWeekDatesByDate,
} from "../../../shared/helpers";
import { IWeekShiftBase } from "../../../shared/types";

import { useAppSelector, useJobsHook } from "../../../hooks";
import { useFormStatusHook } from "../../../shared/hooks/useFormStatus.hook";
import { WeekShiftsTable } from "./components";
import { DragDropContext } from "react-beautiful-dnd";
import { useWeekShiftsHook } from "../../../shared/hooks";
import dayjs from "dayjs";

export const WeekShiftsIndex: React.FC = () => {
  // *****GLOBALS***** //

  // *****STATES***** //
  const [date, setDate] = useState(getMonday());
  const [weekShift, setWeekShift] = useState<IWeekShiftBase>();
  const { collaborators } = useAppSelector((state) => state.collaborator);

  // *****CUSTOM HOOKS***** //
  const { handleUnsavedChanges } = useFormStatusHook();
  const { expandShifts, fetchWeekShift } = useWeekShiftsHook();
  const { sortedCollaboratorsByJob: sortedCollaboratorsByJob } = useJobsHook();

  // *****COMPUTED VALUES***** //
  const expandedShifts = expandShifts(weekShift?.shifts || [], collaborators);
  const displayCollaborators = useMemo(() => {
    return sortedCollaboratorsByJob.filter((collaborator) => {
      const hasStarted =
        !collaborator.startDate ||
        dayjs(collaborator.startDate).isBefore(weekShift?.endingDate);
      const hasNotEnded =
        !collaborator.endDate ||
        dayjs(collaborator.endDate).isAfter(weekShift?.startingDate);

      return hasStarted && hasNotEnded && collaborator.coverShift;
    });
  }, [
    sortedCollaboratorsByJob,
    weekShift?.startingDate,
    weekShift?.endingDate,
  ]);

  // *****HANDLERS***** //
  const handleSetDate = async (date: Date) => {
    await handleUnsavedChanges(() => {
      setDate(date);
    });
  };
  console.log({ weekshiftsindexdate: date });

  // *****HELPERS***** //

  // *****EFFECTS***** //

  useEffect(() => {
    fetchWeekShift(date, setWeekShift);
  }, [date]);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        mb={2}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: 2,
        }}
      >
        <Typography variant="h5" fontWeight="bold" flex={1}>
          Week shifts for {getStringDateAsDDMMYYYY(date.toISOString())}
        </Typography>
        <Box flex={1}>
          <DateSelector date={date} setDate={handleSetDate} />
        </Box>
      </Box>
      <Divider />
      {weekShift ? (
        <Box mt={3}>
          <DragDropContext onDragEnd={() => {}}>
            <WeekShiftsTable
              shifts={expandedShifts}
              weekDates={getWeekDatesByDate(date.toISOString())}
              collaborators={displayCollaborators}
              editable={false}
            />
          </DragDropContext>
        </Box>
      ) : (
        <CircularProgress />
      )}
      <pre>{JSON.stringify(weekShift, null, 2)}</pre>
    </Box>
  );
};
