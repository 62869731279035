import React from "react";
import { Route, Routes } from "react-router-dom";

import { EditPublicHolidays, NewPublicHolidays, PublicHolidaysIndex } from ".";

export const PublicHolidaysRouter = () => {
  return (
    <Routes>
      <Route index element={<PublicHolidaysIndex />} />
      <Route path="edit/:id" element={<EditPublicHolidays />} />

      <Route path="new" element={<NewPublicHolidays />} />
      <Route path="*" element={<PublicHolidaysIndex />} />
    </Routes>
  );
};
