import React from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { MenuRoute, ModuleMenu } from "../../../../components";
import { JobsRouter } from "./";

export const JobsLayout = () => {
  const routes: MenuRoute[] = [
    { name: "List", path: "" },
    { name: "New", path: "new" },
  ];

  const isLoading = false;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h4">Jobs</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ModuleMenu routes={routes} />
      </Grid>
      <Grid item xs={12}>
        {isLoading ? <CircularProgress /> : <JobsRouter />}
      </Grid>
    </Grid>
  );
};
