import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { ICollaborator } from "../../../../shared/types";
import { collaboratorThunks } from "../../../../store/thunks";
import { CustomAvatar } from "../../../../components/avatar/CustomAvatar";
import { checkManagerAuthorization } from "../../../../shared/helpers";

type Props = { collaborators: ICollaborator[] };
export const CollaboratorsTable = ({ collaborators }: Props) => {
  const { jobs } = useAppSelector((state) => state.jobs);
  const { user } = useAppSelector((state) => state.authReducer);

  const state = useAppSelector((state) => state);
  console.log({ state });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleDelete = async (id: string) => {
    if (await checkManagerAuthorization(user?.role)) {
      dispatch(collaboratorThunks.deleteItem(id));
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
          <TableCell sx={{ fontWeight: "bold" }}>Code</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Position</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Role</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Active</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Registered</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {collaborators.map((collaborator) => {
          const job = jobs.find((job) => job.id === collaborator.jobId);
          return (
            <TableRow
              key={collaborator.id}
              sx={{
                backgroundColor: collaborator.isActive ? "inherit" : "#ffebee",
              }}
            >
              <TableCell>
                <Box display="flex" alignItems="center" gap={1}>
                  {collaborator.col_code}{" "}
                  <CustomAvatar collaborator={collaborator} />
                </Box>
              </TableCell>
              <TableCell>{`${collaborator.first_name} ${collaborator.last_name}`}</TableCell>
              <TableCell>{job?.title || "N/A"}</TableCell>
              <TableCell>{collaborator.role}</TableCell>
              <TableCell>
                {renderStatusCircle(collaborator.isActive)}
                {collaborator.isActive ? "Active" : "Inactive"}
              </TableCell>
              <TableCell>{collaborator.isRegistered ? "Yes" : "No"}</TableCell>
              <TableCell>
                <IconButton
                  size="small"
                  onClick={async () => {
                    navigate(`${collaborator.id}`);
                  }}
                  sx={{ color: "#1976d2" }}
                >
                  <VisibilityIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={async () => {
                    if (await checkManagerAuthorization(user?.role)) {
                      navigate(`edit/${collaborator.id}`);
                    }
                  }}
                  sx={{ color: "#43a047" }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleDelete(collaborator.id)}
                  sx={{ color: "#d32f2f" }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const renderStatusCircle = (isActive: boolean) => (
  <Box
    component="span"
    sx={{
      width: 10,
      height: 10,
      borderRadius: "50%",
      display: "inline-block",
      backgroundColor: isActive ? "green" : "red",
      marginRight: 1,
    }}
  />
);
