import React from "react";

import { Box, Grid, Typography, TableContainer, Paper } from "@mui/material";
import { CollaboratorListFilters, CollaboratorsListSort } from "./components";
import { CollaboratorsTable } from "./components/CollaboratorsTable";
import { useFilteredSortedCollaboratorsHook } from "./hooks/useFilteredSortedCollaboratorsHook";

export const CollaboratorsIndex = () => {
  const { filters, updateFilter, sortBy, setSortBy, sortedCollaborators } =
    useFilteredSortedCollaboratorsHook();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box>
        <Typography variant="h4">Collaborators</Typography>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <CollaboratorListFilters
            filters={filters}
            updateFilter={updateFilter}
          />
          <CollaboratorsListSort sortBy={sortBy} setSortBy={setSortBy} />
        </Grid>
      </Box>

      <Box>
        <TableContainer component={Paper}>
          <CollaboratorsTable collaborators={sortedCollaborators} />
        </TableContainer>
      </Box>
    </Box>
  );
};
