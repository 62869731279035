export const transformCloudinaryUrlTo4x3 = (url?: string): string => {
  if (!url) return "";

  // Check if the URL is valid and contains Cloudinary's base URL
  const cloudinaryBaseUrl = "https://res.cloudinary.com/";
  if (!url.startsWith(cloudinaryBaseUrl)) {
    throw new Error("Invalid Cloudinary URL");
  }

  // Split the URL to insert transformation parameters
  const urlParts = url.split("/");

  // Find the index of the "upload" segment
  const uploadIndex = urlParts.indexOf("upload");

  // If "upload" is found, insert the transformation parameters
  if (uploadIndex !== -1) {
    // Insert transformation parameters before the "upload" segment
    urlParts.splice(uploadIndex + 1, 0, "c_crop,ar_4:3");
  }

  // Join the parts back together to form the new URL
  return urlParts.join("/");
};
