import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  buildRangeParam,
  getCollaboratorShifts,
  isDevMode,
} from "../../../../shared/helpers";
import dayjs from "dayjs";
import {
  IAttendanceRecord,
  CollaboratorDayShift,
  AxiosParams,
  AttendanceReport,
} from "../../../../shared/types";
import { useAuthHook } from "../../../../hooks";
import {
  AttendanceRecordService,
  WeekShiftService,
} from "../../../../services";
import { getAttendanceRecordsReport } from "../../../../shared/helpers/attendance-records.helpers";
import { AttendanceReportTable } from "../components/AttendanceReportTable";
import { AttendanceRecordReportViewSelector } from "../components/AttendanceRecordReportViewSelector";

export const AttendanceRecordReport = () => {
  // *****GLOBALS***** //
  const weekShiftService = new WeekShiftService();
  const attendanceRecordService = new AttendanceRecordService();

  // *****SELECTORS***** //

  // *****STATES***** //
  const [attendanceRecords, setAttendanceRecords] = useState<
    IAttendanceRecord[]
  >([]);

  const [collaboratorsDayShifts, setCollaboratorsDayShifts] = useState<
    CollaboratorDayShift[]
  >([]);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
  const [processedData, setProcessedData] = useState<AttendanceReport[]>([]);
  // *****CUSTOM HOOKS***** //
  const { currentUserId } = useAuthHook();

  // *****USE EFFECTS***** //s

  const fetchWeekShifts = async (params: AxiosParams) => {
    const data = await weekShiftService.getAll(params);
    const weekShifts = data.data;

    const collaboratorShifts = getCollaboratorShifts(
      weekShifts,
      currentUserId!
    );

    setCollaboratorsDayShifts(collaboratorShifts);
  };
  const fetchAttendanceRecords = async (params: AxiosParams) => {
    const data = await attendanceRecordService.getAll(params);
    const attendanceRecords = data.data;

    setAttendanceRecords(attendanceRecords);
  };

  useEffect(() => {
    if (!startDate) return;

    fetchWeekShifts({
      $startingDate: `$gte: ${startDate?.toISOString()}`,
      $endingDate: `$lte: ${endDate?.toISOString()}`,
    });
    fetchAttendanceRecords({
      $shiftDate: buildRangeParam(startDate, endDate!),
      collaborator: currentUserId,
    });
  }, [startDate, endDate]);

  useEffect(() => {
    const processedData = getAttendanceRecordsReport(
      attendanceRecords,
      collaboratorsDayShifts,
      currentUserId!,
      startDate?.toDate() || new Date(),
      endDate?.toDate() || new Date()
    );
    setProcessedData(processedData);
  }, [attendanceRecords, collaboratorsDayShifts]);

  // fetch attendance records by dates
  // be able to select dates and periods... Like stats
  return (
    <Box>
      <AttendanceRecordReportViewSelector
        handleSetStartDate={setStartDate}
        handleSetEndDate={setEndDate}
      />

      <AttendanceReportTable data={processedData} />
      {isDevMode && (
        <Box>
          <pre>{JSON.stringify(startDate, null, 2)}</pre>
          <pre>{JSON.stringify(endDate, null, 2)}</pre>
          <pre>{JSON.stringify({ processedData }, null, 2)}</pre>
          <pre>{JSON.stringify({ collaboratorsDayShifts }, null, 2)}</pre>
          <pre>{JSON.stringify({ attendanceRecords }, null, 2)}</pre>
        </Box>
      )}
    </Box>
  );
};
