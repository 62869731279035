import React from "react";
import { Box, TextField, Button } from "@mui/material";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";

type Props = {
  year: number;
  setYear: React.Dispatch<React.SetStateAction<number>>;
};

export const YearSelector = ({ year, setYear }: Props) => {
  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newYear = parseInt(event.target.value, 10);
    if (!isNaN(newYear)) {
      setYear(newYear);
    }
  };

  const handleYearChangeByNumber = (number: number) => {
    setYear((prevYear: number) => prevYear + number);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, my: 2 }}>
      <Button onClick={() => handleYearChangeByNumber(-1)} sx={{ height: 56 }}>
        <NavigateBefore />
      </Button>
      <TextField
        type="number"
        value={year}
        onChange={handleYearChange}
        inputProps={{ min: 1900, max: 2100 }}
      />
      <Button onClick={() => handleYearChangeByNumber(1)} sx={{ height: 56 }}>
        <NavigateNext />
      </Button>
    </Box>
  );
};
