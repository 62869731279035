import dayjs, { Dayjs } from "dayjs";
import dayjsWithPlugins from "../config/dayjs";
import { MEXICO_TIME_ZONE } from "../shared";

export const OLDDATE = new Date(1900, 0, 1);
export const FUTUREDATE = new Date(2100, 11, 31);

export const getFormattedMxDate = (date?: Date | string) => {
  if (!date) return "N/A";
  date = new Date(date);

  return date.toLocaleDateString("es-MX", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const getFormattedDate = (date?: Date | string) => {
  if (!date) return "N/A";
  date = new Date(date);

  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const parseDateString = (dateString: string): Date | null => {
  const [day, month, year] = dateString.split("/");

  // Check if the date components are valid numbers
  if (!day || !month || !year) {
    console.error("Invalid date string format");
    return null;
  }

  const dateObject = new Date(
    parseInt(year),
    parseInt(month) - 1,
    parseInt(day)
  );

  // Check if the resulting Date object is valid
  if (isNaN(dateObject.getTime())) {
    console.error("Invalid date");
    return null;
  }

  return dateObject;
};

export const getFormattedMxDatetime = (date?: Date | string) => {
  if (!date) return "N/A";
  date = new Date(date);

  return date.toLocaleString("es-MX", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const getDurationInHours = (
  start: Date | string,
  end?: Date | string
) => {
  if (!end) return 0;
  start = new Date(start);
  end = new Date(end);

  const durationInHours = (end.getTime() - start.getTime()) / 1000 / 60 / 60;
  return +durationInHours.toFixed(2);
};

export const isSameDate = (
  date1: Date | undefined,
  date2: Date | undefined
) => {
  if (!date1 || !date2) return false;

  const date1String = new Date(
    Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
  ).toISOString();

  const date2String = new Date(
    Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())
  ).toISOString();

  return date1String === date2String;
};

export const isISODateString = (dateString: any) => {
  if (typeof dateString !== "string") return false;
  const dateObject = new Date(dateString);
  return !isNaN(dateObject.getTime());
};

export const isDateBetween = (
  startDate: Date | string,
  endDate: Date | string,
  date: Date | string
) => {
  startDate = new Date(startDate);
  endDate = new Date(endDate);

  date = new Date(date);

  return (
    startDate.getTime() <= date.getTime() && endDate.getTime() >= date.getTime()
  );
};

export const daysInMonth = (date: Date | string) => {
  date = new Date(date);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are zero-indexed

  // Get the last day of the next month (day 0 of the following month)
  const lastDayOfMonth = new Date(year, month, 0);

  // Return the day of the month (total days in the month)
  return lastDayOfMonth.getDate();
};

export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getNewEndDate = (date: Date | string) => {
  date = new Date(date);
  return new Date(date.getTime() + 86399999);
};

export const getCurrentMexicanDate = () => {
  const options = {
    year: "numeric" as const,
    month: "2-digit" as const,
    day: "2-digit" as const,
  };
  const date = new Date().toLocaleDateString("es-MX", options);
  const [day, month, year] = date.split("/");
  return `${year}-${month}-${day}`;
};

export const formatMexicanDateTime = (dateString: Date | string) => {
  const date = new Date(dateString);
  return (
    date.toLocaleDateString("es-MX", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: "America/Mexico_City", // Specify the timezone for Mexico City
    }) +
    " " +
    date.toLocaleTimeString("es-MX", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // Use 24-hour format
      timeZone: "America/Mexico_City", // Specify the timezone for Mexico City
    })
  );
};

export const formatLocaleDateTime = (dateString?: Date | string) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  const userLocale = navigator.language; // Dynamically get the user's locale

  return (
    date.toLocaleDateString(userLocale, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }) +
    " " +
    date.toLocaleTimeString(userLocale, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // Use 24-hour format, adjust as needed
    })
  );
};

export const getDuration = (
  start: Date | string | undefined,
  end: Date | string | undefined
): number => {
  if (!start || !end) return 0;

  const startDate = new Date(start);
  const endDate = new Date(end);

  // Check if the dates are valid
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    console.error("Invalid date input");
    return 0;
  }

  const durationInMilliseconds = endDate.getTime() - startDate.getTime();
  return +(durationInMilliseconds / (1000 * 60 * 60)).toFixed(2); // Convert to hours and round to 2 decimal places
};

export const getStartOfTodayInMexico = (): string => {
  const nowInMexico = dayjsWithPlugins().tz(MEXICO_TIME_ZONE);
  return nowInMexico.startOf("day").toISOString();
};

export const getEndOfTodayInMexico = (): string => {
  const nowInMexico = dayjsWithPlugins().tz(MEXICO_TIME_ZONE);
  return nowInMexico.endOf("day").toISOString();
};

export const getStartOfYesterdayInMexico = (): string => {
  const nowInMexico = dayjsWithPlugins().tz(MEXICO_TIME_ZONE);
  return nowInMexico.subtract(1, "day").startOf("day").toISOString();
};

export const getEndOfYesterdayInMexico = (): string => {
  const nowInMexico = dayjsWithPlugins().tz(MEXICO_TIME_ZONE);
  return nowInMexico.subtract(1, "day").endOf("day").toISOString();
};

export const getStartOfBiWeekInMexico = (): string => {
  const now = dayjsWithPlugins().tz(MEXICO_TIME_ZONE);
  const day = now.date();
  let startDate;

  if (day <= 15) {
    startDate = now.startOf("month").date(1);
  } else {
    startDate = now.startOf("month").date(16);
  }

  return startDate.toISOString();
};

export const getEndOfBiWeekInMexico = (): string => {
  const now = dayjsWithPlugins().tz(MEXICO_TIME_ZONE);
  const day = now.date();
  let endDate;

  if (day <= 15) {
    endDate = now.startOf("month").date(15).endOf("day");
  } else {
    endDate = now.endOf("month").endOf("day");
  }

  return endDate.toISOString();
};

export const getStartOfMonthInMexico = (): string => {
  return dayjsWithPlugins().tz(MEXICO_TIME_ZONE).startOf("month").toISOString();
};

export const getEndOfMonthInMexico = (): string => {
  return dayjsWithPlugins().tz(MEXICO_TIME_ZONE).endOf("month").toISOString();
};

export const getStartOfYearInMexico = (): string => {
  return dayjsWithPlugins().tz(MEXICO_TIME_ZONE).startOf("year").toISOString();
};

export const getEndOfYearInMexico = (): string => {
  return dayjsWithPlugins().tz(MEXICO_TIME_ZONE).endOf("year").toISOString();
};

const getStartOfQuarter = (date: Dayjs) => {
  const month = date.month();
  let startMonth;

  if (month < 3) {
    startMonth = 0; // Q1 starts in January
  } else if (month < 6) {
    startMonth = 3; // Q2 starts in April
  } else if (month < 9) {
    startMonth = 6; // Q3 starts in July
  } else {
    startMonth = 9; // Q4 starts in October
  }

  return date.month(startMonth).startOf("month");
};

// Helper function to get the end date of the quarter
const getEndOfQuarter = (date: Dayjs) => {
  const month = date.month();
  let endMonth;

  if (month < 3) {
    endMonth = 2; // Q1 ends in March
  } else if (month < 6) {
    endMonth = 5; // Q2 ends in June
  } else if (month < 9) {
    endMonth = 8; // Q3 ends in September
  } else {
    endMonth = 11; // Q4 ends in December
  }

  return date.month(endMonth).endOf("month");
};

export const getStartOfQuarterInMexico = (): string => {
  const now = dayjsWithPlugins().tz(MEXICO_TIME_ZONE);
  const startOfQuarter = getStartOfQuarter(now);
  return startOfQuarter.toISOString();
};

export const getEndOfQuarterInMexico = (): string => {
  const now = dayjsWithPlugins().tz(MEXICO_TIME_ZONE);
  const endOfQuarter = getEndOfQuarter(now);
  return endOfQuarter.toISOString();
};

export const getDateRangeInMexico = (period: string) => {
  switch (period) {
    case "today":
      return {
        startDate: getStartOfTodayInMexico(),
        endDate: getEndOfTodayInMexico(),
      };
    case "yesterday":
      return {
        startDate: getStartOfYesterdayInMexico(),
        endDate: getEndOfYesterdayInMexico(),
      };
    case "biweek":
      return {
        startDate: getStartOfBiWeekInMexico(),
        endDate: getEndOfBiWeekInMexico(),
      };
    case "month":
      return {
        startDate: getStartOfMonthInMexico(),
        endDate: getEndOfMonthInMexico(),
      };
    case "quarter":
      return {
        startDate: getStartOfQuarterInMexico(),
        endDate: getEndOfQuarterInMexico(),
      };
    case "year":
      return {
        startDate: getStartOfYearInMexico(),
        endDate: getEndOfYearInMexico(),
      };
    case "all":
      return { startDate: undefined, endDate: undefined };
    default:
      throw new Error(`Invalid period: ${period}`);
  }
};

export const convertMStoHours = (milliseconds: number) => {
  return +(milliseconds / 1000 / 60 / 60).toFixed(2);
};

export const getStartOfTodayDate = () => {
  return dayjsWithPlugins().startOf("day").toDate();
};

export const getEndOfTodayDate = () => {
  return dayjsWithPlugins().endOf("day").toDate();
};

export const getStartOfDate = (date: Date | string) => {
  return dayjsWithPlugins(date).startOf("day").toDate();
};

export const getEndOfDate = (date: Date | string) => {
  return dayjsWithPlugins(date).endOf("day").toDate();
};

export const getUtcStartOfLocalDate = () => {
  // Get the start of the current local day, then convert it to UTC
  return dayjs().startOf("day").utcOffset(0, true); // The `true` keeps the date fixed
};

export const getCurrentDatetime = () => {
  return dayjs();
};

export const getUtcDate = (date: Date | string | Dayjs) => {
  return dayjs(date).utc().startOf("day");
};
