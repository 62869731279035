import React from "react";
import { Field, FieldProps } from "formik";
import { Switch, FormControlLabel, SwitchProps } from "@mui/material";

interface Props extends Omit<SwitchProps, "name"> {
  name: string;
  label: string;
}

export const SimpleFormikSwitch: React.FC<Props> = ({
  name,
  label,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControlLabel
          control={
            <Switch
              {...field}
              checked={field.value ?? false} // Make sure the switch reflects the form value
              {...props}
              onChange={(e) => form.setFieldValue(name, e.target.checked)}
              color={props.color || "primary"}
            />
          }
          label={label}
        />
      )}
    </Field>
  );
};
