import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import {
  IBranch,
  ICollaborator,
  CollaboratorDayShift,
} from "../../../../shared/types";

import { useFormStatusHook } from "../../../../shared/hooks";
import { CollaboratorWeekShift } from "./CollaboratorWeekShift";
import { useAppSelector } from "../../../../hooks";

type Props = {
  collaborators: ICollaborator[];
  weekDates: string[];
  startingDate: string;
  shifts: CollaboratorDayShift[];
  branches: Partial<IBranch>[];

  isCollapsed: boolean;
  toggleSection: (section: "collaboratorsTables") => void;
  handleSubmit: () => Promise<void>;
  setShifts: React.Dispatch<React.SetStateAction<CollaboratorDayShift[]>>;
};

export const CollaboratorsWeekShiftSection = ({
  collaborators,
  weekDates,
  startingDate,
  shifts,
  isCollapsed,
  toggleSection,
  handleSubmit,
  setShifts,
}: Props) => {
  const { weekShifts } = useAppSelector((state) => state.weekShifts);
  const test = weekShifts.map((shift) => shift.startingDate);
  const allWeekShifts = weekShifts.flatMap((weekShift) => weekShift.shifts);
  const totalShifts = [...shifts, ...allWeekShifts];
  const { isSaving } = useFormStatusHook();

  const [editingCollaboratorsIds, setEditingCollaboratorsIds] = useState<
    string[]
  >([]);
  const totalCollaborators = useMemo(() => {
    return collaborators.length;
  }, [collaborators]);

  const handleToggleEditAllCollaboratorsTable = () => {
    setEditingCollaboratorsIds((prev) => {
      if (prev.length === totalCollaborators) return [];
      return collaborators.map((collaborator) => collaborator.id);
    });
  };

  const toggleEditMode = useCallback((collaboratorId: string) => {
    setEditingCollaboratorsIds((prev) => {
      if (prev.includes(collaboratorId)) {
        return prev.filter((id) => id !== collaboratorId);
      }
      return [...prev, collaboratorId];
    });
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6">Collaborators tables</Typography>
          <IconButton onClick={() => toggleSection("collaboratorsTables")}>
            {!isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          <Typography variant="body2" sx={{ marginLeft: 2 }}>
            Edit all collaborators tables
          </Typography>
          <Switch
            checked={editingCollaboratorsIds.length === totalCollaborators}
            onChange={handleToggleEditAllCollaboratorsTable}
            sx={{ marginLeft: 2 }}
          />
        </Box>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={isSaving}
        >
          Save shifts
        </Button>
      </Box>

      <Collapse in={!isCollapsed}>
        <Box sx={{ overflowX: "auto", display: "flex", gap: 1 }}>
          {collaborators.map((collaborator) => {
            return (
              <CollaboratorWeekShift
                key={collaborator.id}
                collaborator={collaborator}
                isEditing={editingCollaboratorsIds.includes(collaborator.id)}
                toggleEditMode={toggleEditMode}
                weekDates={weekDates}
                shifts={shifts.filter(
                  (shift) => shift.collaboratorId === collaborator.id
                )}
                setShifts={setShifts}
                startingDate={startingDate}
                totalShifts={totalShifts.filter(
                  (shift) => shift.collaboratorId === collaborator.id
                )}
              />
            );
          })}
        </Box>
        {/* <pre>{JSON.stringify(test, null, 2)}</pre> */}
      </Collapse>
    </Box>
  );
};
