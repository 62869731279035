import {
  Box,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import {
  IBranch,
  ICollaborator,
  CollaboratorDayShift,
} from "../../../../shared/types";
import dayjs from "dayjs";
import { DAYS_OF_WEEK } from "../../../../shared";
import { ClearIcon, TimeField } from "@mui/x-date-pickers";
import { useAppDispatch, useBranchHook } from "../../../../hooks";
import { setHasUnsavedChanges } from "../../../../store/slice";
import {
  createNewShift,
  formatDateToAbbrDay,
  getDatetimeFromTime,
} from "../../../../shared/helpers";

type Props = {
  collaborator: ICollaborator;
  day: string;
  dayIndex: number;
  startingDate: string;
  shifts: CollaboratorDayShift[];

  setShifts: React.Dispatch<React.SetStateAction<CollaboratorDayShift[]>>;
};
export const CollaboratorDayShiftEdit = ({
  collaborator,
  day,
  dayIndex,
  startingDate,
  shifts,

  setShifts,
}: Props) => {
  const { getBranchByName, branches } = useBranchHook();
  const dispatch = useAppDispatch();
  const currentDay = dayjs
    .utc(startingDate)
    .add(dayIndex, "day")
    .format("YYYY-MM-DD");

  console.log({ startingDate, currentDay });

  const shiftForDay = shifts.find(
    (shift) =>
      shift.collaboratorId === collaborator.id && shift.shiftDate === currentDay
  );

  const debounceRef = useRef<number | null>(null);

  const handleTimeChange = useCallback(
    (
      newValue: dayjs.Dayjs | null,
      field: string,
      collaboratorId: string,
      day: string
    ) => {
      const foundShift = shifts.find(
        (shift) =>
          shift.collaboratorId === collaboratorId && shift.shiftDate === day
      );

      if (!foundShift) return;

      setShifts((prevShifts) => {
        return prevShifts.map((shift) => {
          if (foundShift.id === shift.id) {
            return { ...shift, [field]: newValue?.format("HH:mm") };
          }
          return shift;
        });
      });
      dispatch(setHasUnsavedChanges({ hasUnsavedChanges: true }));
    },
    [shifts]
  );

  const handleBranchChange = (
    e: SelectChangeEvent,
    collaboratorId: string,
    day: string
  ) => {
    const selectedBranch = e.target.value;

    setShifts((prevShifts) => {
      const existingShift = prevShifts.find(
        (shift) =>
          shift.collaboratorId === collaboratorId && shift.shiftDate === day
      );

      const isRemote = selectedBranch === "remote";
      const branchId = isRemote ? undefined : selectedBranch;
      const montejoBranchId = getBranchByName("montejo");

      if (existingShift) {
        return prevShifts.map((shift) => {
          if (shift.id === existingShift.id) {
            return { ...shift, branchId, isRemote };
          }
          return shift;
        });
      } else {
        const newShift: CollaboratorDayShift = createNewShift(
          branchId!,
          day,
          collaboratorId,
          montejoBranchId!.id,
          isRemote
        );

        return [...prevShifts, newShift];
      }
    });
    dispatch(setHasUnsavedChanges({ hasUnsavedChanges: true }));
  };

  const clearTimes = useCallback(
    (collaboratorId: string, day: string) => {
      setShifts((prevShifts) => {
        const foundShift = shifts.find(
          (shift) =>
            shift.collaboratorId === collaboratorId && shift.shiftDate === day
        );
        // if found remove the shift
        if (foundShift) {
          return prevShifts.filter((shift) => shift.id !== foundShift.id);
        }
        return prevShifts;
      });
      dispatch(setHasUnsavedChanges({ hasUnsavedChanges: true }));
    },
    [shifts]
  );

  const handleKeyDown = (e: React.KeyboardEvent, shiftField: string) => {
    if (e.key === "ArrowRight") {
      if (shiftField === "branch") {
        document
          .getElementById(`startingTime-${collaborator.id}-${day}`)
          ?.focus();
      } else if (shiftField === "startingTime") {
        document
          .getElementById(`endingTime-${collaborator.id}-${day}`)
          ?.focus();
      }
    } else if (e.key === "ArrowLeft") {
      if (shiftField === "endingTime") {
        document
          .getElementById(`startingTime-${collaborator.id}-${day}`)
          ?.focus();
      } else if (shiftField === "startingTime") {
        document.getElementById(`branch-${collaborator.id}-${day}`)?.focus();
      }
    } else if (e.key === "ArrowUp" && dayIndex > 0) {
      document
        .getElementById(
          `${shiftField}-${collaborator.id}-${DAYS_OF_WEEK[dayIndex - 1]}`
        )
        ?.focus();
    } else if (e.key === "ArrowDown" && dayIndex < DAYS_OF_WEEK.length - 1) {
      document
        .getElementById(
          `${shiftField}-${collaborator.id}-${DAYS_OF_WEEK[dayIndex + 1]}`
        )
        ?.focus();
    }
  };

  const handleTimeChangeNew = useCallback(
    (newValue: dayjs.Dayjs | null, shiftField: string) => {
      const newTime = newValue?.format("HH:mm");

      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }

      debounceRef.current = window.setTimeout(() => {
        // If the shiftField is endingTime and it's before the startingTime, set it equal to startingTime
        if (shiftField === "endingTime" && shiftForDay?.startingTime) {
          const startingTime = dayjs(shiftForDay.startingTime, "HH:mm");

          if (newValue && newValue.isBefore(startingTime)) {
            // Set ending time to be equal to starting time if it's before
            handleTimeChange(startingTime, shiftField, collaborator.id!, day);
          } else {
            handleTimeChange(newValue, shiftField, collaborator.id!, day);
          }
        } else {
          handleTimeChange(newValue, shiftField, collaborator.id!, day);
        }

        debounceRef.current = null;
      }, 1000); // Adjust debounce timing as needed (300ms in this case)
    },
    [handleTimeChange, collaborator.id, day, shiftForDay]
  );

  return (
    <Box
      key={day}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
        padding: "6px",
        border: "1px solid lightgrey",
        borderRadius: "8px",
      }}
    >
      <Typography variant="body2" sx={{ minWidth: "30px", fontSize: "1rem" }}>
        {formatDateToAbbrDay(day)}
      </Typography>
      <Select
        id={`branch-${collaborator.id}-${day}`}
        value={
          shiftForDay?.branchId
            ? shiftForDay.branchId
            : shiftForDay?.isRemote
            ? "remote"
            : ""
        }
        onChange={(e) => handleBranchChange(e, collaborator.id!, day)}
        size="small"
        sx={{ minWidth: "80px", fontSize: "1rem" }}
        onKeyDown={(e) => handleKeyDown(e, "branch")}
      >
        {branches.map((branch) => (
          <MenuItem key={branch.id} value={branch.id}>
            {branch.name}
          </MenuItem>
        ))}
        <MenuItem key={"remote"} value={"remote"}>
          Remote
        </MenuItem>
      </Select>
      <Box sx={{ width: 70 }}>
        <TextField
          type="time"
          id={`startingTime-${collaborator.id}-${day}`}
          value={shiftForDay?.startingTime || ""}
          onChange={(e) => {
            const newValue = e.target.value
              ? getDatetimeFromTime(e.target.value)
              : null;
            handleTimeChangeNew(newValue, "startingTime");
          }}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputProps={{
            style: {
              fontSize: "1rem",
            },
            step: 900, // 15 minutes
          }}
          fullWidth
          sx={{
            "& input[type='time']::-webkit-inner-spin-button": {
              display: "none", // Hide spin buttons in WebKit browsers
            },
            "& input[type='time']::-webkit-calendar-picker-indicator": {
              display: "none", // Hide the calendar icon in WebKit browsers
            },
            width: "80px", // Custom width for the time input
            fontSize: "1rem",
          }}
        />
      </Box>
      <Box sx={{ width: 70 }}>
        {" "}
        {/* Adjust width as needed */}
        <TextField
          type="time"
          id={`endingTime-${collaborator.id}-${day}`}
          value={shiftForDay?.endingTime || ""}
          onChange={(e) => {
            const newValue = e.target.value
              ? getDatetimeFromTime(e.target.value)
              : null;
            handleTimeChangeNew(newValue, "endingTime");
          }}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputProps={{
            style: {
              fontSize: "1rem",
            },
            step: 900, // 15 minutes
          }}
          fullWidth
          sx={{
            "& input[type='time']::-webkit-inner-spin-button": {
              display: "none", // Hide spin buttons in WebKit browsers
            },
            "& input[type='time']::-webkit-calendar-picker-indicator": {
              display: "none", // Hide the calendar icon in WebKit browsers
            },
            width: "80px", // Custom width for the time input
          }}
        />
      </Box>

      <IconButton
        color="secondary"
        onClick={() => clearTimes(collaborator.id!, day)}
        size="small"
      >
        <ClearIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};
