export const MEXICO_TIME_ZONE = "America/Mexico_City";
export const DATETIME_FORMAT = "DD-MMM-YYYY HH:mm";

export const DAYS_OF_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
