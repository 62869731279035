import { WeekShiftService } from "../../services";
import { createCRUDThunks } from "../../shared/helpers";
import { IWeekShift, IWeekShiftBase } from "../../shared/types";

const service = new WeekShiftService();

const resourceName = "WeekShifts";

const crudThunks = createCRUDThunks<IWeekShift, IWeekShiftBase>({
  service,
  resourceName,
});

export const weekShiftThunks = {
  ...crudThunks,
};
