import React from "react";
import { AttendanceLogger, TodayCollaboratorsList } from "./components";

export const AttendanceRecordsIndex = () => {
  return (
    <>
      <AttendanceLogger />
      <TodayCollaboratorsList />
    </>
  );
};
