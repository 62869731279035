import React, { useEffect, useState } from "react";
import { Container, Box, Button, Typography, FormControl } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  AttendanceRecordAction,
  BranchNames,
  IAttendanceRecordBase,
} from "../../../../shared/types";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import {
  startSavingAttendanceRecord,
  startUpdatingAttendanceRecord,
} from "../../../../store/thunks/attendance-records-thunks";

import CustomRadioGroup from "../../../../components/form/CustomRadioGroup";
import {
  getCurrentUcdDate,
  formatDateToUTC,
  formatTimeToLocalTime,
  isDevMode,
  compareDates,
} from "../../../../shared/helpers";
import { useAuthHook, useBranchHook } from "../../../../hooks";
import { AttendanceActionDisplayMessage } from "./AttendanceLogerDisplayMessage";

export const AttendanceLogger: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // ***** SELECTORS *****
  const { lastCollaboratorAttendanceRecord } = useAppSelector(
    (state) => state.attendanceRecordsReducer
  );

  // ***** CUSTOM HOOKS *****
  const { getBranchNameById, getBranchOptions, getBranchByName } =
    useBranchHook();
  const { currentUser } = useAuthHook();

  const dispatch = useAppDispatch();

  // ***** DATA INITIALIZATION *****
  const query = new URLSearchParams(location.search);
  const branchQueryParam = query.get("branch");
  const branchParamId = getBranchByName(branchQueryParam);
  const initialBranch =
    branchParamId?.id ?? getBranchByName(BranchNames.Urban)!.id;

  // ***** STATES *****
  const [attendanceRecordStatus, setAttendanceRecordStatus] = useState<
    "unregistered" | "clockedIn" | "clockedOut"
  >("unregistered");
  const [isRegisteredDate, setIsRegisteredDate] = useState(false);
  const [currentUtcDate, setCurrentUtcDate] = useState(getCurrentUcdDate());
  const [attendanceRecord, setAttendanceRecord] =
    useState<IAttendanceRecordBase>({
      shiftDate: currentUtcDate.toISOString(),
      clockInBranch: initialBranch,
      collaborator: currentUser?.uid!,
      action: AttendanceRecordAction.ClockIn,
    });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(initialBranch);
  const [currentPosition, setCurrentPosition] = useState({
    lat: 0,
    lng: 0,
  });

  // ***** EFFECTS *****

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentUtcDate(getCurrentUcdDate());
    }, 10000);

    return () => clearInterval(interval); // Clea
  }, []);

  useEffect(() => {
    getCurrentPosition().then((position) => {
      setCurrentPosition(position);
    });
  }, []);

  useEffect(() => {
    setAttendanceRecord((prev) => {
      switch (attendanceRecordStatus) {
        case "unregistered":
          return {
            ...prev,
            collaborator: currentUser?.uid!,
            shiftDate: currentUtcDate.toISOString(),
            action: AttendanceRecordAction.ClockIn,
            startLatitude: currentPosition.lat,
            startLongitude: currentPosition.lng,
            clockInBranch: selectedBranch,
          };
        case "clockedIn":
          return {
            ...lastCollaboratorAttendanceRecord!,
            action: AttendanceRecordAction.ClockOut,
            endLatitude: currentPosition.lat,
            endLongitude: currentPosition.lng,
            clockOutBranch: selectedBranch,
          };
        case "clockedOut":
          return {
            ...prev,
          };
      }
    });
  }, [attendanceRecordStatus, selectedBranch, currentPosition]);

  useEffect(() => {
    // the last date is not today
    if (
      !compareDates(currentUtcDate, lastCollaboratorAttendanceRecord?.shiftDate)
    ) {
      setAttendanceRecordStatus("unregistered");
    } else {
      if (lastCollaboratorAttendanceRecord?.endTime) {
        setAttendanceRecordStatus("clockedOut");
      } else {
        setAttendanceRecordStatus("clockedIn");
      }
    }
  }, [currentUtcDate, lastCollaboratorAttendanceRecord?.shiftDate]);

  // ***** HANDLERS *****
  const getCurrentPosition = async () => {
    const position = await new Promise<GeolocationPosition>((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject)
    );
    return {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
  };

  const handleBranchChange = (value: string) => {
    const branchValue = value;
    setSelectedBranch(branchValue);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Wrap the geolocation call in a new Promise to make it awaitable
      const position = await new Promise<GeolocationPosition>(
        (resolve, reject) => {
          // The getCurrentPosition function will prompt the user for permission
          navigator.geolocation.getCurrentPosition(
            (position) => resolve(position),
            (error) => reject(error)
          );
        }
      );

      // Extract latitude and longitude from the position object
      const { latitude, longitude } = position.coords;
      const updatedRecord: IAttendanceRecordBase = {
        ...attendanceRecord,
        [attendanceRecord.action === AttendanceRecordAction.ClockIn
          ? "startLatitude"
          : "endLatitude"]: latitude,
        [attendanceRecord.action === AttendanceRecordAction.ClockIn
          ? "startLongitude"
          : "endLongitude"]: longitude,
      };

      // Update the state with the new record including geolocation data
      setAttendanceRecord(updatedRecord);

      // Proceed with saving or updating the record after geolocation data is set
      if (!updatedRecord.id) {
        await dispatch(startSavingAttendanceRecord(updatedRecord));
      } else {
        await dispatch(startUpdatingAttendanceRecord(updatedRecord));
        navigate(`/dashboard/cleanups/${updatedRecord.clockOutBranch}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 5,
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "#fff",
        }}
      >
        {/* HEADER */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="h4" gutterBottom>
            Attendance Record Logger
          </Typography>
          <Typography variant="h6" gutterBottom>
            Collaborator Code: {currentUser?.col_code}
          </Typography>
        </Box>
        {/* Last Registry data */}
        <Box sx={{ mb: 2, textAlign: "center" }}>
          <Typography variant="h6">Last Record:</Typography>
          <Typography variant="body1">
            {`Date: ${formatDateToUTC(
              lastCollaboratorAttendanceRecord?.shiftDate
            )}`}
          </Typography>
          <Typography variant="body1">
            {`Clocked in at ${getBranchNameById(
              lastCollaboratorAttendanceRecord?.clockInBranch!
            )} at ${formatTimeToLocalTime(
              lastCollaboratorAttendanceRecord?.startTime
            )}`}
          </Typography>
          {lastCollaboratorAttendanceRecord?.endTime && (
            <Typography variant="body1">
              {`Clocked out at ${getBranchNameById(
                lastCollaboratorAttendanceRecord?.clockOutBranch!
              )} at ${
                lastCollaboratorAttendanceRecord?.endTime
                  ? new Date(
                      lastCollaboratorAttendanceRecord?.endTime
                    ).toLocaleTimeString()
                  : ""
              }`}
            </Typography>
          )}
        </Box>
        {/* Form */}
        {attendanceRecordStatus !== "clockedOut" ? (
          <Box>
            <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Branch:
              </Typography>
              <CustomRadioGroup
                options={getBranchOptions()}
                value={selectedBranch}
                onChange={(value) => handleBranchChange(value)}
              />
            </FormControl>

            <AttendanceActionDisplayMessage
              attendanceRecord={attendanceRecord}
              currentPosition={currentPosition}
              attendanceRecordStatus={attendanceRecordStatus}
              selectedBranchName={selectedBranch}
            />
            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                Submit
              </Button>
            </FormControl>
          </Box>
        ) : (
          <Box>
            <Typography variant="h5" sx={{ color: "green", fontWeight: 600 }}>
              You have already registered today
            </Typography>
          </Box>
        )}
      </Box>
      {/*       
      <Box sx={{ mt: 5 }}>
        <pre>{JSON.stringify({ attendanceRecord }, null, 2)}</pre>
        <pre>{JSON.stringify({ isRegisteredDate }, null, 2)}</pre>
        <pre>{JSON.stringify({ todayDate }, null, 2)}</pre>
        <pre>
          {JSON.stringify({ lastCollaboratorAttendanceRecord }, null, 2)}
        </pre>
      </Box> */}
      {isDevMode && (
        <>
          <pre>{JSON.stringify({ currentUser }, null, 2)}</pre>
          <pre>{JSON.stringify({ currentPosition }, null, 2)}</pre>
          <pre>{JSON.stringify({ isLoading }, null, 2)}</pre>
          <pre>{JSON.stringify({ currentUtcDate }, null, 2)}</pre>
          <pre>{JSON.stringify({ attendanceRecordStatus }, null, 2)}</pre>
          <pre>{JSON.stringify({ isRegisteredDate }, null, 2)}</pre>
          <pre>
            {JSON.stringify({ lastCollaboratorAttendanceRecord }, null, 2)}
          </pre>
          <pre>{JSON.stringify({ attendanceRecord }, null, 2)}</pre>
        </>
      )}
    </Container>
  );
};
