import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { ERROR_COLOR, SUCCESS_COLOR } from "../../../../shared";
import {
  calculateTotalHoursCovered,
  formatToInteger,
  isSaturdayOrSunday,
} from "../../../../shared/helpers";
import { CollaboratorDayShift, ICollaborator } from "../../../../shared/types";
import { CollaboratorDayShiftEdit } from "./CollaboratorDayShiftEdit";
import { CollaboratorDayShiftView } from "./CollaboratorDayShiftView";

type Props = {
  collaborator: ICollaborator;
  toggleEditMode: (collaboratorId: string) => void;
  isEditing: boolean;
  weekDates: string[];
  shifts: CollaboratorDayShift[];
  startingDate: string;
  setShifts: React.Dispatch<React.SetStateAction<CollaboratorDayShift[]>>;
  totalShifts: CollaboratorDayShift[];
};
export const CollaboratorWeekShift = ({
  collaborator,
  toggleEditMode,
  isEditing,
  weekDates,
  shifts,
  startingDate,
  setShifts,
  totalShifts,
}: Props) => {
  const scheduledHours = calculateTotalHoursCovered(shifts);
  const { weeklyHours } = collaborator;
  const scheduleMatch = weeklyHours === scheduledHours;
  const weekendHours =
    calculateTotalHoursCovered(
      totalShifts
        .filter((shift) => isSaturdayOrSunday(shift.shiftDate))
        .filter((shift) => !shift.isRemote)
    ) / 6;

  return (
    <Box
      key={collaborator.id}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "350px",
        border: "1px solid lightgrey",
        padding: "10px",
        borderRadius: "8px",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          gap: 4,
        }}
      >
        <Typography variant="h6">{collaborator.col_code}</Typography>

        <Button
          size="small"
          onClick={() => toggleEditMode(collaborator.id!)}
          color={isEditing ? "error" : "primary"}
        >
          {isEditing ? "Cancel" : "Edit"}
        </Button>
      </Box>
      <Box
        sx={{
          padding: 2,
          border: "1px solid lightblue",
          borderRadius: 2,
          width: "100%",
          backgroundColor: scheduleMatch ? SUCCESS_COLOR : ERROR_COLOR,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              Req
            </Typography>
            <Typography variant="body2">
              {formatToInteger(collaborator.weeklyHours || 0)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              Sche
            </Typography>
            <Typography variant="body2">
              {formatToInteger(scheduledHours)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              Weekend
            </Typography>
            <Typography variant="body2">
              {formatToInteger(weekendHours)}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        {weekDates.map((day, dayIndex) => {
          if (isEditing) {
            return (
              <CollaboratorDayShiftEdit
                key={dayIndex}
                day={day}
                startingDate={startingDate}
                dayIndex={dayIndex}
                collaborator={collaborator}
                shifts={shifts}
                setShifts={setShifts}
              />
            );
          }
          return (
            <CollaboratorDayShiftView
              key={dayIndex}
              day={day}
              startingDate={startingDate}
              dayIndex={dayIndex}
              collaborator={collaborator}
              shifts={shifts}
            />
          );
        })}
      </Box>
    </Box>
  );
};
