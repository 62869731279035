import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { DateSelector } from "../../../../components";
import {
  dateIntoStringDate,
  getMonday,
  getStringDateAsDDMMYYYY,
  getSunday,
} from "../../../../shared/helpers";
import { IWeekShift, IWeekShiftBase } from "../../../../shared/types";
import { HandleErrorsService, WeekShiftService } from "../../../../services";
import { useAppDispatch, useAuthHook } from "../../../../hooks";
import { useFormStatusHook } from "../../../../shared/hooks/useFormStatus.hook";
import { weekShiftThunks } from "../../../../store/thunks";
import { setHasUnsavedChanges } from "../../../../store/slice";
import { CustomSelect } from "../../../../components/inputs/CustomSelect";
import { fireSwalSuccess } from "../../../../helpers/utilities";
import { WeekShiftsForm } from "../components";
import { fireSwalError } from "../../../../helpers";
import dayjs from "dayjs";

const initialEmptyValues: IWeekShiftBase = {
  startingDate: dateIntoStringDate(getMonday()),
  endingDate: dateIntoStringDate(getSunday()),
  shifts: [],
};

export const WeeksShiftsEdit: React.FC = () => {
  // *****GLOBALS***** //
  const service = new WeekShiftService();
  const dispatch = useAppDispatch();

  // *****STATES***** //
  const [date, setDate] = useState(getMonday());
  const [weekShift, setWeekShift] = useState<IWeekShiftBase>();
  const [models, setModels] = useState<IWeekShift[]>([]);
  const [selectedModel, setSelectedModel] = useState<string>("");

  // *****CUSTOM HOOKS***** //
  const { handleUnsavedChanges } = useFormStatusHook();
  const { authBlock } = useAuthHook();

  // *****COMPUTED VALUES***** //
  const modelOptions = models.map((model) => ({
    label: model.modelName!,
    value: model.modelName!,
  }));

  // *****HANDLERS***** //
  const handleSetDate = async (date: Date) => {
    await handleUnsavedChanges(() => {
      setDate(date);
    });
  };

  const handleSaveWeekShift = async (weekShift: IWeekShiftBase) => {
    try {
      let savedWeekShift;
      if (weekShift.id) {
        savedWeekShift = await dispatch(
          weekShiftThunks.updateItem(weekShift)
        ).unwrap();
      } else {
        savedWeekShift = await dispatch(
          weekShiftThunks.createItem(weekShift)
        ).unwrap();
      }
      setWeekShift(savedWeekShift);
      dispatch(setHasUnsavedChanges({ hasUnsavedChanges: false }));
      await fireSwalSuccess("Week shift saved successfully");
    } catch (error) {
      const errorMessage = HandleErrorsService.handleError(error);
      await fireSwalError(errorMessage);
    } finally {
    }
  };

  // *****HELPERS***** //
  const fetchWeekShift = async () => {
    try {
      const weekShift = await service.getAll({
        startingDate: dateIntoStringDate(date),
      });

      if (weekShift.data[0]) {
        setWeekShift(weekShift.data[0]);
      } else {
        const newWeekShift: IWeekShiftBase = {
          ...initialEmptyValues,
          startingDate: dateIntoStringDate(getMonday(date)),
          endingDate: dateIntoStringDate(getSunday(date)),
        };
        setWeekShift(newWeekShift);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const fetchModels = async () => {
    const models = await service.getAll({ isModel: true });
    setModels(models.data);
  };

  // *****EFFECTS***** //
  useEffect(() => {
    fetchModels();
  }, []);

  useEffect(() => {
    fetchWeekShift();
  }, [date]);

  useEffect(() => {
    const dayJsStratingDate = dayjs(date);
    const mondayFiveWeeksAgo = dayJsStratingDate.subtract(5, "week");
    const formattedDate = mondayFiveWeeksAgo.format("YYYY-MM-DD");
    dispatch(
      weekShiftThunks.fetchItems({
        $startingDate: `$gte: ${formattedDate}`,
        $endingDate: `$lte: ${date}`,
      })
    );
  }, [dispatch, date]);

  useEffect(() => {
    if (weekShift?.modelName) {
      setSelectedModel(weekShift.modelName);
    } else {
      setSelectedModel("");
    }
  }, [weekShift]);

  useEffect(() => {
    if (selectedModel) {
      const selectedWeekShift = models.find(
        (model) => model.modelName === selectedModel
      );
      if (selectedWeekShift) {
        setDate(new Date(selectedWeekShift.startingDate));
      }
    }
  }, [selectedModel]);

  authBlock("/dashboard/work-shifts");

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        mb={2}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: 2,
        }}
      >
        <Typography variant="h5" fontWeight="bold" flex={1}>
          Week shifts for {getStringDateAsDDMMYYYY(date.toISOString())}
        </Typography>
        <Box flex={1}>
          <DateSelector date={date} setDate={handleSetDate} />
        </Box>
        <Box flex={1}>
          <CustomSelect
            options={modelOptions}
            label="Select a weekshift by model"
            value={selectedModel}
            onChange={(e) => setSelectedModel(e.target.value as string)}
            sx={{
              minWidth: {
                xs: "250px",
              },
            }}
          />
        </Box>
      </Box>

      <Divider />

      {weekShift ? (
        <Box mt={3}>
          <WeekShiftsForm
            weekShift={weekShift}
            handleSaveWeekShift={handleSaveWeekShift}
            fetchWeekShift={fetchWeekShift}
          />
        </Box>
      ) : (
        <CircularProgress />
      )}
      <pre>{JSON.stringify(weekShift, null, 2)}</pre>
    </Box>
  );
};
