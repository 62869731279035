import Swal from "sweetalert2";
import { fileUploadCloudinary } from "./fileUploadCloudinary";

export const uploadImg = async (file, fireSwal = true) => {
  fireSwal &&
    Swal.fire({
      title: "Uploading...",
      text: "Please wait...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

  // cloudinary
  const cloudUrl = "https://api.cloudinary.com/v1_1/dwalcv9li/upload";
  const formData = new FormData();
  formData.append("upload_preset", "react-course");
  formData.append("file", file);
  try {
    // cloudinary request
    const resp = await fetch(cloudUrl, {
      method: "POST",
      body: formData,
    });

    if (resp.ok) {
      // get the response
      const cloudResp = await resp.json();
      console.log({ cloudResp });
      // return the url
      fireSwal && Swal.close();
      return cloudResp.secure_url;
    } else {
      fireSwal && Swal.close();
      return null;
    }
  } catch (error) {
    fireSwal && Swal.close();
    throw error;
  }
};

const cloudResp = {
  asset_id: "2931442350b8bab5ca03cfea5db72196",
  public_id: "journal/rvoweujqkne9whhx7tuq",
  version: 1727626354,
  version_id: "3acac71323d4fafb82743b6d16975b76",
  signature: "dbacff493576d777a4e80dca2929662bc19d2777",
  width: 518,
  height: 305,
  format: "png",
  resource_type: "image",
  created_at: "2024-09-29T16:12:34Z",
  tags: [],
  bytes: 15746,
  type: "upload",
  etag: "221fab339edf6f984ed5456e5b04705d",
  placeholder: false,
  url: "http://res.cloudinary.com/dwalcv9li/image/upload/v1727626354/journal/rvoweujqkne9whhx7tuq.png",
  secure_url:
    "https://res.cloudinary.com/dwalcv9li/image/upload/v1727626354/journal/rvoweujqkne9whhx7tuq.png",
  folder: "journal",
  access_mode: "public",
  original_filename: "Screenshot from 2024-09-29 18-10-12",
};
