import dayjs from "dayjs";
import {
  IBranch,
  ICollaborator,
  CollaboratorDayShift,
  ExtendedCollaboratorDayShift,
  IWeekShiftBase,
  CollaboratorRestListByDate,
  IWeekShift,
} from "../types";
import { getDateFromTime, getHoursDifference } from "./time.helpers";
import {
  ALL_VETERINARY_POSITIONS,
  VETS,
  VETS_AND_ASSISTANTSA,
} from "../constants/job.contants";
import {
  DAY_SHIFT_VALIDATION_RULES,
  SEVERITY_COLORS,
  ValidationRule,
} from "../constants/week-shift.constants";
import { getSunday, isSaturdayOrSunday } from "./date.helpers";
import { isSunday } from "date-fns";
import { cloneDeep } from "lodash";
import { nanoid } from "nanoid";

export const fillShiftsWithRestAndRemote = (
  shifts: CollaboratorDayShift[],
  collaborators: ICollaborator[],
  weekDates: string[]
) => {
  const newShifts = fillShiftsWithRemote(shifts);

  for (const collaborator of collaborators) {
    for (const weekDate of weekDates) {
      const shift = newShifts.find(
        (shift) =>
          shift.collaboratorId === collaborator.id &&
          shift.shiftDate === weekDate
      );

      if (!shift) {
        newShifts.push({
          id: `${collaborator.id}-${weekDate}`,
          collaboratorId: collaborator.id!,
          shiftDate: weekDate,
          branchId: "rest",
        });
      }
    }
  }

  return newShifts;
};

export const fillShiftsWithRemote = (shifts: CollaboratorDayShift[]) => {
  const newShifts = shifts.map((shift) => {
    if (shift.isRemote) {
      return { ...shift, branchId: "remote" };
    }
    return shift;
  });

  return newShifts;
};

type ValidateShiftArgs = {
  shifts: ExtendedCollaboratorDayShift[];
  date: string;
  branch?: IBranch;
};

export const validateShift = ({ shifts, date, branch }: ValidateShiftArgs) => {
  if (!branch) return [];

  const errors: ValidationRule[] = [];
  const { openingTime, closingTime } =
    getBranchOpeningTimeByDate(date, branch) || {};

  if (!openingTime || !closingTime) {
    if (shifts.length === 0) return [];
    return [DAY_SHIFT_VALIDATION_RULES.outsideHours];
  }

  const totalHoursCovered = calculateTotalHoursCovered(shifts);
  const vetShifts = filterShiftsByRole(shifts, ALL_VETERINARY_POSITIONS);

  // Generalized validations

  if (!validateCoverage(openingTime, closingTime, vetShifts)) {
    errors.push(DAY_SHIFT_VALIDATION_RULES.trainedStaffPresence);
  }

  if (!validateOutsideHours(shifts, openingTime, closingTime)) {
    errors.push(DAY_SHIFT_VALIDATION_RULES.outsideHours);
  }

  if (!validateClosingShift(shifts, branch, closingTime)) {
    errors.push(DAY_SHIFT_VALIDATION_RULES.assistantAForClosing);
  }

  if (!validateBranchWeekendCoverage(date, totalHoursCovered, branch)) {
    errors.push(DAY_SHIFT_VALIDATION_RULES.weekendCoverageUrban);
  }

  if (!validateRglOrMatPairing(shifts)) {
    errors.push(DAY_SHIFT_VALIDATION_RULES.rglOrMatPairing);
  }

  if (!validateBranchSundayCoverage(date, totalHoursCovered, branch)) {
    errors.push(DAY_SHIFT_VALIDATION_RULES.sundayCoverageHarbor);
  }

  if (
    !validateVeterinarianAtAllTimes(shifts, branch, openingTime!, closingTime!)
  ) {
    errors.push(DAY_SHIFT_VALIDATION_RULES.veterinarianAtAllTimes);
  }

  if (
    !validateWeekendDoubleCoverage(
      date,
      shifts,
      branch,
      openingTime!,
      closingTime!
    )
  ) {
    errors.push(DAY_SHIFT_VALIDATION_RULES.weekendDoubleCoverage);
  }

  if (!matAdditionalStaffRecommended(shifts)) {
    errors.push(DAY_SHIFT_VALIDATION_RULES.matAdditionalStaffRecommended);
  }

  if (date === "2024-10-07" && branch.name === "Montejo") {
    console.log({ errors, branch, openingTime, closingTime, date, shifts });
  }

  return errors;
};
export const calculateTotalHoursCovered = (shifts: CollaboratorDayShift[]) => {
  return shifts.reduce(
    (acc, shift) =>
      acc + getHoursDifference(shift.startingTime!, shift.endingTime!),
    0
  );
};

const filterShiftsByRole = (
  shifts: ExtendedCollaboratorDayShift[],
  roles: string[]
) => {
  return shifts.filter((shift) => roles.includes(shift.job?.title ?? ""));
};

const validateCoverage = (
  openingTime: string,
  closingTime: string,
  shifts: ExtendedCollaboratorDayShift[]
) => {
  const opening = parseTime(openingTime);
  const closing = parseTime(closingTime);

  const intervals = new Array(closing - opening).fill(0);

  shifts.forEach((shift) => {
    const shiftStart = Math.max(parseTime(shift.startingTime!), opening);
    const shiftEnd = Math.min(parseTime(shift.endingTime!), closing);

    for (let i = shiftStart - opening; i < shiftEnd - opening; i++) {
      intervals[i] += 1;
    }
  });

  return intervals.every((coverage) => coverage >= 1); // Ensure full coverage by at least 1 person
};

const validateOutsideHours = (
  shifts: ExtendedCollaboratorDayShift[],
  openingTime: string,
  closingTime: string
) => {
  return shifts.every((shift) => {
    const shiftStart = parseTime(shift.startingTime!);
    const shiftEnd = parseTime(shift.endingTime!);
    return (
      shiftStart >= parseTime(openingTime) && shiftEnd <= parseTime(closingTime)
    );
  });
};

const validateClosingShift = (
  shifts: ExtendedCollaboratorDayShift[],
  branch: IBranch,
  closingTime: string
): boolean => {
  if (branch.name !== "Urban") return true;

  const assistantsShifts = filterShiftsByRole(shifts, VETS_AND_ASSISTANTSA);
  const closingHour = getDateFromTime(closingTime);

  return assistantsShifts.some((shift) =>
    getDateFromTime(shift.endingTime!).isSameOrAfter(closingHour)
  );
};

const validateBranchWeekendCoverage = (
  date: string,
  totalHoursCovered: number,
  branch: IBranch
) => {
  if (branch.name === "Urban" && isSaturdayOrSunday(date)) {
    return totalHoursCovered >= 30;
  }
  return true;
};

// Generalized validation for branch-specific Sunday coverage
const validateBranchSundayCoverage = (
  date: string,
  totalHoursCovered: number,
  branch: IBranch
) => {
  if (branch.name === "Harbor" && isSunday(date)) {
    return totalHoursCovered >= 30;
  }
  return true;
};

const validateVeterinarianAtAllTimes = (
  shifts: ExtendedCollaboratorDayShift[],
  branch: IBranch,
  openingTime: string,
  closingTime: string
): boolean => {
  // Only validate for specific branches
  if (branch.name !== "Urban" && branch.name !== "Harbor") return true;

  // Filter shifts by veterinarian roles
  const vetShifts = filterShiftsByRole(shifts, VETS);

  // Check if the veterinarian shifts cover the entire opening time
  return validateCoverage(openingTime, closingTime, vetShifts);
};

// Validate weekend double coverage (at least 2 collaborators present at all times on weekends)
const validateWeekendDoubleCoverage = (
  date: string,
  shifts: ExtendedCollaboratorDayShift[],
  branch: IBranch,
  openingTime: string,
  closingTime: string
): boolean => {
  // Only apply this rule for branches "Urban" or "Harbor"
  if (branch.name !== "Urban" && branch.name !== "Harbor") return true;

  // Only validate if it's a weekend (Saturday or Sunday)
  if (!isSaturdayOrSunday(date)) return true;

  // Parse the opening and closing times into minutes
  const opening = parseTime(openingTime);
  const closing = parseTime(closingTime);

  // Initialize an array representing time intervals in minutes
  const intervals = new Array(closing - opening).fill(0);

  // Loop over the shifts and fill the intervals array based on how many collaborators cover each minute
  shifts.forEach((shift) => {
    const shiftStart = parseTime(shift.startingTime!);
    const shiftEnd = parseTime(shift.endingTime!);

    for (
      let i = Math.max(shiftStart, opening);
      i < Math.min(shiftEnd, closing);
      i++
    ) {
      intervals[i - opening] += 1; // Increment the number of collaborators for each interval
    }
  });

  // Ensure that for every minute during the opening time, at least 2 collaborators are present
  return intervals.every((coverage) => coverage >= 2);
};

// Validate RGL/MAT pairing logic
const validateRglOrMatPairing = (
  shifts: ExtendedCollaboratorDayShift[]
): boolean => {
  // Filter out MAT or RGL shifts
  const rglOrMatShifts = shifts.filter(
    (shift) =>
      shift.collaborator.col_code === "MAT" ||
      shift.collaborator.col_code === "RGL"
  );

  // If no RGL or MAT shifts, no need to check further
  if (!rglOrMatShifts.length) return true;

  // Sort all shifts by starting time
  const sortedShifts = [...shifts].sort((a, b) =>
    getDateFromTime(a.startingTime!).diff(getDateFromTime(b.startingTime!))
  );

  // Check if each MAT or RGL shift has full coverage by one or more collaborators
  return rglOrMatShifts.every((rglOrMatShift) => {
    let currentStartTime = getDateFromTime(rglOrMatShift.startingTime!);

    // Filter shifts that are not MAT or RGL
    const otherCollaborators = sortedShifts.filter(
      (shift) =>
        shift.collaborator.col_code !== "MAT" &&
        shift.collaborator.col_code !== "RGL"
    );

    for (const shift of otherCollaborators) {
      const shiftStart = getDateFromTime(shift.startingTime!);
      const shiftEnd = getDateFromTime(shift.endingTime!);

      // Update the current start time if the shift covers or overlaps with it
      if (
        shiftStart.isSameOrBefore(currentStartTime) &&
        shiftEnd.isAfter(currentStartTime)
      ) {
        currentStartTime = shiftEnd;
      }

      // If the currentStartTime covers the end of the RGL/MAT shift, return true
      if (
        currentStartTime.isSameOrAfter(
          getDateFromTime(rglOrMatShift.endingTime!)
        )
      ) {
        return true;
      }
    }

    return false; // If not fully covered, return false
  });
};

// Find shifts that overlap with a given time range
const getOverlappingShifts = (
  shifts: ExtendedCollaboratorDayShift[],
  start: number,
  end: number
) => {
  return shifts.filter((shift) => {
    const shiftStart = parseTime(shift.startingTime!);
    const shiftEnd = parseTime(shift.endingTime!);

    return shiftEnd > start && shiftStart < end;
  });
};

const matAdditionalStaffRecommended = (
  shifts: ExtendedCollaboratorDayShift[]
): boolean => {
  // Find the MAT shift
  const matShift = shifts.find(
    (shift) => shift.collaborator.col_code === "MAT"
  );

  // If no MAT shift is found, return true (no need for additional staff check)
  if (!matShift) return true;

  // Parse the start and end time of the MAT shift
  const matStart = parseTime(matShift.startingTime!);
  const matEnd = parseTime(matShift.endingTime!);

  // Create an array to represent each minute during the MAT shift
  const timeRange = new Array(matEnd - matStart).fill(0);

  // Filter non-MAT shifts that overlap with the MAT shift
  const overlappingShifts = shifts.filter(
    (shift) => shift.collaborator.col_code !== "MAT"
  );

  overlappingShifts.forEach((shift) => {
    const shiftStart = Math.max(parseTime(shift.startingTime!), matStart);
    const shiftEnd = Math.min(parseTime(shift.endingTime!), matEnd);

    // Update the time range for each minute the shift covers
    for (let i = shiftStart - matStart; i < shiftEnd - matStart; i++) {
      timeRange[i] += 1;
    }
  });

  // Check if at least two collaborators are present for every minute of the MAT shift
  return timeRange.every((collabs) => collabs >= 2);
};

// Helper function to parse time in HH:mm format
const parseTime = (time: string): number => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes; // Convert time to minutes for easier comparison
};

export const getBranchOpeningTimeByDate = (
  date: string,
  branch: IBranch
): { openingTime?: string; closingTime?: string } | undefined => {
  const dayIndex = dayjs.utc(date).day();
  if (date === "2024-10-13" && branch.name === "Montejo") {
    console.log({ dayIndex });
  }
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayOfWeek = days[dayIndex];

  // Find the opening hours for the corresponding day of the week
  const openingHoursForDay = branch.openingHours.find(
    (hours) => hours.day === dayOfWeek
  );

  // Return the opening and closing times for the day
  return openingHoursForDay
    ? {
        openingTime: openingHoursForDay.open,
        closingTime: openingHoursForDay.close,
      }
    : undefined;
};

type SeverityLevel = keyof typeof SEVERITY_COLORS;
export const getSeverityColor = (errors: ValidationRule[]) => {
  if (errors.length === 0) return "inherit";

  const severityOrder = ["critical", "high", "medium", "low"];
  const highestSeverity = severityOrder.find((severity) =>
    errors.some((error) => error.severity === severity)
  );

  return highestSeverity
    ? SEVERITY_COLORS[highestSeverity as SeverityLevel]
    : "inherit";
};

export const buildWeekShiftParams = (selectedCopyModel: string) => {
  const isDate = /\d{4}-\d{2}-\d{2}/.test(selectedCopyModel); // Regex to check if it's a date in format YYYY-MM-DD

  if (isDate) {
    return {
      startingDate: selectedCopyModel,
    };
  } else {
    return {
      modelName: selectedCopyModel,
    };
  }
};

export const changeWeekShiftDates = (
  weekShift: IWeekShiftBase,
  startingDate: string
) => {
  const originalStartingDate = weekShift.startingDate;
  console.log({ originalStartingDate });

  const clonedWeekShift = cloneDeep(weekShift);
  return {
    startingDate,
    endingDate: getSunday(startingDate),
    shifts: clonedWeekShift.shifts.map((shift) => {
      // Normalize both dates to the start of the day to avoid time differences affecting the calculation
      const dayDifference = dayjs(shift.shiftDate)
        .startOf("day")
        .diff(dayjs(originalStartingDate).startOf("day"), "day");

      console.log({
        startingDate: dayjs(startingDate),
        shiftDate: dayjs(shift.shiftDate),
        dayDifference,
      });

      // Update shift date based on the calculated day difference
      const newShiftDate = dayjs(startingDate)
        .add(dayDifference, "day")
        .format("YYYY-MM-DD");

      return {
        ...shift,
        shiftDate: newShiftDate, // Update the shift date
      };
    }),
  };
};

export const parseDroppableId = (droppableId: string) => {
  const lastHyphenIndex = droppableId.lastIndexOf("-");
  const destDate = droppableId.slice(0, lastHyphenIndex);
  const destBranch = droppableId.slice(lastHyphenIndex + 1);
  return { destDate, destBranch };
};

export const createNewShift = (
  destBranch: string,
  destDate: string,
  collaboratorId: string,
  montejoBranchId: string,
  isRemote: boolean
): CollaboratorDayShift => {
  const isMontejo = montejoBranchId === destBranch;
  const isWeekend = isSaturdayOrSunday(destDate);

  console.log(dayjs(destDate).format("dddd"));
  console.log({ isMontejo, isWeekend });

  let startingTime = "08:00";
  let endingTime = "20:00";
  if (isMontejo && !isWeekend) {
    startingTime = "11:00";
    endingTime = "19:00";
  }

  const newShift: CollaboratorDayShift = {
    id: nanoid(),
    collaboratorId,
    shiftDate: destDate,
    startingTime,
    endingTime,
    branchId: destBranch,
    isRemote,
  };

  return newShift;
};

export const getCollaboratosWithoutShiftByDate = (
  collaborators: ICollaborator[],
  shifts: ExtendedCollaboratorDayShift[],
  dates: string[]
): CollaboratorRestListByDate => {
  const shiftsByDay: Record<string, Set<string>> = {};

  shifts.forEach((shift) => {
    if (!shiftsByDay[shift.shiftDate]) {
      shiftsByDay[shift.shiftDate] = new Set();
    }
    shiftsByDay[shift.shiftDate].add(shift.collaboratorId);
  });

  const result: CollaboratorRestListByDate = {};

  dates.forEach((date) => {
    result[date] = collaborators.filter(
      (collaborator) => !shiftsByDay[date]?.has(collaborator.id)
    );
  });

  return result;
};

export const getCollaboratorShifts = (
  weekShifts: IWeekShift[],
  collaboratorId: string
) => {
  const collaboratorShifts = weekShifts.flatMap((weekShift) =>
    weekShift.shifts.filter((shift) => shift.collaboratorId === collaboratorId)
  );
  return collaboratorShifts;
};

export const getCollaboratorsShifts = (weekShifts: IWeekShift[]) => {
  return weekShifts.flatMap((weekShift) => weekShift.shifts);
};
