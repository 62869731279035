export enum BranchNames {
  Urban = "Urban",
  Harbor = "Harbor",
  Montejo = "Montejo",
}

export interface Branch {
  id: string;
  name: string;
  color: string;
}
