import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import {
  clearUnsavedChanges,
  formStatusSlice,
  setIsSaving,
} from "../../store/slice";
import { confirmLeaveWithoutSaving } from "../../helpers";

export const useFormStatusHook = () => {
  const dispatch = useDispatch();
  const { hasUnsavedChanges, isSaving } = useAppSelector(
    (state) => state.formStatus
  );

  const handleUnsavedChanges = async (
    onConfirm: () => void | Promise<void>,
    onCancel?: () => void | Promise<void>
  ) => {
    if (hasUnsavedChanges) {
      const result = await confirmLeaveWithoutSaving();
      if (result.isConfirmed) {
        dispatch(clearUnsavedChanges());
        await onConfirm();
      } else {
        onCancel && (await onCancel());
      }
    } else {
      dispatch(clearUnsavedChanges());
      onConfirm();
    }
  };

  const handleSetIsSaving = (isSaving: boolean) => {
    dispatch(setIsSaving(isSaving));
  };

  return {
    handleUnsavedChanges,
    hasUnsavedChanges,
    setIsSaving: handleSetIsSaving,
    isSaving,
  };
};
