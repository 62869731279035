import React from "react";
import { CollaboratorRestListByDate } from "../../../../shared/types";
import { TableCell, TableRow, Typography } from "@mui/material";

type Props = {
  collaboratorsByDate: CollaboratorRestListByDate;
  weekDates: string[];
};

export const WeekShiftRestRow = ({ collaboratorsByDate, weekDates }: Props) => {
  return (
    <TableRow>
      <TableCell>
        <Typography fontWeight={600}>Rest</Typography>
      </TableCell>
      {weekDates.map((date, index) => {
        return (
          <TableCell key={index} sx={{ padding: 0.75, fontSize: 12 }}>
            {collaboratorsByDate[date].map((collaborator) => {
              return (
                <span key={collaborator.id}>{collaborator.col_code} </span>
              );
            })}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
