import React from "react";
import { Field, FieldProps } from "formik";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  SelectProps,
  FormHelperText,
} from "@mui/material";
import { IOption } from "../../shared/types/option.types";

interface Props extends SelectProps {
  options: IOption[];
  noneLabel?: string;
  withNoneOption?: boolean;
}

export const SimpleFormikSelect: React.FC<Props> = ({
  label,
  name,
  error,
  options,
  disabled = false,
  withNoneOption = false,
  noneLabel = "None",
  ...rest
}) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <FormControl fullWidth error={meta.touched && Boolean(meta.error)}>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Select
            {...field}
            {...rest}
            labelId={`${name}-label`}
            id={name}
            label={label}
            disabled={disabled}
          >
            {withNoneOption && (
              <MenuItem key="none" value="">
                <em>{noneLabel}</em>
              </MenuItem>
            )}
            {options.map((option: IOption) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {meta.touched && meta.error && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
};
