import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";

import { IAttendanceRecord } from "../../../../shared/types";
import { useAuthHook } from "../../../../hooks";
import { AttendanceRecordService } from "../../../../services";
import { Box, Button, CircularProgress } from "@mui/material";
import { AttendanceRecordsTable } from "../components";
import { useFetchRecords } from "../../../../shared/hooks/useFetchList.hook";

export const AttendanceRecordsHistoryIndex = () => {
  const { currentUserId } = useAuthHook();
  const {
    records: attendanceRecords,
    loading,
    hasMore,
    loadNextPage,
    count,
  } = useFetchRecords<IAttendanceRecord>({
    service: new AttendanceRecordService(),
    params: {
      collaborator: currentUserId,
      direction: "desc",
      sort_by: "shiftDate",
    },
  });

  return (
    <>
      <AttendanceRecordsTable attendanceRecords={attendanceRecords} />
      <Box display="flex" justifyContent="center" mt={2}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button onClick={loadNextPage} disabled={!hasMore}>
            Show More
          </Button>
        )}
      </Box>
      <pre>{JSON.stringify(attendanceRecords.length, null, 2)}</pre>
    </>
  );
};
