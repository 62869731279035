import { createSlice } from "@reduxjs/toolkit";
import { IJob } from "../../shared/types";
import { jobThunks } from "../thunks/";

interface State {
  jobs: IJob[];
  isLoading: boolean;
}

const initialState: State = {
  jobs: [],
  isLoading: true,
};

export const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(jobThunks.fetchItems.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(jobThunks.fetchItems.fulfilled, (state, action) => {
      state.jobs = action.payload;
      state.isLoading = false;
    });
    builder.addCase(jobThunks.fetchItems.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(jobThunks.createItem.fulfilled, (state, action) => {
      state.jobs.push(action.payload);
    });
    builder.addCase(jobThunks.updateItem.fulfilled, (state, action) => {
      const index = state.jobs.findIndex((job) => job.id === action.payload.id);
      state.jobs[index] = action.payload;
    });
    builder.addCase(jobThunks.deleteItem.fulfilled, (state, action) => {
      state.jobs = state.jobs.filter((job) => job.id !== action.payload);
    });
  },
});
