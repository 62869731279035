import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICollaborator } from "../../shared/types";
import { collaboratorThunks } from "../thunks";

interface State {
  collaborators: ICollaborator[];
  activeCollaborator?: ICollaborator;
  isLoading: boolean;
  isInitialLoading: boolean;
}

const initialState: State = {
  collaborators: [],
  activeCollaborator: undefined,
  isLoading: true,
  isInitialLoading: true,
};

export const collaboratorsSlice = createSlice({
  name: "collaborator",
  initialState,
  reducers: {
    setActiveCollaborator: (state, action: PayloadAction<ICollaborator>) => {
      state.activeCollaborator = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(collaboratorThunks.fetchItems.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      collaboratorThunks.fetchItems.fulfilled,
      (state, action) => {
        state.collaborators = action.payload;
        state.isLoading = false;
        state.isInitialLoading = false;
      }
    );
    builder.addCase(collaboratorThunks.fetchItems.rejected, (state) => {
      state.isLoading = false;
      state.isInitialLoading = false;
    });
    builder.addCase(
      collaboratorThunks.getItemById.fulfilled,
      (state, action) => {
        state.activeCollaborator = action.payload;
      }
    );
    builder.addCase(collaboratorThunks.getItemById.rejected, (state) => {
      state.activeCollaborator = undefined;
    });
    builder.addCase(
      collaboratorThunks.createItem.fulfilled,
      (state, action) => {
        state.collaborators.push(action.payload);
      }
    );
    builder.addCase(
      collaboratorThunks.updateItem.fulfilled,
      (state, action) => {
        const index = state.collaborators.findIndex(
          (collaborators) => collaborators.id === action.payload.id
        );
        state.collaborators[index] = action.payload;
      }
    );
    builder.addCase(
      collaboratorThunks.deleteItem.fulfilled,
      (state, action) => {
        state.collaborators = state.collaborators.filter(
          (collaborators) => collaborators.id !== action.payload
        );
      }
    );
    builder.addCase(
      collaboratorThunks.registerCollaborator.fulfilled,
      (state, action) => {
        state.collaborators.push(action.payload);
      }
    );
    builder.addCase(
      collaboratorThunks.registerCollaborator.rejected,
      (state, action) => {
        console.log(action.error);
      }
    );
    builder.addCase(
      collaboratorThunks.fetchCollaboratorsForWeb.fulfilled,
      (state, action) => {
        state.collaborators = action.payload;
      }
    );
    builder.addCase(
      collaboratorThunks.fetchCollaboratorsForWeb.rejected,
      (state, action) => {
        console.log(action.error);
      }
    );
  },
});

export default collaboratorsSlice.reducer;
