import { IOption } from "../types";

export const getOptionsFromValues = (
  values: string[] | number[] | boolean[]
): IOption[] => {
  return values.map((value) => ({
    value: String(value),
    label: String(value),
  }));
};

export const getOptionsFromProperties = <T extends Record<string, any>>(
  fields: T[],
  labelKey: keyof T,
  valueKey: keyof T
): IOption[] => {
  if (!Array.isArray(fields)) {
    throw new Error("The first argument must be an array.");
  }

  return fields.map((field) => {
    if (field[labelKey] === undefined || field[valueKey] === undefined) {
      throw new Error(
        `Field must contain properties: ${String(labelKey)} and ${String(
          valueKey
        )}`
      );
    }

    return {
      label: String(field[labelKey]), // Ensure label is a string
      value: field[valueKey], // Value can be string or number as defined in IOption
    };
  });
};
