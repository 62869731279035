import React, { useMemo, useState } from "react";
import { sortBy as lodashSortBy } from "lodash";
import { ICollaborator, WebAppRole } from "../../../../shared/types";
import { useAppSelector } from "../../../../hooks";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { CustomAvatar } from "../../../../components/avatar/CustomAvatar";
import { CollaboratorCard } from "../components/CollaboratorCard";
import { CollaboratorListFilters, CollaboratorsListSort } from "../components";
import { PaymentType } from "../../../../shared/enums";

export type CollaboratorFilters = {
  role?: WebAppRole;
  isRegistered?: true | false | undefined;
  isActive?: boolean;
  paymentType?: PaymentType;
  job?: string;
};

export type CollaboratorSortOption =
  | "col_code"
  | "first_name"
  | "job_position"
  | "role"
  | "startDate"
  | "paymentType";

export const CollaboratorOverview = () => {
  const [selectedCollaborator, setSelectedCollaborator] =
    useState<ICollaborator>();

  const handleSelectCollaborator = (collaborator: ICollaborator) => {
    setSelectedCollaborator(collaborator);
  };

  const [filters, setFilters] = useState<CollaboratorFilters>({
    isActive: true, // default state
  });
  const [sortBy, setSortBy] = useState<CollaboratorSortOption>("col_code");

  const { collaborators } = useAppSelector((state) => state.collaborator);
  const { jobs } = useAppSelector((state) => state.jobs);

  const updateFilter = (field: keyof CollaboratorFilters, value: any) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const displayCollaborators = collaborators.filter((collaborator) => {
    const { isActive, isRegistered, paymentType, role, job } = filters;
    const filterChecks = {
      isActive: () =>
        isActive === undefined ||
        isActive === false ||
        collaborator.isActive === true,
      role: () => !role || collaborator.role === role,
      isRegistered: () =>
        isRegistered === undefined ||
        collaborator.isRegistered === isRegistered,
      paymentType: () =>
        !paymentType || collaborator.paymentType === paymentType,
      job: () => !job || collaborator.jobId === job,
    };

    return Object.values(filterChecks).every((check) => check());
  });

  const sortedCollaborators = useMemo(() => {
    return lodashSortBy(displayCollaborators, (c) => {
      switch (sortBy) {
        case "col_code":
          return c.col_code;
        case "first_name":
          return c.first_name;
        case "job_position":
          const job = jobs.find((job) => job.id === c.jobId);
          return job ? job.sortingOrder : Infinity;
        case "role":
          return c.role;
        case "startDate":
          return c.startDate || new Date(0);
        case "paymentType":
          return c.paymentType || "";
        default:
          return c.col_code;
      }
    });
  }, [displayCollaborators, sortBy, jobs]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box>
        <Typography variant="h4">Collaborators</Typography>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <CollaboratorListFilters
            filters={filters}
            updateFilter={updateFilter}
          />
          <CollaboratorsListSort sortBy={sortBy} setSortBy={setSortBy} />
        </Grid>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          height: "100vh", // Set height to 100% of viewport height
          maxHeight: "100vh", // Ensure it doesn't exceed viewport height
          overflow: "hidden", // Hide overflow
        }}
      >
        {/* Left Sidebar for Collaborator List */}
        <Grid item xs={2} sx={{ overflowY: "auto", height: "70%" }}>
          <List>
            {sortedCollaborators.map((collaborator) => (
              <ListItem
                component={ListItemButton}
                key={collaborator.id}
                onClick={() => handleSelectCollaborator(collaborator)}
              >
                <ListItemAvatar>
                  <CustomAvatar
                    collaborator={collaborator}
                    sx={{ width: 40, height: 40 }}
                  />
                </ListItemAvatar>
                <ListItemText primary={collaborator.col_code} />
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Right side for Selected Collaborator Details */}
        <Grid item xs={10}>
          {selectedCollaborator ? (
            <CollaboratorCard collaborator={selectedCollaborator} />
          ) : (
            <Typography variant="body1">
              Select a collaborator to view details
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
