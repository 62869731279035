import hvpAuthenticatedApi from "../api/hvpAuthenticatedApi";
import { ICollaborator, ICollaboratorBase } from "../shared/types/";
import { BaseService } from "./base.service";

export class CollaboratorService extends BaseService<
  ICollaborator,
  ICollaboratorBase
> {
  constructor() {
    super("/collaborators");
  }

  async getCollaboratorsForWeb(): Promise<ICollaborator[]> {
    console.log("inside the getWeb");
    const response = await hvpAuthenticatedApi.get(
      "/collaborators/getAllForWeb"
    );
    console.log("the response", response.data);
    return response.data;
  }

  async registerCollaborator(
    collaborator: ICollaboratorBase
  ): Promise<ICollaborator> {
    const response = await hvpAuthenticatedApi.post(
      "/collaborators/register",
      collaborator
    );
    return response.data.data;
  }
}
