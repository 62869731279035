import * as Yup from "yup";

export const AddressSchema = Yup.object().shape({
  line1: Yup.string(),
  line2: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zip: Yup.string(),
  country: Yup.string(),
});
