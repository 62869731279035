import React from "react";
import { Route, Routes } from "react-router-dom";
// import { SalaryDataIndex, EditSalaryData, NewSalaryData } from ".";
import { EditSalaryData, NewSalaryData, SalaryDataIndex } from "./";

export const SalaryDataRouter = () => {
  return (
    <Routes>
      <Route index element={<SalaryDataIndex />} />
      <Route path="edit/:id" element={<EditSalaryData />} />

      <Route path="new" element={<NewSalaryData />} />
      <Route path="*" element={<SalaryDataIndex />} />
    </Routes>
  );
};
