import React from "react";
import { Box, TextField, Button } from "@mui/material";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getMonday } from "../../shared/helpers";

type Props = {
  date: Date;
  setDate: (date: Date) => void;
};

export const DateSelector = ({ date, setDate }: Props) => {
  const handleDateChange = (newValue: dayjs.Dayjs | null) => {
    console.log({ newValue });
    if (newValue) {
      const newDate = getMonday(newValue);
      console.log({ newValue, newDate });
      setDate(newDate);
    }
  };

  const handleDateChangeByWeeks = (number: number) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + number * 7); // Change by weeks
    // Ensure it's a Monday
    console.log({ newDate });
    setDate(getMonday(newDate));
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, my: 2 }}>
      <Button onClick={() => handleDateChangeByWeeks(-1)} sx={{ height: 56 }}>
        <NavigateBefore />
      </Button>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Date"
          value={dayjs(date).utc()}
          onChange={(newValue) => handleDateChange(newValue)}
          format="DD/MM/YYYY"
        />
      </LocalizationProvider>

      <Button onClick={() => handleDateChangeByWeeks(1)} sx={{ height: 56 }}>
        <NavigateNext />
      </Button>
    </Box>
  );
};
