import { Degree, Gender, PaymentType } from "../enums";
import { ImageUrl } from "../value-objects";
import { AddressVO } from "./address.types";
import { IEntityBase } from "./entity-base.types";

export enum WebAppRole {
  admin = "Administrador",
  manager = "Gerente",
  collaborator = "Colaborador",
  user = "User",
  guest = "Invitado",
}

export interface ICollaboratorBase {
  id?: string;
  // Primary data
  // todo remove this
  _id?: string;
  first_name: string;
  last_name: string;
  email?: string;
  col_code: string;
  col_numId?: number;
  jobId?: string;
  role: WebAppRole;
  isActive: boolean;
  isDisplayedWeb: boolean;

  // General information
  gender?: Gender;
  degree?: Degree;
  phoneNumber?: string;
  phoneNumber2?: string;
  address?: AddressVO;
  curp?: string;
  imssNumber?: string;
  rfcCode?: string;
  emergencyContact?: string;
  emergencyContactPhone?: string;

  // webApp information
  imgUrl?: string;
  images?: ImageUrl[];
  accessCode?: string;
  isRegistered: boolean;
  password?: string;
  textPresentation?: string;
  registeredDate?: Date;
  // TODO: remove this
  lastLogin?: Date;
  vacationsTakenBefore2021?: number;

  // Job information
  // this could be computed
  startDate?: Date;
  endDate?: Date;
  // TODO: remove this
  position?: string;
  coverShift?: boolean;
  weeklyHours?: number;
  contractDate?: Date;
  hasIMSS?: boolean;
  imssEnrollmentDate?: Date;
  // Payroll information
  // TODO: set in a parameter the value of the compensation
  paymentType?: PaymentType;
  additionalCompensation?: number; // based in the hours he goes
  // TODO: enum

  // TODO: enum
}

export interface ICollaborator extends ICollaboratorBase, IEntityBase {
  id: string;
}
