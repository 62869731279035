import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISalaryData } from "../../shared/types";
import { salaryDataThunks } from "../thunks";

interface State {
  salaryData: ISalaryData[];
  activeSalaryData: ISalaryData | null;
  isLoading: boolean;
}

const initialState: State = {
  salaryData: [],
  activeSalaryData: null,
  isLoading: true,
};

export const salaryDataSlice = createSlice({
  name: "salary-data",
  initialState,
  reducers: {
    setActiveSalaryData: (state, action: PayloadAction<ISalaryData>) => {
      state.activeSalaryData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(salaryDataThunks.fetchItems.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(salaryDataThunks.fetchItems.fulfilled, (state, action) => {
      state.salaryData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(salaryDataThunks.fetchItems.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(salaryDataThunks.getItemById.fulfilled, (state, action) => {
      state.activeSalaryData = action.payload;
    });
    builder.addCase(salaryDataThunks.getItemById.rejected, (state) => {
      state.activeSalaryData = null;
    });
    builder.addCase(salaryDataThunks.createItem.fulfilled, (state, action) => {
      state.salaryData.push(action.payload);
    });
    builder.addCase(salaryDataThunks.updateItem.fulfilled, (state, action) => {
      const index = state.salaryData.findIndex(
        (salaryData) => salaryData.id === action.payload.id
      );
      state.salaryData[index] = action.payload;
    });
    builder.addCase(salaryDataThunks.deleteItem.fulfilled, (state, action) => {
      state.salaryData = state.salaryData.filter(
        (salaryData) => salaryData.id !== action.payload
      );
    });
  },
});

export const { setActiveSalaryData } = salaryDataSlice.actions;
export default salaryDataSlice.reducer;
