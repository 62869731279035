import React from "react";
import { MenuRoute, ModuleMenu } from "../../../components";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { ParametersRouter } from "./ParametersRouter";

export const ParametersLayout = () => {
  const routes: MenuRoute[] = [
    { name: "Main", path: "" },
    { name: "Branches", path: "branches" },
    { name: "Jobs", path: "jobs" },
    { name: "Salary Data", path: "salary-data" },
    { name: "Public Holidays", path: "public-holidays" },
  ];

  const isLoading = false;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h4">Parameters</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ModuleMenu routes={routes} />
      </Grid>
      <Grid item xs={12}>
        {isLoading ? <CircularProgress /> : <ParametersRouter />}
      </Grid>
    </Grid>
  );
};
