export interface ValidationRule {
  severity: "critical" | "high" | "medium" | "low";
  message: string;
}

enum DayShiftValidationRules {
  TRAINED_STAFF_PRESENCE = "trainedStaffPresence",
  ASSISTANT_A_FOR_CLOSING = "assistantAForClosing",
  WEEKEND_COVERAGE_URBAN = "weekendCoverageUrban",
  SUNDAY_COVERAGE_HARBOR = "sundayCoverageHarbor",
  RGL_OR_MAT_PAIRING = "rglOrMatPairing",
  VETERINARIAN_AT_ALL_TIMES = "veterinarianAtAllTimes",
  WEEKEND_DOUBLE_COVERAGE = "weekendDoubleCoverage",
  MAT_ADDITIONAL_STAFF_RECOMMENDED = "matAdditionalStaffRecommended",
  OUTSIDE_HOURS = "outsideHours",
}

export const DAY_SHIFT_VALIDATION_RULES: {
  [key in DayShiftValidationRules]: ValidationRule;
} = {
  trainedStaffPresence: {
    severity: "critical",
    message: "Debe haber al menos una persona con formación veterinaria",
  },
  outsideHours: {
    severity: "critical",
    message: "No debe haber personal fuera de horario",
  },
  assistantAForClosing: {
    severity: "high",
    message:
      "Debe haber al menos un asistente A en el horario de cierre en Urban",
  },
  weekendCoverageUrban: {
    severity: "high",
    message: "En fin de semana debe haber al menos 30 horas en Urban",
  },
  rglOrMatPairing: {
    severity: "high",
    message:
      "Siempre que esté RGL o MAT debe haber siempre una persona más en Urban o Harbor",
  },
  sundayCoverageHarbor: {
    severity: "medium",
    message: "El domingo debe haber al menos 30 horas en Harbor",
  },
  veterinarianAtAllTimes: {
    severity: "medium",
    message:
      "En Urban y Harbor debe haber siempre un veterinario a todas horas",
  },
  weekendDoubleCoverage: {
    severity: "medium",
    message:
      "En fin de semana debe haber al menos 2 personas en todas horas en Urban y Harbor",
  },
  matAdditionalStaffRecommended: {
    severity: "low",
    message: "Cuando esté MAT se recomienda que haya al menos dos personas más",
  },
};

export const SEVERITY_COLORS = {
  critical: "#FF0000",
  high: "#FFA500",
  medium: "#FFD700",
  low: "#32CD32",
};
