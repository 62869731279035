import React from "react";
import { SalaryDataForm } from "../components/SalaryDataForm";
import { ISalaryData } from "../../../../../shared/types";

export const NewSalaryData = () => {
  const initialValues: ISalaryData = {
    year: 2024,
    minimumWage: 248.93,
    uma: 108.57,
    ocupationalRisk: 0.005436,
    imssEmployerRates: {
      sicknessAndMaternity: {
        fixedFee: {
          name: "Cuota fija",
          description: "Base: UMA. 106.I",
          rate: 0.204,
        },
        surplus: {
          name: "Excedente",
          description: "Base: Diferencia entre SBC y 3 veces la UMA. 106.II",
          rate: 0.011,
        },
        cashBenefits: {
          name: "Prestaciones en dinero",
          description: "Base: SBC. 107",
          rate: 0.007,
        },
        pensionersAndBeneficiaries: {
          name: "Pensionados y beneficiarios",
          description: "Base: SBC. ???",
          rate: 0.0105,
        },
      },
      disabilityAndLife: {
        disabilityAndLife: {
          name: "Invalidez y vida",
          description: "Base: SBC. ???",
          rate: 0.0175,
        },
      },
      workRisk: {
        workRisk: {
          name: "Riesgos de trabajo",
          description: "Base: SBC. ???",
          rate: 0.005436,
        },
      },
      daycareAndSocialBenefits: {
        daycareAndSocialBenefits: {
          name: "Guarderías y prestaciones sociales",
          description: "Base: SBC. ???",
          rate: 0.01,
        },
      },
      oldAge: {
        retirement: {
          name: "Retiro",
          description: "Base: SBC. ???",
          rate: 0.02,
        },
        oldAge: [
          {
            umaLimit: 1.0,
            rate: 0.0315,
          },
          {
            umaLimit: 1.5,
            rate: 0.03544,
          },
          {
            umaLimit: 2.0,
            rate: 0.04,
          },
          {
            umaLimit: 2.5,
            rate: 0.04353,
          },
          {
            umaLimit: 3.0,
            rate: 0.04588,
          },
          {
            umaLimit: 3.5,
            rate: 0.04756,
          },
          {
            umaLimit: 4.0,
            rate: 0.04882,
          },
          {
            umaLimit: 1000,
            rate: 0.05331,
          },
        ],
      },
      infonavit: {
        infonavit: {
          name: "Infonavit",
          description: "Base: SBC. ???",
          rate: 0.05,
        },
      },
    },
    imssEmployeeRates: {
      sicknessAndMaternity: {
        fixedFee: {
          name: "Cuota fija",
          description: "Base: UMA. 106.I",
          rate: 0.0,
        },
        surplus: {
          name: "Excedente",
          description: "Base: Diferencia entre SBC y 3 veces la UMA. 106.II",
          rate: 0.004,
        },
        cashBenefits: {
          name: "Prestaciones en dinero",
          description: "Base: SBC. 107",
          rate: 0.007,
        },
        pensionersAndBeneficiaries: {
          name: "Pensionados y beneficiarios",
          description: "Base: SBC. ???",
          rate: 0.0025,
        },
      },
      disabilityAndLife: {
        disabilityAndLife: {
          name: "Invalidez y vida",
          description: "Base: SBC. ???",
          rate: 0.00625,
        },
      },
      workRisk: {
        workRisk: {
          name: "Riesgos de trabajo",
          description: "Base: SBC. ???",
          rate: 0.0,
        },
      },
      daycareAndSocialBenefits: {
        daycareAndSocialBenefits: {
          name: "Guarderías y prestaciones sociales",
          description: "Base: SBC. ???",
          rate: 0.0,
        },
      },
      oldAge: {
        retirement: {
          name: "Retiro",
          description: "Base: SBC. ???",
          rate: 0.0,
        },
        oldAge: [
          {
            umaLimit: 1.0,
            rate: 0.01125,
          },
          {
            umaLimit: 1.5,
            rate: 0.01125,
          },
          {
            umaLimit: 2.0,
            rate: 0.01125,
          },
          {
            umaLimit: 2.5,
            rate: 0.01125,
          },
          {
            umaLimit: 3.0,
            rate: 0.01125,
          },
          {
            umaLimit: 3.5,
            rate: 0.01125,
          },
          {
            umaLimit: 4.0,
            rate: 0.01125,
          },
          {
            umaLimit: 1000,
            rate: 0.01125,
          },
        ],
      },
      infonavit: {
        infonavit: {
          name: "Infonavit",
          description: "Base: SBC. ???",
          rate: 0.0,
        },
      },
    },
  };

  return <SalaryDataForm initialValues={initialValues} />;
};
