import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WebAppRole } from "../../shared/types/collaborators.types";

interface User {
  uid?: string;
  col_code?: string;
  role?: WebAppRole;
  image_url?: string;
}

interface AuthState {
  isLoading: boolean;
  user?: User;
  isAuthenticated: boolean;
  error?: string;
}

const initialState: AuthState = {
  isLoading: true,
  isAuthenticated: false,
  user: undefined,
  error: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    requestLogin: (state) => {
      state.isLoading = true;
      state.error = undefined;
    },
    loginSuccess: (state, action: PayloadAction<User>) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    logout: (state) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.user = undefined;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { requestLogin, loginSuccess, loginFailure, logout, setLoading } =
  authSlice.actions;
