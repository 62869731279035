export const VETS = [
  "Director médico",
  "Gerenta médica",
  "Veterinario A",
  "Veterinario B",
];

export const VETS_AND_ASSISTANTSA = [...VETS, "Asistente veterinario A"];

export const ALL_VETERINARY_POSITIONS = [
  ...VETS_AND_ASSISTANTSA,
  "Asistente veterinario B",
  "Practicante de asistente veterinario A",
  "Practicante de asistente veterinario B",
];
