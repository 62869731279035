import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../store/store";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Swal from "sweetalert2";
import { clearUnsavedChanges } from "../../../store/slice";
import { confirmLeaveWithoutSaving } from "../../../helpers";
import { useFormStatusHook } from "../../../shared/hooks/useFormStatus.hook";

type Props = {
  link: string;
  muiIcon: React.ReactNode;
  handleNavLinkClick: () => void;
  label: string;
};

export const SidebarLink: React.FC<Props> = ({
  link,
  muiIcon,
  handleNavLinkClick,
  label,
}) => {
  const { menuState } = useSelector((state: RootState) => state.dbUi);
  const location = useLocation();
  const isActive = location.pathname.startsWith(link);
  const { hasUnsavedChanges } = useAppSelector((state) => state.formStatus);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleUnsavedChanges } = useFormStatusHook();

  const onClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    await handleUnsavedChanges(
      () => {
        navigate(link);
        handleNavLinkClick();
      },
      () => {}
    );
  };

  return (
    <ListItemButton
      component={NavLink}
      to={link}
      onClick={onClick}
      selected={isActive}
      sx={{
        alignItems: "center",
        padding: 0,
        paddingX: 2,
        borderRadius: 2,
        "&.Mui-selected": {
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          "&:hover": {
            backgroundColor: "primary.dark",
          },
        },
      }}
    >
      <ListItemIcon sx={{ minWidth: 0, mr: menuState !== "minimized" ? 2 : 0 }}>
        {muiIcon}
      </ListItemIcon>
      {menuState !== "minimized" && <ListItemText primary={label} />}
    </ListItemButton>
  );
};
