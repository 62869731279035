import React, { useMemo } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import {
  TimeOffStatus,
  TimeOffType,
} from "../../../../shared/types/TimeOffRequest";
import {
  convertTimeOffOverviewToDateTimeOffRequests,
  getUpcomingAndRecentTimeOffDates,
} from "../../../../helpers/timeOffRequestsHelpers";
import { DateTimeOffTable } from "./DateTimeOffTable";
import { getFormattedMxDate } from "../../../../helpers";

export const TimeOffRequestsCollaboratorInfo = () => {
  const { uid } = useAppSelector((state) => state.authReducer.user!);
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);
  const { activeCollaboratorTimeOffOverview, timeOffRequests } = useAppSelector(
    (state) => state.timeOffRequestsReducer
  );

  const collaboratorTimeOffRequests = timeOffRequests.filter(
    (timeOffRequest) => timeOffRequest.collaborator === uid
  );

  const lastAnniversaryDate =
    activeCollaboratorTimeOffOverview?.lastAnniversaryDate ?? undefined;

  const legalVacationDays =
    activeCollaboratorTimeOffOverview?.legalVacationDays ?? 0;
  const totalVacations =
    activeCollaboratorTimeOffOverview?.totalVacationDays ?? 0;
  const currentYearVacationDays = totalVacations - legalVacationDays;
  const remainingVacations =
    activeCollaboratorTimeOffOverview?.remainingVacationDays;
  const vacationsApproved =
    activeCollaboratorTimeOffOverview?.dateTimeOffRequests.filter(
      (dateTimeOffRequest) =>
        dateTimeOffRequest.status === TimeOffStatus.approved &&
        dateTimeOffRequest.timeOffType === TimeOffType.vacation
    ).length ?? 0;
  const vacationsPending =
    activeCollaboratorTimeOffOverview?.dateTimeOffRequests.filter(
      (dateTimeOffRequest) =>
        dateTimeOffRequest.status === TimeOffStatus.pending &&
        dateTimeOffRequest.timeOffType === TimeOffType.vacation
    ).length ?? 0;

  const remainingLegalVacationDays =
    activeCollaboratorTimeOffOverview?.remainingLegalVacationDays ?? 0;
  const remainingCurrentYearVacationDays =
    activeCollaboratorTimeOffOverview?.remainingcurrentYearVacationDays ?? 0;
  const totalRequests = collaboratorTimeOffRequests.length;
  const approvedRequests = collaboratorTimeOffRequests.filter(
    (timeOffRequest) => {
      return timeOffRequest.status === TimeOffStatus.approved;
    }
  ).length;
  const pendingRequests = collaboratorTimeOffRequests.filter(
    (timeOffRequest) => {
      return timeOffRequest.status === TimeOffStatus.pending;
    }
  ).length;
  const rejectedRequests = collaboratorTimeOffRequests.filter(
    (timeOffRequest) => {
      return timeOffRequest.status === TimeOffStatus.rejected;
    }
  ).length;

  const timeOffDates = useMemo(() => {
    return convertTimeOffOverviewToDateTimeOffRequests(collaborators, [
      activeCollaboratorTimeOffOverview!,
    ]);
  }, [collaborators, activeCollaboratorTimeOffOverview]);

  const { upcomingTimeOffDates, recentTimeOffDates } = useMemo(() => {
    return getUpcomingAndRecentTimeOffDates(timeOffDates);
  }, [timeOffDates]);

  console.log({ activeCollaboratorTimeOffOverview });

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          User Info
        </Typography>
        <Typography>
          Día de último aniversario: {getFormattedMxDate(lastAnniversaryDate)}
        </Typography>
        <Divider style={{ margin: "10px 0" }} />
        <Typography>
          Días de vacaciones legalmente obtenidos: {legalVacationDays}
        </Typography>
        <Typography>
          Días de vacaciones del año en curso: {currentYearVacationDays}
        </Typography>
        <Typography>
          Días totales de vacaciones ganadas: {totalVacations}
        </Typography>
        <Divider style={{ margin: "10px 0" }} />
        <Typography>
          Días de vacaciones aprobadas: {vacationsApproved}
        </Typography>
        <Typography>
          Días de vacaciones pendientes: {vacationsPending}
        </Typography>
        <Typography>
          Total de vacaciones aprobadas o pendientes:{" "}
          {vacationsPending + vacationsApproved}
        </Typography>
        <Divider style={{ margin: "10px 0" }} />
        <Typography>
          Días de vacaciones restantes (legales): {remainingLegalVacationDays}
        </Typography>
        <Typography>
          Días de vacaciones restantes (año en curso):{" "}
          {remainingCurrentYearVacationDays}
        </Typography>
        <Typography>
          Total de vacaciones restantes: {remainingVacations}
        </Typography>
        <Divider style={{ margin: "10px 0" }} />
        <Typography>
          Total Time Off Requests Requested: {totalRequests}
        </Typography>
        <Typography>
          Total Time Off Requests Approved: {approvedRequests}
        </Typography>
        <Typography>
          Total Time Off Requests Pending: {pendingRequests}
        </Typography>
        <Typography>
          Total Time Off Requests Rejected: {rejectedRequests}
        </Typography>
        <Box sx={{ mb: 2 }}>
          <Typography gutterBottom>Upcoming Time Off</Typography>
          {upcomingTimeOffDates.length > 0 ? (
            <DateTimeOffTable dateTimeOffRequests={upcomingTimeOffDates} />
          ) : (
            <Typography variant="body2">
              No upcoming time off requests
            </Typography>
          )}

          <Typography mt={4} mb={2} gutterBottom>
            Recent Time Off
          </Typography>
          {recentTimeOffDates.length > 0 ? (
            <DateTimeOffTable dateTimeOffRequests={recentTimeOffDates} />
          ) : (
            <Typography variant="body2">No recent time off requests</Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
