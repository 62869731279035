import React from "react";
import { Typography, Box, IconButton, Modal } from "@mui/material";
import { ImageUrl } from "../../../../shared/value-objects";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  selectedImage: ImageUrl | null;
  handleCloseModal: () => void;
};

export const CollaboratorImgModal = ({
  selectedImage,
  handleCloseModal,
}: Props) => {
  return (
    <Modal open={!!selectedImage} onClose={handleCloseModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxWidth: "90vw",
          maxHeight: "90vh",
        }}
      >
        {selectedImage && (
          <>
            <IconButton
              sx={{ position: "absolute", top: 8, right: 8 }}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
            <img
              src={selectedImage.url}
              alt={`Collaborator ${selectedImage.publicId}`}
              style={{ maxWidth: "100%", maxHeight: "80vh" }}
            />
            {selectedImage.isMain && (
              <Typography variant="caption" sx={{ mt: 1, display: "block" }}>
                Main Image
              </Typography>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};
