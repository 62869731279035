import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Badge,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";

import { toggleMenu } from "../../../../actions/dbUiActions";
import {
  useAppDispatch,
  useAppSelector,
  useCollaboratorHook,
} from "../../../../hooks";
import {
  changeUserCredentialsByEmailAndPassword,
  changeUserCredentialsById,
  startLoggingOut,
} from "../../../../store/thunks/authThunks";
import { hasAuthorization } from "../../../../helpers";
import { WebAppRole } from "../../../../shared/types/collaborators.types";
import { ChangeUserDialog } from "./ChangeUserDialog";
import ChangeUserByAdminDialog from "./ChangeUserByAdminDialog";
import { NotificationsMenu } from "./NotificationsMenu";
import UserMenu from "./UserMenu";
import { fetchNotifications } from "../../../../store/thunks/notificationThunks";

export const Topbar = () => {
  const { getCurrentCollaborator } = useCollaboratorHook();
  const { imgUrl, role } = getCurrentCollaborator() || {};
  const { uid } = useAppSelector((state) => state.authReducer.user!);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isChangeUserModalOpen, setChangeUserModalOpen] = useState(false);
  const [isChangeUserByAdminModalOpen, setChangeUserByAdminModalOpen] =
    useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedCollaborator, setSelectedCollaborator] = useState<string>(
    uid!
  );
  const [notificationAnchorEl, setNotificationAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleMenuClick = () => {
    dispatch(toggleMenu());
  };

  const handleLogout = async () => {
    await appDispatch(startLoggingOut());
    navigate("/");
  };

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeUserClick = () => {
    const isAdmin = hasAuthorization(role, WebAppRole.admin);
    if (isAdmin) {
      setChangeUserByAdminModalOpen(true);
    } else {
      setChangeUserModalOpen(true);
    }
  };

  const handleCloseChangeUserModal = () => {
    setChangeUserModalOpen(false);
  };

  const handleCloseChangeUserByAdminModal = () => {
    setChangeUserByAdminModalOpen(false);
  };

  const handleChangePassword = () => {
    navigate("/dashboard/collaborators/change-password");
  };

  const handleSubmitChangeUser = async () => {
    await appDispatch(changeUserCredentialsByEmailAndPassword(email, password));
    handleCloseChangeUserModal();
    handleClose();
  };

  const handleSubmitChangeUserByAdmin = async () => {
    await appDispatch(changeUserCredentialsById(selectedCollaborator));
    handleCloseChangeUserByAdminModal();
    handleClose();
  };

  const handleSelectCollaborator = (event: SelectChangeEvent<string>) => {
    setSelectedCollaborator(event.target.value);
  };

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  const { notifications } = useAppSelector(
    (state) => state.notificationsReducer
  );

  return (
    <AppBar position="sticky">
      <Toolbar style={{ minHeight: "48px", padding: "0 8px" }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
          style={{ padding: "8px" }}
        >
          <MenuIcon />
        </IconButton>
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src="assets/imgs/Logo_HVP.png"
            alt="HVP Logo"
            style={{ marginRight: "10px", width: "32px", height: "32px" }}
          />
        </Link>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Administración HVP
        </Typography>

        <IconButton color="inherit" onClick={handleNotificationClick}>
          <Badge
            badgeContent={
              notifications.filter((notification) => !notification.read).length
            }
            color="secondary"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <NotificationsMenu
          anchorEl={notificationAnchorEl}
          notifications={notifications}
          onClose={handleNotificationClose}
        />
        <IconButton color="inherit" onClick={handleLogout}>
          <LogoutIcon />
        </IconButton>
        <Avatar
          src={imgUrl}
          onClick={handleAvatarClick}
          style={{ cursor: "pointer", width: "32px", height: "32px" }}
        />
        <UserMenu
          anchorEl={anchorEl}
          onClose={handleClose}
          onChangePassword={handleChangePassword}
          onChangeUserClick={handleChangeUserClick}
          onLogout={handleLogout}
        />
      </Toolbar>
      <ChangeUserDialog
        open={isChangeUserModalOpen}
        onClose={handleCloseChangeUserModal}
        onSubmit={handleSubmitChangeUser}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
      />
      <ChangeUserByAdminDialog
        open={isChangeUserByAdminModalOpen}
        onClose={handleCloseChangeUserByAdminModal}
        onSubmit={handleSubmitChangeUserByAdmin}
        selectedCollaborator={selectedCollaborator}
        handleSelectCollaborator={handleSelectCollaborator}
      />
    </AppBar>
  );
};
