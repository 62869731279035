import Swal from "sweetalert2";
import { IOption } from "../shared/types/option.types";
import {
  convertArrayToObject,
  sortAlphabeticallyByProperty,
} from "./sorting.helpers";

export const fireSwalError = (message: string) => {
  return Swal.fire({
    icon: "error",
    title: "ERROR",
    text: message,
    showConfirmButton: true,
  });
};

export const fireSwalSuccess = (message: string) => {
  return Swal.fire({
    icon: "success",
    title: "ÉXITO",
    text: message,
    showConfirmButton: false,
    timer: 2000,
  });
};

export const fireSwalWarning = async (
  title: string = "WARNING",
  message: string = "warning"
) => {
  return await Swal.fire({
    icon: "warning",
    title: title,
    text: message,
    showConfirmButton: true,
    showCancelButton: true,
  });
};

export const fireSwalConfirmation = async (message: string) => {
  const result = await Swal.fire({
    icon: "warning",
    title: "CONFIRMACIÓN",
    text: message,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sí!",
  });

  return result.isConfirmed;
};

export const fireSwalQuestionSelect = async (
  message: string,
  inputOptions: IOption[],
  inputPlaceholder: string
) => {
  console.log({ message, inputOptions, inputPlaceholder });
  const sortedOptions = sortAlphabeticallyByProperty("label", inputOptions);
  const objectOptions = convertArrayToObject(sortedOptions, "value");

  console.log({ objectOptions });

  const result = await Swal.fire({
    title: "RESPONDE",
    text: message,
    icon: "question",
    input: "select",
    inputOptions: objectOptions,
    inputPlaceholder: inputPlaceholder,
    showCancelButton: true,
  });

  return result.value;
};

export const fireSwalQuestionInput = async (
  message: string,
  inputLabel: string
) => {
  const result = await Swal.fire({
    title: "RESPONDE",
    text: message,
    icon: "question",
    input: "text",
    inputLabel,
  });

  return result.value;
};

export const confirmLeaveWithoutSaving = async () =>
  await Swal.fire({
    title: "Unsaved Changes",
    text: "You have unsaved changes. Do you want to discard them?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Discard",
    cancelButtonText: "Keep Editing",
  });
