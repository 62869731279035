import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IPublicHolidays } from "../../../../../../shared/types";
import { PublicHolidaysService } from "../../../../../../services";

import { CircularProgress } from "@mui/material";
import { PublicHolidayForm } from "../../components";

export const EditPublicHolidays = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [initialValues, setInitialValues] = useState<IPublicHolidays>();

  useEffect(() => {
    if (id) {
      const fetchById = async () => {
        try {
          const service = new PublicHolidaysService();
          const resource = await service.getById(id);
          setInitialValues(resource);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      fetchById();
    }
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!initialValues) {
    return <div>Public Holidays not found</div>;
  }

  return <PublicHolidayForm initialValues={initialValues} />;
};
