import React from "react";
import { Box, Typography } from "@mui/material";
import {
  AttendanceRecordAction,
  IAttendanceRecordBase,
} from "../../../../shared/types";
import { formatNumber } from "../../../../shared/helpers";
import { useBranchHook } from "../../../../hooks";
import { calculateDistanceInKm } from "../../../../shared/helpers/distance.helpers";

type Props = {
  attendanceRecord: IAttendanceRecordBase;
  currentPosition: { lat: number; lng: number };
  attendanceRecordStatus: "unregistered" | "clockedIn" | "clockedOut";
  selectedBranchName: string;
};

export const AttendanceActionDisplayMessage = ({
  attendanceRecord,
  currentPosition,
  attendanceRecordStatus,
  selectedBranchName: selectedBranch,
}: Props) => {
  // Threshold in km for the "unusual position" notice
  const distanceThreshold = 0.5;
  const { getBranchNameById, getBranchCoordinatesByName } = useBranchHook();
  const branchName = getBranchNameById(selectedBranch);
  const distance = calculateDistanceInKm(
    currentPosition,
    getBranchCoordinatesByName(branchName!)
  );
  const isUnusualPosition = distance > distanceThreshold;

  if (attendanceRecordStatus === "clockedOut")
    return (
      <Box>
        <Typography variant="h5" sx={{ color: "green", fontWeight: 600 }}>
          You have already registered today
        </Typography>
      </Box>
    );

  return (
    <Box sx={{ my: 2, textAlign: "center" }}>
      <Typography
        variant="h6"
        sx={{
          color:
            attendanceRecord.action === AttendanceRecordAction.ClockIn
              ? "green"
              : "red",
        }}
      >
        {attendanceRecordStatus === "unregistered" ? "Clock in" : "Clock out"}
        {` from ${branchName} at `}
        {new Date().toLocaleTimeString()}
      </Typography>
      {isUnusualPosition && (
        <Typography variant="body2" sx={{ color: "orange", mt: 1 }}>
          Location Notice: The current position appears unusual (about{" "}
          {formatNumber(distance)} km away from the branch). Please verify that
          you are at the right branch.
        </Typography>
      )}
    </Box>
  );
};
