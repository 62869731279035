import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import dayjs from "dayjs";
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const DEFAULT_DATE = "1970-01-01";

export const isPreviousTime = (time1: string, time2: string) => {
  const time1Date = dayjs(`${DEFAULT_DATE} ${time1}`, "YYYY-MM-DD HH:mm");
  const time2Date = dayjs(`${DEFAULT_DATE} ${time2}`, "YYYY-MM-DD HH:mm");
  return time1Date.isBefore(time2Date);
};

export const isAfterTime = (time1: string, time2: string) => {
  const time1Date = dayjs(`${DEFAULT_DATE} ${time1}`, "YYYY-MM-DD HH:mm");
  const time2Date = dayjs(`${DEFAULT_DATE} ${time2}`, "YYYY-MM-DD HH:mm");
  return time1Date.isAfter(time2Date);
};

export const isPreviousTimeOrSame = (time1: string, time2: string) => {
  if (!time1 || !time2) return false;
  const time1Date = dayjs(`${DEFAULT_DATE} ${time1}`, "YYYY-MM-DD HH:mm");
  const time2Date = dayjs(`${DEFAULT_DATE} ${time2}`, "YYYY-MM-DD HH:mm");
  return time1Date.isSameOrBefore(time2Date);
};

export const isAfterTimeOrSame = (time1: string, time2: string) => {
  if (!time1 || !time2) return false;
  const time1Date = dayjs(`${DEFAULT_DATE} ${time1}`, "YYYY-MM-DD HH:mm");
  const time2Date = dayjs(`${DEFAULT_DATE} ${time2}`, "YYYY-MM-DD HH:mm");
  return time1Date.isSameOrAfter(time2Date);
};

export const getDateFromTime = (time: string) => {
  return dayjs(`${DEFAULT_DATE} ${time}`, "YYYY-MM-DD HH:mm");
};

export const getHoursDifference = (
  startTime: string,
  endTime: string
): number => {
  const start = getDateFromTime(startTime);
  const end = getDateFromTime(endTime);
  return end.diff(start, "hour");
};

export const getMaxTime = (time1: string, time2?: string) => {
  if (!time2) return time1;
  const time1Date = dayjs(`${DEFAULT_DATE} ${time1}`, "YYYY-MM-DD HH:mm");
  const time2Date = dayjs(`${DEFAULT_DATE} ${time2}`, "YYYY-MM-DD HH:mm");
  return time1Date.isAfter(time2Date) ? time1 : time2;
};
