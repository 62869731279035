import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { salaryDataThunks } from "../../../../store/thunks";
import { Box, Typography } from "@mui/material";
import { YearSelector } from "../../../../components";
import { ISalaryData } from "../../../../shared/types";
import { WageDataCard } from "./components";

export const SalaryDataIndex = () => {
  const dispatch = useAppDispatch();
  const [currentSalaryData, setcurrentSalaryData] = useState<ISalaryData>();

  const [year, setYear] = useState<number>(new Date().getFullYear());
  const { salaryData } = useAppSelector((state) => state.salaryData);

  useEffect(() => {
    dispatch(salaryDataThunks.fetchItems());
  }, [dispatch, year]);

  useEffect(() => {
    setcurrentSalaryData(salaryData.find((item) => item.year === year));
  }, [salaryData, year]);

  return (
    <Box>
      <Box>
        <Typography variant="h5">Salary data values</Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <YearSelector year={year} setYear={setYear} />
      </Box>
      <Box>
        {currentSalaryData ? (
          <WageDataCard data={currentSalaryData} />
        ) : (
          <Box>
            <Typography>No data found for this year</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
