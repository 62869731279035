import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Grid } from "@mui/material";
import { branchThunks } from "../../../../store/thunks/branch.thunks";
import { BranchCard } from "./components/BranchCard";
import { fireSwalError } from "../../../../helpers";

export const BranchList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(branchThunks.fetchItems());
  }, [dispatch]);

  const handleDelete = (id: string) => {
    fireSwalError("Error deleting branch");
    //dispatch(branchThunks.deleteItem(id));
  };

  const handleEdit = (id: string) => {
    console.log("Edit branch:", id);
    navigate(`/dashboard/parameters/branches/edit/${id}`);
  };

  const { branches, isLoading } = useAppSelector((state) => state.branches);
  console.log({ branches });

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {branches.map((branch) => (
          <Grid
            item
            sm={12}
            md={6}
            lg={4}
            key={branch.id}
            sx={{ display: "flex" }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <BranchCard
                branch={branch}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
