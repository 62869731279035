import { Grid } from "@mui/material";
import React from "react";
import { CustomSelect } from "../../../../components/inputs/CustomSelect";
import { CollaboratorSortOption } from "../hooks/useFilteredSortedCollaboratorsHook";

type Props = {
  sortBy: string;
  setSortBy: React.Dispatch<React.SetStateAction<CollaboratorSortOption>>;
};
export const CollaboratorsListSort = ({ sortBy, setSortBy }: Props) => {
  const sortOptions = [
    { label: "Collaborator Code", value: "col_code" },
    { label: "First Name", value: "first_name" },
    { label: "Job Position", value: "job_position" },
    { label: "Role", value: "role" },
    { label: "Start Date", value: "startDate" },
    { label: "Payment Type", value: "paymentType" },
  ];

  return (
    <Grid item xs={12} md={12 / 3} xl={12 / 6}>
      <CustomSelect
        options={sortOptions}
        label="Sort by"
        onChange={(e) => setSortBy(e.target.value as CollaboratorSortOption)}
        value={sortBy}
      />
    </Grid>
  );
};
