import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Paper,
  Box,
  TextField,
  CircularProgress,
} from "@mui/material";
import { getFormattedMxDate } from "../../../../helpers/dateHelpers";
import {
  getCollaboratorAvatarDataById,
  getCollaboratorById,
} from "../../../../helpers/collaboratorsHelpers";
import { CustomAvatar } from "../../../../components/avatar/CustomAvatar";
import {
  TimeOffRequest,
  TimeOffStatus,
} from "../../../../shared/types/TimeOffRequest";
import { useTimeOffRequestsStore } from "../../../../hooks/useTimeOffRequestsStore";
import { WebAppRole } from "../../../../shared/types/collaborators.types";

export const TimeOffRequestDetailView = () => {
  // TODO only manager can approve or reject
  const [managerNote, setManagerNote] = useState("");
  const [timeOffRequest, settimeOffRequest] = useState<
    TimeOffRequest | undefined
  >(undefined);
  const [isLoading, setisLoading] = useState(false);

  const { id } = useParams();
  const { timeOffRequests } = useAppSelector(
    (state) => state.timeOffRequestsReducer
  );

  const { role } = useAppSelector((state) => state.authReducer.user!);

  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);

  const labelStyle = { fontWeight: "bold" };
  const valueStyle = { fontWeight: "normal" };

  const {
    startApprovingTimeOffRequest,
    startDeletingTimeOffRequest,
    startUpdatingTimeOffRequest,
  } = useTimeOffRequestsStore();

  const handleSubmit = (timeOffStatus: TimeOffStatus) => {
    startApprovingTimeOffRequest(id!, managerNote, timeOffStatus);
    // approveRequest(managerNote);
  };

  const handleDelete = () => {
    startDeletingTimeOffRequest(id!);
  };

  const handleUnauthorize = () => {
    const timeOffRequestToUpdate = {
      ...timeOffRequest!,
      status: TimeOffStatus.pending,
    };

    startUpdatingTimeOffRequest(timeOffRequestToUpdate);
  };

  const handleWithdraw = () => {
    const timeOffRequestToUpdate = {
      ...timeOffRequest!,
      status: TimeOffStatus.canceled,
    };

    startUpdatingTimeOffRequest(timeOffRequestToUpdate);
  };

  const collaboratorAvatarData =
    !isLoading &&
    getCollaboratorAvatarDataById(collaborators, timeOffRequest?.collaborator!);

  const createdByAvatarData =
    !isLoading &&
    getCollaboratorAvatarDataById(collaborators, timeOffRequest?.createdBy!);

  const approvedByData =
    !isLoading && timeOffRequest?.approvedBy
      ? getCollaboratorAvatarDataById(collaborators, timeOffRequest?.approvedBy)
      : undefined;

  const hasAuthorization =
    role === WebAppRole.manager || role === WebAppRole.admin;

  const isResolved =
    timeOffRequest?.status === TimeOffStatus.approved ||
    timeOffRequest?.status === TimeOffStatus.rejected;

  useEffect(() => {
    if (timeOffRequests.length === 0) return;
    const timeOffRequest = timeOffRequests.find(
      (timeOffRequest) => timeOffRequest._id === id
    );
    settimeOffRequest(timeOffRequest);
    setisLoading(false);
  }, [timeOffRequests, id]);

  if (isLoading) return <CircularProgress />;

  console.log({ timeOffRequest });

  return (
    <Grid item xs={12}>
      <Paper elevation={3}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              <span style={labelStyle}>Type:</span>{" "}
              <span style={valueStyle}>{timeOffRequest?.timeOffType}</span>
            </Typography>
            <Box mt={2}>
              <Typography color="textSecondary">
                <span style={labelStyle}>Collaborator:</span>{" "}
              </Typography>
              {collaboratorAvatarData && (
                <CustomAvatar
                  collaborator={
                    getCollaboratorById(
                      collaborators,
                      timeOffRequest?.collaborator!
                    )!
                  }
                  sx={{ width: 100, height: 100 }}
                />
              )}
              <Typography color="textSecondary">
                <span style={labelStyle}>CreatedBy:</span>{" "}
              </Typography>
              {createdByAvatarData && (
                <CustomAvatar
                  collaborator={
                    getCollaboratorById(
                      collaborators,
                      timeOffRequest?.createdBy!
                    )!
                  }
                  sx={{ width: 100, height: 100 }}
                />
              )}

              <Typography color="textSecondary">
                <span style={labelStyle}>ApprovedBy:</span>{" "}
              </Typography>
              {approvedByData && (
                <CustomAvatar
                  collaborator={
                    getCollaboratorById(
                      collaborators,
                      timeOffRequest?.approvedBy!
                    )!
                  }
                  sx={{ width: 100, height: 100 }}
                />
              )}

              <Typography color="textSecondary">
                <span style={labelStyle}>Current Status:</span>{" "}
                <span style={valueStyle}>{timeOffRequest?.status}</span>
              </Typography>
              <Typography color="textSecondary">
                <span style={labelStyle}>Requested Days:</span>{" "}
                <span style={valueStyle}>
                  {timeOffRequest?.requestedDays
                    .map(getFormattedMxDate)
                    .join(", ")}
                </span>
              </Typography>
              <Typography color="textSecondary">
                <span style={labelStyle}>Requested At:</span>{" "}
                <span style={valueStyle}>
                  {getFormattedMxDate(timeOffRequest?.requestedAt!)}
                </span>
              </Typography>
              <Typography color="textSecondary">
                <span style={labelStyle}>Approval Date:</span>{" "}
                <span style={valueStyle}>
                  {timeOffRequest?.approvedAt
                    ? getFormattedMxDate(timeOffRequest?.approvedAt)
                    : ""}
                </span>
              </Typography>
              <Typography color="textSecondary">
                <span style={labelStyle}>Collaborator's Note:</span>{" "}
                <span style={valueStyle}>
                  {timeOffRequest?.collaboratorNote}
                </span>
              </Typography>

              <Typography color="textSecondary">
                <span style={labelStyle}>Managers's Note:</span>{" "}
                <span style={valueStyle}>{timeOffRequest?.managerNote}</span>
              </Typography>
            </Box>
          </CardContent>
          <Box m={2}>
            <Typography color="textSecondary">
              <span style={labelStyle}>Manager's Note:</span>{" "}
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={managerNote}
              onChange={(e) => setManagerNote(e.target.value)}
              variant="outlined"
            />

            <Box m={2} display="flex" justifyContent="space-around">
              {hasAuthorization && !isResolved && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit(TimeOffStatus.approved)}
                >
                  Aprobar
                </Button>
              )}
              {hasAuthorization && !isResolved && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleSubmit(TimeOffStatus.rejected)}
                >
                  Rechazar
                </Button>
              )}
              {hasAuthorization && !isResolved && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDelete}
                >
                  Eliminar
                </Button>
              )}
              {hasAuthorization && isResolved && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleUnauthorize}
                >
                  Desautorizar
                </Button>
              )}
              {!isResolved && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleWithdraw}
                >
                  Retirar
                </Button>
              )}
            </Box>
          </Box>
        </Card>
      </Paper>
    </Grid>
  );
};
