import { createSlice } from "@reduxjs/toolkit";
import { IBranch } from "../../shared/types";
import { branchThunks } from "../thunks/branch.thunks";
import { sortBranchesByRelevance } from "../../shared/helpers";

interface State {
  branches: IBranch[];
  isLoading: boolean;
}

const initialState: State = {
  branches: [],
  isLoading: true,
};

export const branchSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(branchThunks.fetchItems.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(branchThunks.fetchItems.fulfilled, (state, action) => {
      state.branches = sortBranchesByRelevance(action.payload);
      state.branches = action.payload;
      state.isLoading = false;
    });
    builder.addCase(branchThunks.fetchItems.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(branchThunks.createItem.fulfilled, (state, action) => {
      state.branches.push(action.payload);
    });
    builder.addCase(branchThunks.updateItem.fulfilled, (state, action) => {
      const index = state.branches.findIndex(
        (branch) => branch.id === action.payload.id
      );
      state.branches[index] = action.payload;
    });
    builder.addCase(branchThunks.deleteItem.fulfilled, (state, action) => {
      state.branches = state.branches.filter(
        (branch) => branch.id !== action.payload
      );
    });
  },
});
