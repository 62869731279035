import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { DayPicker } from "react-day-picker";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { Task, TaskActivity } from "../../../../../shared/types/Task";
import { ICollaborator } from "../../../../../shared/types/collaborators.types";

type Props = {
  workingTask: Task;
  handleSave: (task: Task) => Promise<void>;
  setshowNewTaskForm: React.Dispatch<React.SetStateAction<boolean>>;
};
export const AddNewActivity = ({
  handleSave,
  setshowNewTaskForm,
  workingTask,
}: Props) => {
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);
  const [newActivity, setnewActivity] = useState<TaskActivity>({
    content: "",
    author: "",
    registeredAt: new Date().toISOString(),
  });

  const handleSaveNewActivity = async () => {
    if (newActivity.author && newActivity.content) {
      const updatedTask = { ...workingTask };
      const activities = [...(updatedTask.activities ?? [])];
      activities.push(newActivity);

      updatedTask.activities = activities;

      await handleSave(updatedTask);

      setshowNewTaskForm(false);
    }
  };

  const handleCancelNewActivity = () => {
    if (newActivity.author && newActivity.content) {
      setnewActivity({
        createdAt: new Date().toISOString(),
        createdBy: "",
        content: "",
        author: "",
        registeredAt: new Date().toISOString(),
        _id: "",
        updatedAt: "",
        updatedBy: "",
      });
      setshowNewTaskForm(false);
    }
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof newActivity;
    setnewActivity({
      ...newActivity,
      [name]: event.target.value as string,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const name = event.target.name as keyof typeof newActivity;
    setnewActivity({
      ...newActivity,
      [name]: event.target.value as string,
    });
  };

  const handleDayPickerChange = (day: Date) => {
    setnewActivity({
      ...newActivity,
      registeredAt: day.toISOString(),
    });
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="author-label">Author</InputLabel>
        <Select
          labelId="author-label"
          id="author"
          name="author"
          value={newActivity.author}
          onChange={handleSelectChange}
        >
          {collaborators.map((collaborator: ICollaborator) => (
            <MenuItem key={collaborator.id} value={collaborator.id}>
              {collaborator.col_code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DayPicker
        selected={new Date(newActivity.registeredAt) ?? new Date()}
        onDayClick={handleDayPickerChange}
      />
      <TextField
        margin="dense"
        id="content"
        label="Content"
        type="text"
        fullWidth
        name="content"
        value={newActivity.content}
        onChange={handleTextFieldChange}
      />
      <Button onClick={handleCancelNewActivity} color="primary">
        Cancel
      </Button>
      <Button onClick={handleSaveNewActivity} color="primary">
        Save
      </Button>
    </Box>
  );
};
