import React, { useCallback, useState } from "react";
import {
  TableCell,
  TableRow,
  IconButton,
  Box,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import { Delete as DeleteIcon, Save as SaveIcon } from "@mui/icons-material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import WarningIcon from "@mui/icons-material/Warning";
import {
  CollaboratorDayShift,
  ExtendedCollaboratorDayShift,
} from "../../../../shared/types";
import {
  useAppDispatch,
  useBranchHook,
  useCollaboratorHook,
} from "../../../../hooks";
import {
  calculateTotalHoursCovered,
  getMaxTime,
  getSeverityColor,
  validateShift,
} from "../../../../shared/helpers";
import { setHasUnsavedChanges } from "../../../../store/slice";

type BaseProps = {
  rowLabel: string;
  branchId: string;
  weekDates: string[];
  shifts: ExtendedCollaboratorDayShift[];
};

type EditableProps = {
  setShifts: React.Dispatch<React.SetStateAction<CollaboratorDayShift[]>>;
  editable: true;
};

type NonEditableProps = {
  editable: false;
};

type Props = BaseProps & (EditableProps | NonEditableProps);

export const WeekShiftRowTable = (props: Props) => {
  const { rowLabel, branchId, weekDates, shifts, editable } = props;
  const dispatch = useAppDispatch();
  const { getCollaboratorById } = useCollaboratorHook();
  const { getBranchById } = useBranchHook();
  const [editShiftId, setEditShiftId] = useState<string | null>(null);
  const [editedShift, setEditedShift] = useState<{
    startingTime: string;
    endingTime: string;
  }>({
    startingTime: "",
    endingTime: "",
  });

  const handleEditClick = (shift: any) => {
    if (!editable) return;

    setEditShiftId(shift.id);
    setEditedShift({
      startingTime: shift.startingTime,
      endingTime: shift.endingTime,
    });
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target; // Get the input id and value
    // Validate the time format to ensure it is in HH:mm
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/; // HH:mm format

    // Check if the input value matches the HH:mm format
    if (regex.test(value)) {
      setEditedShift((prev) => ({
        ...prev,
        [id]: value, // Use the id to dynamically update startingTime or endingTime
      }));
    } else {
      console.log("Invalid time format:", value);
    }
  };

  const handleSaveClick = useCallback(
    (shiftId: string) => {
      console.log({ editedShift });
      if (!editable) return;
      props.setShifts((prevShifts) =>
        prevShifts.map((shift) =>
          shift.id === shiftId
            ? {
                ...shift,
                startingTime: editedShift.startingTime,
                endingTime: getMaxTime(
                  editedShift.endingTime,
                  editedShift.startingTime
                ),
              }
            : shift
        )
      );
      dispatch(setHasUnsavedChanges({ hasUnsavedChanges: true }));
      setEditShiftId(null);
    },
    [editedShift.endingTime, editedShift.startingTime]
  );

  const handleRemoveShift = (shiftId: string) => {
    if (!editable) return;
    props.setShifts((prevShifts) =>
      prevShifts.filter((shift) => shift.id !== shiftId)
    );
    dispatch(setHasUnsavedChanges({ hasUnsavedChanges: true }));
  };

  return (
    <TableRow sx={{ height: 80 }}>
      <TableCell>
        <Typography fontWeight={600}>{rowLabel}</Typography>
      </TableCell>
      {weekDates.map((date) => {
        const filteredShifts = shifts.filter(
          (shift) => shift.shiftDate === date
        );

        const totalHours = calculateTotalHoursCovered(filteredShifts);

        const branch = getBranchById(branchId);

        const errors = validateShift({
          date,
          shifts: filteredShifts,
          branch,
        });

        const droppableId = `${date}-${branchId}`;

        return (
          // Each of these is a table cell (day/branch)
          <TableCell key={date} sx={{ padding: 0.75, fontSize: 12 }}>
            <Droppable droppableId={droppableId}>
              {(provided, snapshot) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    backgroundColor: snapshot.isDraggingOver
                      ? "lightblue"
                      : "inherit",
                    minHeight: "10px", // Ensure a minimum height for empty cells
                  }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    {editable && errors.length > 0 && (
                      <Tooltip
                        title={errors.map((error, index) => (
                          <div key={index}> - {error.message}</div>
                        ))}
                        arrow
                      >
                        <IconButton size="small">
                          <WarningIcon
                            fontSize="small"
                            sx={{ color: getSeverityColor(errors) }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Typography fontSize={10} color="blue">
                      {totalHours}
                    </Typography>
                  </Box>
                  {filteredShifts.map((shift, index) => {
                    return (
                      <Draggable
                        key={shift.id}
                        draggableId={shift.id ?? ""}
                        index={index}
                        isDragDisabled={editShiftId !== null || !editable}
                      >
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              gap: "2px",
                            }}
                          >
                            {editShiftId === shift.id ? (
                              // Editable state
                              <Box>
                                <span>
                                  {
                                    getCollaboratorById(shift.collaboratorId)
                                      ?.col_code
                                  }{" "}
                                </span>
                                <TextField
                                  type="time"
                                  id="startingTime"
                                  value={editedShift.startingTime}
                                  onChange={handleTimeChange}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    step: 900,
                                  }}
                                  variant="outlined" // Use Material-UI's outlined variant
                                  fullWidth
                                  size="small"
                                />
                                <TextField
                                  type="time"
                                  id="endingTime"
                                  value={editedShift.endingTime}
                                  onChange={handleTimeChange}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    step: 900, // 5 min
                                  }}
                                  variant="outlined" // Use Material-UI's outlined variant
                                  fullWidth
                                  size="small"
                                />
                                <IconButton
                                  color="primary"
                                  onClick={() =>
                                    handleSaveClick(shift.id ?? "")
                                  }
                                  size="small"
                                >
                                  <SaveIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            ) : (
                              // Non-editable state
                              <Box
                                onClick={() => handleEditClick(shift)}
                                key={shift.id}
                                style={{ cursor: "pointer" }}
                                minHeight={24}
                              >
                                <span>
                                  {
                                    getCollaboratorById(shift.collaboratorId)
                                      ?.col_code
                                  }{" "}
                                  {shift.startingTime} - {shift.endingTime}
                                </span>
                                {editable && (
                                  <IconButton
                                    disabled={!editable}
                                    color="secondary"
                                    onClick={() =>
                                      handleRemoveShift(shift.id ?? "")
                                    }
                                    size="small"
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                )}
                              </Box>
                            )}
                          </Box>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </TableCell>
        );
      })}
    </TableRow>
  );
};
