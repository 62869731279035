import React, { useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { fetchInitialData } from "../../../store/thunks/attendance-records-thunks";
import { MenuRoute, ModuleMenu } from "../../../components";
import { WebAppRole } from "../../../shared/types";
import { AttendanceRecordsRouter } from "./AttendanceRecordsRouter";

export const AttendanceRecordsLayout = () => {
  const routes: MenuRoute[] = [
    { name: "Main", path: "" },
    { name: "History", path: "history" },
    {
      name: "Admin Panel",
      path: "admin-panel",
      authorizedRoles: [WebAppRole.admin, WebAppRole.manager],
    },
    {
      name: "Report",
      path: "report",
    },
    {
      name: "Reports",
      path: "reports",
      authorizedRoles: [WebAppRole.admin, WebAppRole.manager],
    },
  ];

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchInitialData());
  }, [dispatch]);

  const { isInitialLoading } = useAppSelector(
    (state) => state.attendanceRecordsReducer
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ModuleMenu routes={routes} />
      </Grid>
      <Grid item xs={12}>
        {isInitialLoading ? <CircularProgress /> : <AttendanceRecordsRouter />}
      </Grid>
    </Grid>
  );
};
