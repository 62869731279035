import React, { useEffect } from "react";
import { MenuRoute, ModuleMenu } from "../../../components";
import { Grid } from "@mui/material";
import { CollaboratorsRouter } from "./";
import { useAppDispatch } from "../../../hooks";
// import { ParametersRouter } from "./ParametersRouter";

export const CollaboratorsLayout = () => {
  const routes: MenuRoute[] = [
    { name: "List", path: "" },
    { name: "New", path: "new" },
    { name: "Overview", path: "overview" },
  ];

  const dispatch = useAppDispatch();

  useEffect(() => {
    // dispatch(fetchCollaborators());
  }, [dispatch]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ModuleMenu routes={routes} />
      </Grid>
      <Grid item xs={12}>
        <CollaboratorsRouter />
      </Grid>
    </Grid>
  );
};
