import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { ImssRates, ISalaryData } from "../../../../../shared/types";
import { get } from "lodash";

type Props = {
  salaryData: ISalaryData;
};
export const ImssRatesData = ({ salaryData }: Props) => {
  const { imssEmployerRates, imssEmployeeRates } = salaryData;

  const findRate = (property: string, rates: ImssRates) => {
    return get(rates, property, null);
  };

  const renderRate = (property: string, rates: ImssRates) => {
    const rate = findRate(property, rates);
    return rate ? `${(rate * 100).toFixed(4)}%` : "N/A";
  };

  const findOldAgeRate = (
    property: string,
    rates: ImssRates,
    umaLimit: number
  ) => {
    const oldAgeRates = rates.oldAge.oldAge;
    const rate = oldAgeRates.find((rate) => rate.umaLimit === umaLimit);
    return rate ? `${(rate.rate * 100).toFixed(4)}%` : "N/A";
  };

  const renderOldAgeRateRow = (title: string, umaLimit: number) => {
    return (
      <TableRow>
        <TableCell>
          <Typography fontStyle="italic" sx={{ paddingLeft: 4 }}>
            {title}
          </Typography>
        </TableCell>
        <TableCell>
          {findOldAgeRate("oldAge.oldAge", imssEmployerRates, umaLimit)}
        </TableCell>
        <TableCell>
          {findOldAgeRate("oldAge.oldAge", imssEmployeeRates, umaLimit)}
        </TableCell>
      </TableRow>
    );
  };

  const renderRateRow = (title: string, property: string) => {
    return (
      <TableRow>
        <TableCell>{title}</TableCell>
        <TableCell>{renderRate(property, imssEmployerRates)}</TableCell>
        <TableCell>{renderRate(property, imssEmployeeRates)}</TableCell>
      </TableRow>
    );
  };

  const renderTitleRow = (title: string) => {
    return (
      <TableRow>
        <TableCell sx={{ fontWeight: "bold" }}>
          <Typography variant="h6">{title}</Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <Box>
        <Typography variant="h5">IMSS Rates</Typography>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Concept</TableCell>
            <TableCell>Employer rate</TableCell>
            <TableCell>Employee rate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTitleRow("Enfermedades y Maternidad")}
          {renderRateRow("Cuota fija", "sicknessAndMaternity.fixedFee.rate")}
          {renderRateRow("Excedente", "sicknessAndMaternity.surplus.rate")}
          {renderRateRow(
            "Prestaciones en dinero",
            "sicknessAndMaternity.cashBenefits.rate"
          )}
          {renderRateRow(
            "Prestaciones en especie",
            "sicknessAndMaternity.pensionersAndBeneficiaries.rate"
          )}
          {renderTitleRow("Invalidez y vida")}
          {renderRateRow(
            "Invalidez y vida",
            "disabilityAndLife.disabilityAndLife.rate"
          )}
          {renderTitleRow("Riesgos de trabajo")}
          {renderRateRow("Riesgos de trabajo", "workRisk.workRisk.rate")}
          {renderTitleRow("Guarderías y prestaciones sociales")}
          {renderRateRow(
            "Guarderías y prestaciones sociales",
            "daycareAndSocialBenefits.daycareAndSocialBenefits.rate"
          )}
          {renderTitleRow(
            "Seguro de retiro, cesantía en edad avanzada y vejez"
          )}
          {renderRateRow("Retiro", "oldAge.retirement.rate")}

          <TableRow>
            <TableCell>Cesantía en edad avanzada y vejez</TableCell>
          </TableRow>
          {renderOldAgeRateRow("Hasta por 1 UMA", 1)}
          {renderOldAgeRateRow("Hasta por 1.5 UMA", 1.5)}
          {renderOldAgeRateRow("Hasta por 2 UMA", 2)}
          {renderOldAgeRateRow("Hasta por 2.5 UMA", 2.5)}
          {renderOldAgeRateRow("Hasta por 3 UMA", 3)}
          {renderOldAgeRateRow("Hasta por 3.5 UMA", 3.5)}
          {renderOldAgeRateRow("Hasta por 4 UMA", 4)}
          {renderOldAgeRateRow("Más de 4 UMA", 1000)}
        </TableBody>
      </Table>
    </Box>
  );
};
