import { IAttendanceRecord, IAttendanceRecordBase } from "../shared/types";
import { BaseService } from "./base.service";

export class AttendanceRecordService extends BaseService<
  IAttendanceRecord,
  IAttendanceRecordBase
> {
  constructor() {
    super("/attendance-records");
  }
}
