import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  JobList,
  JobView,
  //  EditJob, NewJob
} from ".";
import { EditJob } from "./edit";
import { NewJob } from "./new/NewJob";

export const JobsRouter = () => {
  return (
    <Routes>
      <Route index element={<JobList />} />
      <Route path="new" element={<NewJob />} />
      <Route path="edit/:id" element={<EditJob />} />
      <Route path=":id" element={<JobView />} />

      <Route path="*" element={<JobList />} />
    </Routes>
  );
};
