import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  AttendanceRecordReport,
  AttendanceRecordsAdminPanelIndex,
  AttendanceRecordsHistoryIndex,
  AttendanceRecordsIndex,
  AttendanceRecordView,
  AttendanceRecordReportsIndex,
} from ".";
import { useAuthHook } from "../../../hooks";

export const AttendanceRecordsRouter = () => {
  const { isAdminOrManager } = useAuthHook();
  return (
    <Routes>
      <Route index element={<AttendanceRecordsIndex />} />
      <Route path="history" element={<AttendanceRecordsHistoryIndex />} />
      {isAdminOrManager && (
        <Route
          path="admin-panel"
          element={<AttendanceRecordsAdminPanelIndex />}
        />
      )}
      <Route path="report" element={<AttendanceRecordReport />} />
      {isAdminOrManager && (
        <Route path="reports" element={<AttendanceRecordReportsIndex />} />
      )}
      <Route path=":id" element={<AttendanceRecordView />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};
