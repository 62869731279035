import React from "react";
import { DayOfWeek } from "../../../../../shared/enums/date.enum";
import { BranchForm } from "../components/BranchForm";
import { IBranchBase } from "../../../../../shared/types";

export const NewBranch = () => {
  const initialValues: IBranchBase = {
    name: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    },
    phoneNumber: "",
    whatsappNumber: "999 184 14 34",
    geoLocation: {
      latitude: 0,
      longitude: 0,
    },
    openingHours: [
      {
        day: DayOfWeek.Monday,
        open: "",
        close: "",
      },
      {
        day: DayOfWeek.Tuesday,
        open: "",
        close: "",
      },
      {
        day: DayOfWeek.Wednesday,
        open: "",
        close: "",
      },
      {
        day: DayOfWeek.Thursday,
        open: "",
        close: "",
      },
      {
        day: DayOfWeek.Friday,
        open: "",
        close: "",
      },
      {
        day: DayOfWeek.Saturday,
        open: "",
        close: "",
      },
      {
        day: DayOfWeek.Sunday,
        open: "",
        close: "",
      },
    ],
  };

  return <BranchForm initialValues={initialValues} />;
};
