import { POSITION_ORDERS } from "../shared";
import { ICollaborator, WebAppRole } from "../shared/types/collaborators.types";

export const getCollaboratorAvatarDataById = (
  collaborators: ICollaborator[],
  id: string
) => collaborators.find((c) => c.id === id);

export const getCollaboratorImgUrlById = (
  collaborators: ICollaborator[],
  id: string
) => collaborators.find((c) => c.id === id)?.imgUrl;

export const getCollaboratorById = (
  collaborators: ICollaborator[],
  id: string
) => collaborators.find((c) => c.id === id);

export const getCollaboratorAvatarPropsById = (
  collaborators: ICollaborator[],
  id: string
) => {
  const collaborator = collaborators.find((c) => c.id === id);
  return {
    id: collaborator!.id!,
    first_name: collaborator!.first_name!,
    imgUrl: collaborator!.imgUrl || "",
  };
};

export const getActiveCollaborators = (collaborators: ICollaborator[]) =>
  [...collaborators]
    .filter((c) => c.isActive)
    .sort((a, b) => a.col_code.localeCompare(b.col_code));

export const getActiveCollaboratorsIds = (collaborators: ICollaborator[]) =>
  [...collaborators]
    .filter((c) => c.isActive)
    .sort((a, b) => a.col_code.localeCompare(b.col_code))
    .map((c) => c.id);

export const getSortedCollaborators = (collaborators: ICollaborator[]) =>
  [...collaborators].sort((a, b) => a.col_code.localeCompare(b.col_code));

export const isAuthorizedByRole = (
  role?: WebAppRole,
  authorizedRoles: WebAppRole[] = [WebAppRole.admin, WebAppRole.manager]
) => {
  if (!role) return false;
  return authorizedRoles.includes(role);
};

export const checkIfManagerOrAdmin = (role: WebAppRole) => {
  return isAuthorizedByRole(role, [WebAppRole.admin, WebAppRole.manager]);
};

export const checkIfAdmin = (role: WebAppRole) => {
  return isAuthorizedByRole(role, [WebAppRole.admin]);
};

// Function to check if the role has the required level or higher
export const hasAuthorization = (
  role?: WebAppRole,
  requiredRole: WebAppRole = WebAppRole.manager
): boolean => {
  if (!role) return false;
  const roleHierarchy = {
    [WebAppRole.admin]: 4,
    [WebAppRole.manager]: 3,
    [WebAppRole.collaborator]: 2,
    [WebAppRole.user]: 1,
    [WebAppRole.guest]: 0,
  };
  return roleHierarchy[role] >= roleHierarchy[requiredRole];
};

export const sortCollaboratorsByPosition = (collaborators: ICollaborator[]) => {
  const positionMap = new Map<string, number>(
    POSITION_ORDERS.map((title, index) => [title.toLowerCase(), index])
  );

  const sortedCollaborators = [...collaborators].sort((a, b) => {
    const positionA =
      positionMap.get(a.position?.toLowerCase() ?? "") ?? Infinity;
    const positionB =
      positionMap.get(b.position?.toLowerCase() ?? "") ?? Infinity;

    return positionA - positionB;
  });

  return sortedCollaborators;
};

export const generateCollaboratorCode = (
  firstName: string,
  lastName: string
): string => {
  // Split names into words
  const firstNameParts = firstName.split(" ");
  const lastNameParts = lastName.split(" ");

  let code = "";

  // Scenario 1: One or two words in firstName, and two words in lastName
  if (lastNameParts.length === 2) {
    code = `${firstNameParts[0][0]}${lastNameParts[0][0]}${lastNameParts[1][0]}`;
  }
  // Scenario 2: Two words in firstName, and one word in lastName
  else if (firstNameParts.length === 2 && lastNameParts.length === 1) {
    code = `${firstNameParts[0][0]}${firstNameParts[1][0]}${lastNameParts[0][0]}`;
  }
  // Scenario 3: One word in firstName and one word in lastName
  else if (firstNameParts.length === 1 && lastNameParts.length === 1) {
    code = `${firstNameParts[0][0]}${firstNameParts[0][1]}${lastNameParts[0][0]}`;
  }

  // Ensure the code is uppercase
  return code.toUpperCase();
};

export const getCollaboratorThumbailUrl = (collaborator: ICollaborator) => {
  const mainImage = collaborator?.images?.find(
    (image) => image.isMain
  )?.thumbnailUrl;

  const anyImage = collaborator?.images?.[0]?.thumbnailUrl;

  return (
    mainImage ||
    anyImage ||
    collaborator?.imgUrl ||
    "https://upload.wikimedia.org/wikipedia/commons/b/bc/Unknown_person.jpg"
  );
};
