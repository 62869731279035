import React from "react";
import { Box, ListItem, Card, CardContent } from "@mui/material";

import { useAppSelector } from "../../../../hooks/useAppSelector";
import { CustomAvatar } from "../../../../components/avatar/CustomAvatar";
import { getCollaboratorById } from "../../../../helpers/collaboratorsHelpers";
import { getTaskNumberAndTitleById } from "../../../../helpers/tasksHelpers";
import { Link } from "react-router-dom";
import { WorkLog } from "../../../../shared/types/WorkLog";

type Props = {
  log: WorkLog;
};

export const CollaboratorLog: React.FC<Props> = ({ log }) => {
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);
  const { tasks } = useAppSelector((state) => state.taskReducer);

  return (
    <Card>
      <CardContent style={{ display: "flex", alignItems: "center" }}>
        <CustomAvatar
          collaborator={getCollaboratorById(collaborators, log.createdBy!)!}
        />
        <Box>
          {log.activities.map((activity, index) => (
            <ListItem key={index}>
              <span>
                <strong>
                  <Link
                    className="task-link"
                    to={`/dashboard/tasks/${activity.relatedTask}`}
                  >
                    {(activity.relatedTask &&
                      getTaskNumberAndTitleById(tasks, activity.relatedTask)) ??
                      "NO TASK"}
                  </Link>
                </strong>
                - {activity.content}
              </span>
            </ListItem>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};
