import React from "react";
import { Field } from "formik";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { IOption } from "../../shared/types/option.types";

interface Props {
  label: string;
  name: string;
}

export const FormikSwitch: React.FC<Props> = ({ label, name }) => {
  return (
    <FormControlLabel
      control={
        <Field as={Switch} type="checkbox" name={name} color="primary" />
      }
      label="Active"
    />
  );
};
