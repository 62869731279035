import React from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik, Form, FieldArray, FormikTouched, FormikErrors } from "formik";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { useAppDispatch } from "../../../../../hooks";
import { IPublicHolidays } from "../../../../../shared/types";
import { publicHolidaysThunks } from "../../../../../store/thunks";
import dayjs from "dayjs";
import { SimpleFormikTextField } from "../../../../../components/form/SimpleFormikTextField";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  year: Yup.number()
    .required("Year is required")
    .min(1900, "Year must be valid"),
  publicHolidays: Yup.array()
    .of(
      Yup.string()
        .required("Date is required")
        .test("valid-date", "Invalid date", (value) => dayjs(value).isValid())
    )
    .required("Public holidays are required"),
});

type Props = {
  initialValues: IPublicHolidays;
};

export const PublicHolidayForm = ({ initialValues }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Function to handle form submission
  const handleSubmit = async (values: IPublicHolidays) => {
    try {
      if (values.id) {
        await dispatch(publicHolidaysThunks.updateItem(values)).unwrap();
      } else {
        await dispatch(publicHolidaysThunks.createItem(values)).unwrap();
      }

      navigate("/dashboard/parameters/public-holidays");
    } catch (error) {
      console.error("Error while submitting the form:", error);
    }
  };

  return (
    <Box padding={4}>
      <Typography variant="h5" marginBottom={2}>
        Create Public Holidays for a Year
      </Typography>

      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <FieldArray name="publicHolidays">
                {({ push, remove }) => (
                  <Grid item xs={12}>
                    <Box>
                      <SimpleFormikTextField
                        name="year"
                        label="Year"
                        type="number"
                        onChange={(e) => {
                          const newYear = Number(e.target.value);
                          setFieldValue("year", newYear);

                          const holidays = [
                            `${newYear}-01-01`, // New Year's Day
                            `${newYear}-02-05`, // Constitution Day
                            `${newYear}-03-18`, // Benito Juárez's Birthday
                            `${newYear}-05-01`, // Labor Day
                            `${newYear}-09-16`, // Independence Day
                            `${newYear}-11-18`, // Revolution Day
                            `${newYear}-12-25`, // Christmas
                          ];

                          setFieldValue("publicHolidays", holidays);
                        }}
                      />
                    </Box>
                    <Typography variant="h6" marginBottom={2}>
                      Public Holidays
                    </Typography>

                    {values.publicHolidays.map((holiday, index) => (
                      <Grid
                        container
                        spacing={2}
                        key={index}
                        alignItems="center"
                        marginBottom={2}
                      >
                        <Grid item>
                          <TextField
                            label={`Holiday #${index + 1}`}
                            name={`publicHolidays[${index}]`}
                            type="date"
                            value={dayjs(holiday).format("YYYY-MM-DD")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              (
                                touched.publicHolidays as
                                  | FormikTouched<string[]>
                                  | undefined
                              )?.[index] &&
                              Boolean(
                                (
                                  errors.publicHolidays as
                                    | FormikErrors<string[]>
                                    | undefined
                                )?.[index]
                              )
                            }
                            helperText={
                              (
                                touched.publicHolidays as
                                  | FormikTouched<string[]>
                                  | undefined
                              )?.[index] &&
                              (
                                errors.publicHolidays as
                                  | FormikErrors<string[]>
                                  | undefined
                              )?.[index]
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            color="secondary"
                            onClick={() => remove(index)}
                            disabled={values.publicHolidays.length === 1}
                          >
                            <RemoveCircle />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}

                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddCircle />}
                      onClick={() => push("")}
                      style={{ marginTop: "16px" }}
                    >
                      Add Holiday
                    </Button>
                  </Grid>
                )}
              </FieldArray>
            </Grid>
            <Box display="flex" justifyContent="center" marginTop={2}>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
