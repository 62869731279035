import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { TimeOffRequestsSummaryOverview } from "./TimeOffRequestsSummaryOverview";
import { TimeOffRequestsUpcomingOverview } from "./TimeOffRequestsUpcomingOverview";
import { TimeOffRequestsCollaboratorInfo } from "./TimeOffRequestsCollaboratorInfo";
import { TimeOffRequestsCollaboratorsSummary } from "../TimeOffRequestsCollaboratorsSummary";
import { isAuthorizedByRole } from "../../../../helpers";

// Your TypeScript file

// Rest of your code

export const TimeOffRequestsHome: React.FC = () => {
  const { isAllLoading } = useAppSelector(
    (state) => state.timeOffRequestsReducer
  );

  const { role } = useAppSelector((state) => state.authReducer.user!);
  const isAuthorized = isAuthorizedByRole(role!);

  return (
    <>
      {isAllLoading && <CircularProgress />}
      {!isAllLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TimeOffRequestsUpcomingOverview />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TimeOffRequestsCollaboratorInfo />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TimeOffRequestsSummaryOverview />
          </Grid>
          {isAuthorized && (
            <Grid item xs={12} sm={6}>
              <TimeOffRequestsCollaboratorsSummary />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};
