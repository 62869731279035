import hvpAuthenticatedApi from "../api/hvpAuthenticatedApi";
import { IJob } from "../shared/types";
import { IOption } from "../shared/types/option.types";
import { ImageUrl } from "../shared/value-objects";
import { BaseService } from "./base.service";

export class ImageService extends BaseService<IJob> {
  constructor() {
    super("/images");
  }

  async uploadFromBuffer(
    file: File,
    publicId: string,
    folder: string
  ): Promise<ImageUrl> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("publicId", publicId);
    formData.append("folder", folder);

    const response = await hvpAuthenticatedApi.post(
      "/images/upload-from-buffer",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data.data;
  }

  async deleteImage(publicId: string): Promise<any> {
    const response = await hvpAuthenticatedApi.delete(
      `/images/delete/${publicId}`
    );
    return response.data;
  }
}
