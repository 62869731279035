import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IWeekShift } from "../../shared/types";
import { weekShiftThunks } from "../thunks";

interface State {
  weekShifts: IWeekShift[];
  activeWeekShift?: IWeekShift;
  isLoading: boolean;
  isInitialLoading: boolean;
}

const initialState: State = {
  weekShifts: [],
  activeWeekShift: undefined,
  isLoading: true,
  isInitialLoading: true,
};

export const weekShiftsSlice = createSlice({
  name: "weekShift",
  initialState,
  reducers: {
    setActiveWeekShift: (state, action: PayloadAction<IWeekShift>) => {
      state.activeWeekShift = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(weekShiftThunks.fetchItems.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(weekShiftThunks.fetchItems.fulfilled, (state, action) => {
      state.weekShifts = action.payload;
      state.isLoading = false;
      state.isInitialLoading = false;
    });
    builder.addCase(weekShiftThunks.fetchItems.rejected, (state) => {
      state.isLoading = false;
      state.isInitialLoading = false;
    });
    builder.addCase(weekShiftThunks.getItemById.fulfilled, (state, action) => {
      state.activeWeekShift = action.payload;
    });
    builder.addCase(weekShiftThunks.getItemById.rejected, (state) => {
      state.activeWeekShift = undefined;
    });
    builder.addCase(weekShiftThunks.createItem.fulfilled, (state, action) => {
      state.weekShifts.push(action.payload);
    });
    builder.addCase(weekShiftThunks.updateItem.fulfilled, (state, action) => {
      const index = state.weekShifts.findIndex(
        (weekShifts) => weekShifts.id === action.payload.id
      );
      state.weekShifts[index] = action.payload;
    });
    builder.addCase(weekShiftThunks.deleteItem.fulfilled, (state, action) => {
      state.weekShifts = state.weekShifts.filter(
        (weekShifts) => weekShifts.id !== action.payload
      );
    });
  },
});

export default weekShiftsSlice.reducer;
