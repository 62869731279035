import React from "react";
import { PublicHolidayForm } from "../components/";
import { IPublicHolidays } from "../../../../../shared/types";

export const NewPublicHolidays = () => {
  const currentYear = new Date().getFullYear();
  const initialValues: IPublicHolidays = {
    year: currentYear,
    publicHolidays: [
      `${currentYear}-01-01`,
      `${currentYear}-02-05`,
      `${currentYear}-03-18`,
      `${currentYear}-05-01`,
      `${currentYear}-09-16`,
      `${currentYear}-11-18`,
      `${currentYear}-12-25`,
    ],
  };

  return <PublicHolidayForm initialValues={initialValues} />;
};
