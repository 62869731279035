import { Avatar, AvatarProps, Tooltip } from "@mui/material";
import React from "react";
import { ICollaborator } from "../../shared/types";
import { getCollaboratorThumbailUrl } from "../../helpers";

type CustomAvatarProps = AvatarProps & {
  id?: string;

  collaborator: ICollaborator;
};
export const CustomAvatar = ({
  id,
  collaborator,
  ...avatarProps
}: CustomAvatarProps) => {
  return (
    <Tooltip title={collaborator?.col_code} key={id}>
      <Avatar
        alt={collaborator?.col_code}
        src={getCollaboratorThumbailUrl(collaborator)}
        {...avatarProps}
      />
    </Tooltip>
  );
};
