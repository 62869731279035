import React from "react";

import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { ICollaborator } from "../../../../shared/types";
import { CustomAvatar } from "../../../../components/avatar/CustomAvatar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useAppSelector } from "../../../../hooks";

type Props = {
  collaborators: ICollaborator[];
  isCollapsed: boolean;
  toggleSection: (section: "collaboratorsCards") => void;
};

export const WeeksShiftsCollaboratorsSection = ({
  collaborators,
  isCollapsed,
  toggleSection,
}: Props) => {
  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6">CollaboratorCards</Typography>
        <IconButton onClick={() => toggleSection("collaboratorsCards")}>
          {!isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={!isCollapsed}>
        <Droppable
          droppableId="collaborators"
          direction="horizontal"
          isDropDisabled={true}
        >
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}
            >
              {collaborators.map((collaborator, index) => (
                <Draggable
                  key={collaborator.id}
                  draggableId={collaborator.id!.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        cursor: "pointer",
                        backgroundColor: snapshot.isDragging
                          ? "lightgreen"
                          : "inherit",
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        padding: "8px",
                        border: "1px solid lightgrey",
                        borderRadius: "8px",
                        minWidth: "100px",
                        justifyContent: "center",
                      }}
                    >
                      <CustomAvatar collaborator={collaborator} />
                      <Typography variant="body2">
                        {collaborator.col_code}
                      </Typography>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Collapse>
    </Box>
  );
};
