import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAuthHook,
  useCollaboratorHook,
} from "../../../../../hooks";
import {
  ImageList,
  ImageListItem,
  IconButton,
  Box,
  Typography,
  Button,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import { ImageService } from "../../../../../services";
import { fireSwalError } from "../../../../../helpers/utilities";
import { collaboratorThunks } from "../../../../../store/thunks";

export const CollaboratorImages = () => {
  const { isOwnerOrManager } = useAuthHook();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { getCollaboratorById } = useCollaboratorHook();
  const collaborator = getCollaboratorById(id!);
  const [isLoading, setisLoading] = useState(false);

  const { images = [] } = collaborator || {};
  const handleAddImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (images.length >= 5) {
      return await fireSwalError("You can only add up to 5 images.");
    }
    if (event.target.files) {
      setisLoading(true);
      const freeIndex = getFirstAvailableNumber(images);
      const file = event.target.files[0];
      const imageService = new ImageService();
      const response = await imageService.uploadFromBuffer(
        file,
        `${collaborator!.col_code}-${freeIndex}`,
        "hvp/collaborators"
      );
      console.log({ response });
      dispatch(
        collaboratorThunks.updateItem({
          ...collaborator!,
          images: [...images, response],
        })
      );
      setisLoading(false);
    }
  };

  const handleDeleteImage = async (publicId: string) => {
    const imageService = new ImageService();
    await imageService.deleteImage(publicId);
    dispatch(
      collaboratorThunks.updateItem({
        ...collaborator!,
        images: images.filter((image) => image.publicId !== publicId),
      })
    );
  };

  const handleSetMainImage = async (publicId: string) => {
    setisLoading(true);
    await dispatch(
      collaboratorThunks.updateItem({
        ...collaborator!,
        images: images.map((image) => ({
          ...image,
          isMain: image.publicId === publicId,
        })),
      })
    );
    setisLoading(false);
  };

  if (!collaborator) {
    return <div>Collaborator not found</div>;
  }

  const isAuthorized = isOwnerOrManager(collaborator?.id);

  if (!isAuthorized) {
    return <div>You are not authorized to view this page</div>;
  }

  return (
    <div>
      <Typography variant="h6">Collaborator Images</Typography>
      <ImageList cols={3} gap={8}>
        {images.map((image) => (
          <ImageListItem key={image.publicId} sx={{ position: "relative" }}>
            <img
              src={image.url}
              alt={`Collaborator ${image.publicId}`}
              loading="lazy"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              onClick={() => handleSetMainImage(image.publicId)} // Click to set as main image
            />
            {image.isMain && (
              <Box
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  borderRadius: "50%",
                  padding: "2px",
                }}
              >
                <StarIcon color="primary" fontSize="small" />
              </Box>
            )}
            <IconButton
              sx={{
                position: "absolute",
                top: 5,
                left: 5,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
              }}
              onClick={() => handleDeleteImage(image.publicId)}
            >
              <DeleteIcon />
            </IconButton>
          </ImageListItem>
        ))}
      </ImageList>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="upload-image"
        type="file"
        onChange={handleAddImage}
      />
      <label htmlFor="upload-image">
        <Button
          component="span"
          color="primary"
          disabled={images.length >= 5 || isLoading} // Disable if there are already 5 images
          startIcon={<AddPhotoAlternateIcon />}
        >
          Add Image
        </Button>
      </label>
      {images.length >= 5 && (
        <Typography variant="body2" color="error">
          Maximum of 5 images reached.
        </Typography>
      )}
    </div>
  );
};

export const getFirstAvailableNumber = (
  images: { publicId: string }[]
): number => {
  const usedNumbers = images
    .map((image) => {
      const match = image.publicId.match(/\d+$/); // Extract the number at the end
      return match ? parseInt(match[0]) : null;
    })
    .filter((number): number is number => number !== null); // Filter out null values

  for (let i = 1; i <= 5; i++) {
    if (!usedNumbers.includes(i)) {
      return i; // Return the first number that is not used
    }
  }

  return -1; // Return -1 if no available number is found
};
