import { createAsyncThunk } from "@reduxjs/toolkit";
import { CollaboratorService } from "../../services";
import { createCRUDThunks, handleAsyncThunkError } from "../../shared/helpers";
import {
  AxiosParams,
  ICollaborator,
  ICollaboratorBase,
} from "../../shared/types";

const service = new CollaboratorService();

const resourceName = "collaborators";
const fetchCollaboratorsForWeb = createAsyncThunk(
  `${resourceName}/fetchCollaboratorsForWeb`,
  async (params: AxiosParams | undefined = {}, { rejectWithValue }) => {
    try {
      const data = await service.getCollaboratorsForWeb();
      return data;
    } catch (error) {
      return handleAsyncThunkError(error, rejectWithValue);
    }
  }
);

const registerCollaborator = createAsyncThunk(
  `${resourceName}/registerCollaborator`,
  async (collaborator: ICollaboratorBase, { rejectWithValue }) => {
    try {
      const data = await service.registerCollaborator(collaborator);
      return data;
    } catch (error) {
      return handleAsyncThunkError(error, rejectWithValue);
    }
  }
);

const crudThunks = createCRUDThunks<ICollaborator, ICollaboratorBase>({
  service,
  resourceName,
});

export const collaboratorThunks = {
  ...crudThunks,
  fetchCollaboratorsForWeb,
  registerCollaborator,
};
