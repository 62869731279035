import React from "react";
import {
  FormControlLabel,
  FormGroup,
  Switch,
  SwitchProps,
} from "@mui/material";

interface Props extends SwitchProps {
  label?: string;
  labelPlacement?: "start" | "end" | "top" | "bottom";
}

export const CustomSwitch: React.FC<Props> = ({
  label = "",
  labelPlacement = "end",
  ...switchProps
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch {...switchProps} />}
        label={label}
        labelPlacement={labelPlacement}
      />
    </FormGroup>
  );
};
