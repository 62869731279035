import React, { useEffect } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { getAllAttendanceRecords } from "../../../../store/thunks/attendance-records-thunks";

import { useAppSelector } from "../../../../hooks/useAppSelector";

import { exportToExcel } from "../../../../helpers/xlsxHelpers";
import { Box, Button, CircularProgress } from "@mui/material";
import { IAttendanceRecord } from "../../../../shared/types";
import { useCollaboratorHook } from "../../../../hooks/useCollaboratorsHook";
import { ICollaborator } from "../../../../shared/types/collaborators.types";
import { getCollaboratorById } from "../../../../helpers";
import { AttendanceRecordsTable } from "../components/AttendanceRecordsTable";
import { useFetchRecords } from "../../../../shared/hooks/useFetchList.hook";
import { AttendanceRecordService } from "../../../../services";

export const transformDataForExport = (
  attendanceRecords: IAttendanceRecord[],
  collaborators: ICollaborator[]
) => {
  const transformedData: any[] = [];
  const excelDateOffset = new Date("1899-12-30T00:00:00Z").getTime();

  attendanceRecords.forEach((record) => {
    if (record.shiftDate && record.startTime) {
      const startTime = new Date(record.startTime);
      const startTimeOffset = new Date(
        startTime.toLocaleString("en-US", { timeZone: "America/Mexico_City" })
      );

      transformedData.push([
        record.clockInBranch,
        getCollaboratorById(collaborators, record.collaborator)?.col_code,
        "Entrada",
        (new Date(record.shiftDate).getTime() - excelDateOffset) /
          (24 * 60 * 60 * 1000),
        (startTimeOffset.getTime() - startTimeOffset.setHours(0, 0, 0, 0)) /
          (24 * 60 * 60 * 1000),
      ]);
    }

    if (record.shiftDate && record.endTime) {
      const endTime = new Date(record.endTime);
      const endTimeOffset = new Date(
        endTime.toLocaleString("en-US", { timeZone: "America/Mexico_City" })
      );

      transformedData.push([
        record.clockOutBranch,
        getCollaboratorById(collaborators, record.collaborator)?.col_code,
        "Salida",
        (new Date(record.shiftDate).getTime() - excelDateOffset) /
          (24 * 60 * 60 * 1000),
        (endTimeOffset.getTime() - endTimeOffset.setHours(0, 0, 0, 0)) /
          (24 * 60 * 60 * 1000),
      ]);
    }
  });

  return transformedData;
};

// TODO: add pagination
// TODO: add filtering by branch
// TODO: add filtering by collaborator

export const AttendanceRecordsAdminPanelIndex = () => {
  const { collaborators } = useCollaboratorHook();

  const {
    records: attendanceRecords,
    loading,
    hasMore,
    loadNextPage,
    count,
  } = useFetchRecords<IAttendanceRecord>({
    service: new AttendanceRecordService(),
    params: {
      direction: "desc",
      sort_by: "shiftDate",
    },
  });

  const handleExportToExcel = () => {
    const transformedData = transformDataForExport(
      attendanceRecords,
      collaborators
    );
    // Call the function to export records to Excel
    exportToExcel(transformedData, "Attendance Records");
  };

  return (
    <>
      <Button onClick={handleExportToExcel} variant="contained" color="primary">
        Export to Excel
      </Button>
      <AttendanceRecordsTable attendanceRecords={attendanceRecords} />
      <Box display="flex" justifyContent="center" mt={2}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button onClick={loadNextPage} disabled={!hasMore}>
            Show More
          </Button>
        )}
      </Box>
      <pre>{JSON.stringify(attendanceRecords.length, null, 2)}</pre>
      <pre>{JSON.stringify(count, null, 2)}</pre>
    </>
  );
};
