import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPublicHolidays } from "../../shared/types";
import { publicHolidaysThunks } from "../thunks";

interface State {
  publicHolidays: IPublicHolidays[];
  activePublicHolidays: IPublicHolidays | null;
  isLoading: boolean;
}

const initialState: State = {
  publicHolidays: [],
  activePublicHolidays: null,
  isLoading: true,
};

export const publicHolidaysSlice = createSlice({
  name: "public-holidays",
  initialState,
  reducers: {
    setActivePublicHolidays: (
      state,
      action: PayloadAction<IPublicHolidays>
    ) => {
      state.activePublicHolidays = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(publicHolidaysThunks.fetchItems.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      publicHolidaysThunks.fetchItems.fulfilled,
      (state, action) => {
        state.publicHolidays = action.payload;
        state.isLoading = false;
      }
    );
    builder.addCase(publicHolidaysThunks.fetchItems.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      publicHolidaysThunks.getItemById.fulfilled,
      (state, action) => {
        state.activePublicHolidays = action.payload;
      }
    );
    builder.addCase(publicHolidaysThunks.getItemById.rejected, (state) => {
      state.activePublicHolidays = null;
    });
    builder.addCase(
      publicHolidaysThunks.createItem.fulfilled,
      (state, action) => {
        state.publicHolidays.push(action.payload);
      }
    );
    builder.addCase(
      publicHolidaysThunks.updateItem.fulfilled,
      (state, action) => {
        const index = state.publicHolidays.findIndex(
          (publicHolidays) => publicHolidays.id === action.payload.id
        );
        state.publicHolidays[index] = action.payload;
      }
    );
    builder.addCase(
      publicHolidaysThunks.deleteItem.fulfilled,
      (state, action) => {
        state.publicHolidays = state.publicHolidays.filter(
          (publicHolidays) => publicHolidays.id !== action.payload
        );
      }
    );
  },
});

export const { setActivePublicHolidays: setActivePublicHoliday } =
  publicHolidaysSlice.actions;
export default publicHolidaysSlice.reducer;
