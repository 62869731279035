import React from "react";
import { Route, Routes } from "react-router-dom";
import { BranchList, EditBranch, NewBranch } from "./";
import { ProductsList } from "../../products";

export const BranchesRouter = () => {
  return (
    <Routes>
      <Route index element={<BranchList />} />
      <Route path="edit/:id" element={<EditBranch />} />
      <Route path="new" element={<NewBranch />} />
      <Route path="*" element={<ProductsList />} />
    </Routes>
  );
};
