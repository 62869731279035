import { BranchNames } from "../shared/types";

export const isValidBranch = (
  branch?: string | null
): branch is BranchNames => {
  if (!branch) return false;
  return Object.values(BranchNames).includes(branch as BranchNames);
};

export const getBranchesAsOptions = () => {
  return Object.values(BranchNames).map((branch) => ({
    id: branch,
    label: branch,
    value: branch,
  }));
};
