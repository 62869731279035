import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ICollaborator } from "../../../../../shared/types";
import { CollaboratorService } from "../../../../../services";
import { CircularProgress } from "@mui/material";
import { CollaboratorForm } from "../../components";

export const EditCollaborator = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<ICollaborator>();

  useEffect(() => {
    if (id) {
      const fetchById = async () => {
        try {
          const service = new CollaboratorService();
          const data = await service.getById(id);
          setInitialValues(data.data);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      fetchById();
    }
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!initialValues) {
    return <div>Collaborator not found</div>;
  }

  return <CollaboratorForm initialValues={initialValues} />;
};
