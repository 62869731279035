import React from "react";

import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import { MenuRoute, ModuleMenu } from "../../../../components";
import { BranchesRouter } from "./BranchesRouter";

export const BranchesLayout = () => {
  console.log("Im in branches layout");
  const routes: MenuRoute[] = [
    { name: "List", path: "" },
    { name: "New", path: "new" },
  ];

  const isLoading = false;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h4">Branches</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ModuleMenu routes={routes} />
      </Grid>
      <Grid item xs={12}>
        {isLoading ? <CircularProgress /> : <BranchesRouter />}
      </Grid>
    </Grid>
  );
};
