import React from "react";
import { Typography } from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";

interface Props {
  boolean: boolean;
  label: string;
}

export const DisplayBooleanWithLabel: React.FC<Props> = ({
  boolean,
  label,
}) => {
  return (
    <Typography
      variant="body1"
      style={{ display: "flex", alignItems: "center" }}
    >
      {label}:
      {boolean ? <CheckCircle color="success" /> : <Cancel color="error" />}
    </Typography>
  );
};
