import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import dayjs from "dayjs";

import { IBranch } from "../../../../../shared/types";

const InfoItem: React.FC<{ icon: React.ReactNode; text: string }> = ({
  icon,
  text,
}) => (
  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
    {icon}
    <Typography variant="body2" color="textSecondary" sx={{ ml: 1 }}>
      {text}
    </Typography>
  </Box>
);

export const BranchCard: React.FC<{
  branch: IBranch;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}> = ({ branch, onEdit, onDelete }) => {
  return (
    <Card
      sx={{
        mb: 4,
        boxShadow: 3,
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h5" component="h2">
            {branch.name}
          </Typography>
          <Box>
            <IconButton color="primary" onClick={() => onEdit(branch.id!)}>
              <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={() => onDelete(branch.id!)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>

        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InfoItem
              icon={<CalendarTodayIcon color="action" />}
              text={`Opened on: ${dayjs(branch.openingDate).format(
                "DD MMM YYYY"
              )}`}
            />
            <InfoItem
              icon={<LocationOnIcon color="action" />}
              text={`${branch.address.line1}, ${
                branch.address.line2 ? `${branch.address.line2}, ` : ""
              }${branch.address.city}, ${branch.address.state} ${
                branch.address.zip
              }, ${branch.address.country}`}
            />
            <InfoItem
              icon={<LocationOnIcon color="action" />}
              text={`${branch.geoLocation.latitude.toFixed(
                6
              )}°, ${branch.geoLocation.longitude.toFixed(6)}°`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoItem
              icon={<PhoneIcon color="action" />}
              text={branch.phoneNumber}
            />
            <InfoItem
              icon={<WhatsAppIcon color="action" />}
              text={branch.whatsappNumber}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />

        <Typography
          variant="h6"
          sx={{ mb: 1, display: "flex", alignItems: "center" }}
        >
          <AccessTimeIcon sx={{ mr: 1 }} /> Opening Hours
        </Typography>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {branch.openingHours.map((hours) => (
                <TableRow key={hours.day}>
                  <TableCell component="th" scope="row">
                    {hours.day}
                  </TableCell>
                  <TableCell align="right">
                    {hours.open && hours.close
                      ? `${hours.open} - ${hours.close}`
                      : "Closed"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
