import React from "react";
import { Route, Routes } from "react-router-dom";
import { WeeksShiftsEdit } from "./edit/WeeksShiftsEditIndex";
import { WeekShiftsIndex } from "./WeekShiftsIndex";

export const WeekShiftsRouter = () => {
  return (
    <Routes>
      <Route index element={<WeekShiftsIndex />} />
      <Route path="edit" element={<WeeksShiftsEdit />} />
      <Route path="*" element={<WeekShiftsIndex />} />
    </Routes>
  );
};
