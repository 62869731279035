import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  BranchesLayout,
  JobsLayout,
  PublicHolidaysLayout,
  SalaryDataLayout,
} from "./";

export const ParametersRouter = () => {
  return (
    <Routes>
      <Route path="branches/*" element={<BranchesLayout />} />
      <Route path="jobs/*" element={<JobsLayout />} />
      <Route path="salary-data/*" element={<SalaryDataLayout />} />
      <Route path="public-holidays/*" element={<PublicHolidaysLayout />} />
    </Routes>
  );
};
