import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  Paper,
} from "@mui/material";
import dayjs from "dayjs";
import { HOUR_IN_MILLISECONDS } from "../../../../shared/constants/time.constants";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  finishCurrentActivityRegister,
  updateActivityRegister,
} from "../../../../store/thunks/activity-register.thunks";
import { useNavigate } from "react-router-dom";

import { fireSwalConfirmation, fireSwalWarning } from "../../../../helpers";

const findLabelByValue = (
  array: { value: string; label: string }[],
  value: string
): string => {
  const item = array.find((item) => item.value === value);
  return item ? item.label : "";
};

export const ActivityRegisterCurrent: React.FC = () => {
  const { currentRegister, activityRegisterTypes } = useAppSelector(
    (state) => state.activityRegisterReducer
  );

  const dispatch = useAppDispatch();
  const [time, setTime] = useState<number>(0);
  const hour = 3600000;
  const minute = 60000;
  const second = 1000;

  const handleOvertimeActivity = useCallback(async () => {
    if (!currentRegister) return;
    // Add finish activity logic here
    await fireSwalWarning(
      "Overtime",
      "This activity is over 8 hours. It is going to be finished automatically with one hour duration. If there is an overlap the activity will be removed."
    );
    await dispatch(finishCurrentActivityRegister(currentRegister));
  }, [currentRegister, dispatch]);

  const handleFinishActivity = async () => {
    if (!currentRegister) return;
    // Add finish activity logic here
    const isConfirmed = await fireSwalConfirmation(
      "¿Estás seguro en dar por terminada esta actividad?"
    );

    if (isConfirmed) {
      dispatch(
        updateActivityRegister({
          ...currentRegister,
          endingTime: new Date(),
        })
      );
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Date.now() - new Date(currentRegister!.startingTime).getTime());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [currentRegister]);

  const navigate = useNavigate();

  const isOverEightHours = time / HOUR_IN_MILLISECONDS > 8;

  useEffect(() => {
    if (isOverEightHours) {
      handleOvertimeActivity();
    }
  }, [isOverEightHours, handleOvertimeActivity]);

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Current Activity
      </Typography>

      <Card>
        <CardContent>
          <Typography variant="h5" component="h3" gutterBottom>
            {findLabelByValue(activityRegisterTypes, currentRegister!.activity)}
          </Typography>
          <Typography variant="body1" paragraph>
            {currentRegister!.desc}
          </Typography>

          <Typography variant="body2">
            Fecha de inicio:{" "}
            {dayjs(currentRegister!.startingTime).format("DD/MMM/YYYY")}
          </Typography>
          <Typography variant="body2">
            Hora de inicio:{" "}
            {dayjs(currentRegister!.startingTime).format("HH:mm")}
          </Typography>
          <Box my={2}>
            <Typography variant="h4" component="span">
              {`${("0" + Math.floor((time / hour) % 60)).slice(-2)}:
                ${("0" + Math.floor((time / minute) % 60)).slice(-2)}:
                ${("0" + Math.floor((time / second) % 60)).slice(-2)}`}
            </Typography>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            onClick={() => navigate(`${currentRegister!.id}`)}
          >
            Edit current register
          </Button>
          <Button variant="contained" onClick={handleFinishActivity}>
            Finish activity
          </Button>
        </CardActions>
      </Card>
    </Paper>
  );
};
