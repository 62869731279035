import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CustomDateField } from "../../../../components/form/CustomDateField";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import dayjsWithPlugins from "../../../../config/dayjs";
import {
  filterTasksByDateRange,
  getFormattedMxDate,
  getNewEndDate,
  printDocument,
} from "../../../../helpers";
import { ViewType } from "./TaskStats";

import { TaskStatsDashboard } from "./TaskStatsDashboard";
import { OtherTasks } from "./reportsComponents/OtherTasks";
import { ReportCompletedTasks } from "./reportsComponents/ReportCompletedTasks";
import { ReportCancelledTasks } from "./reportsComponents/ReportCancelledTasks";
import { ReportTasksWithProgress } from "./reportsComponents/ReportTasksWithProgress";
import { ReportNewTasks } from "./reportsComponents/ReportNewTasks";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { startInitialWorkLogsLoading as startInitialLoadingWorkLogs } from "../../../../store/thunks/workLogsThunks";

export const TaskReport = () => {
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<Date>(new Date(1900, 1, 1));
  const [endDate, setEndDate] = useState<Date>(new Date(2099, 11, 31));
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const start_date = query.get("start_date");
  const end_date = query.get("end_date");
  const newEndDate = getNewEndDate(endDate);
  const { tasks } = useAppSelector((state) => state.taskReducer);
  const { isInitialLoading: isInitialLoadingWorkLogs } = useAppSelector(
    (state) => state.workLogsReducer
  );

  const filteredTasks = filterTasksByDateRange(tasks, startDate, newEndDate);

  type ComponentTitleProps = { title: string };
  const ComponentTitle = ({ title }: ComponentTitleProps) => {
    return (
      <Typography variant="h4" align="center" mb={2}>
        {title}
      </Typography>
    );
  };

  useEffect(() => {
    setStartDate(
      start_date
        ? dayjsWithPlugins(start_date).startOf("day").toDate()
        : dayjsWithPlugins().subtract(7, "day").startOf("day").toDate()
    );
    setEndDate(
      end_date
        ? dayjsWithPlugins(end_date).startOf("day").toDate()
        : dayjsWithPlugins().startOf("day").toDate()
    );
  }, [start_date, end_date]);

  useEffect(() => {
    dispatch(startInitialLoadingWorkLogs());
  }, [dispatch]);

  if (isInitialLoadingWorkLogs) {
    return <CircularProgress />;
  }
  return (
    <Box>
      <Box>
        <Button onClick={printDocument}>Export to PDF</Button>
      </Box>

      {/* Set dates */}
      <Box display="flex" justifyContent="center" margin={2}>
        <Box display="flex" alignItems="center">
          <CustomDateField
            date={startDate}
            label="Start Date"
            setDate={setStartDate}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <CustomDateField
            date={endDate}
            label="End Date"
            setDate={setEndDate}
          />
        </Box>
      </Box>
      {/* Main component */}
      <Box id="printPDF">
        <Box>
          <Typography
            variant="h3"
            align="center"
            margin={2}
          >{`Report from ${getFormattedMxDate(
            startDate
          )} to ${getFormattedMxDate(endDate)}`}</Typography>
        </Box>
        <Box margin={2} padding={4}>
          <ComponentTitle title="Task Stats" />
          <Box display="flex" justifyContent="center" margin={1}>
            <TaskStatsDashboard
              tasks={filteredTasks}
              startDate={startDate}
              endDate={newEndDate}
              viewType={ViewType.Custom}
            />
          </Box>
        </Box>
        <Box margin={2} padding={4}>
          <ComponentTitle title="Completed Tasks" />
          <ReportCompletedTasks
            tasks={filteredTasks}
            startDate={startDate}
            endDate={newEndDate}
          />
        </Box>
        <Box margin={2} padding={4}>
          <ComponentTitle title="Tasks with Progress" />

          <ReportTasksWithProgress
            tasks={filteredTasks}
            startDate={startDate}
            endDate={newEndDate}
          />
        </Box>
        <Box margin={2} padding={4}>
          <ComponentTitle title="Canceled Tasks" />

          <ReportCancelledTasks
            tasks={filteredTasks}
            startDate={startDate}
            endDate={newEndDate}
          />
        </Box>
        <Box margin={2} padding={4}>
          <ComponentTitle title="Other Tasks" />

          <OtherTasks startDate={startDate} endDate={newEndDate} />
        </Box>
        <Box margin={2} padding={4}>
          <ComponentTitle title="New Tasks" />
          <ReportNewTasks
            tasks={filteredTasks}
            startDate={startDate}
            endDate={newEndDate}
          />
        </Box>
      </Box>
    </Box>
  );
};
