import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  CollaboratorImages,
  CollaboratorIndex,
  CollaboratorOverview,
  CollaboratorsIndex,
  EditCollaborator,
  NewCollaborator,
  ChangePassword,
} from "./";

export const CollaboratorsRouter = () => {
  return (
    <Routes>
      <Route index element={<CollaboratorsIndex />} />
      <Route path=":id/images" element={<CollaboratorImages />} />
      <Route path=":id" element={<CollaboratorIndex />} />
      <Route path="jobs/*" element={<div> Jobs </div>} />
      <Route path="change-password/*" element={<ChangePassword />} />
      <Route path="edit/:id" element={<EditCollaborator />} />
      <Route path="new" element={<NewCollaborator />} />
      <Route path="overview" element={<CollaboratorOverview />} />
      <Route path="*" element={<CollaboratorsIndex />} />
      {/* <Route path="*" element={<WorkShiftsView />} /> */}
    </Routes>
  );
};
