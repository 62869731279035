import { Avatar, Box, Chip, Paper, Typography } from "@mui/material";
import React from "react";
import { Task } from "../../../../shared/types/Task";
import {
  getCollaboratorImgUrlById,
  getPriorityColor,
  getTagColor,
  getFormattedMxDate,
  getCollaboratorById,
  filterActivitiesByDateRange,
} from "../../../../helpers/";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { Link } from "react-router-dom";
import { CustomAvatar } from "../../../../components/avatar/CustomAvatar";

type Props = {
  task: Task;
  showActivities?: boolean;
  startDate?: Date;
  endDate?: Date;
};
export const TaskListCard = ({
  task,
  showActivities = false,
  startDate,
  endDate,
}: Props) => {
  const filteredActivities =
    !showActivities || !startDate || !endDate || !task.activities
      ? []
      : filterActivitiesByDateRange(task.activities, startDate, endDate);
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);
  return (
    <Paper elevation={2}>
      <Box key={task._id} sx={{ minWidth: 275, margin: 1, padding: 1 }}>
        {/* Top row */}
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
          <Avatar
            alt={task.author}
            src={getCollaboratorImgUrlById(collaborators, task.author)}
          />
          <Box sx={{ marginLeft: 1, marginRight: 2 }}>
            <Chip
              style={{
                backgroundColor: getPriorityColor(task.priority),
                marginRight: "5px",
              }}
              size="small"
            />
            <Link
              className="task-link"
              to={`/dashboard/tasks/${task._id}`}
            >{`#${task.number} - ${task.title}`}</Link>
          </Box>
          <Box sx={{ display: "flex", "& > *": { mr: -2, mt: 1, mb: 1 } }}>
            {task.assignees!.map((assignee, index) => (
              <Avatar
                key={index}
                alt={assignee}
                src={getCollaboratorImgUrlById(collaborators, assignee)}
              />
            ))}
          </Box>
        </Box>
        {/* Description row */}
        <Box sx={{ whiteSpace: "pre-line" }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {task.description}
          </Typography>
        </Box>
        {/* last row */}
        <Box display="flex" gap={2}>
          <Typography>{task.status}</Typography>
          <Typography>Req: {getFormattedMxDate(task.requestedAt)}</Typography>
          <Typography>Upd: {getFormattedMxDate(task.updatedAt)}</Typography>
          {/* Tags */}
          {task.tags!.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              variant="outlined"
              size="small"
              style={{
                marginRight: "5px",
                backgroundColor: getTagColor(tag),
              }}
            />
          ))}
        </Box>
        <Box>
          {showActivities && filteredActivities.length > 0 && (
            <>
              <Typography sx={{ fontWeight: "bold" }}>
                Period Activities:
              </Typography>
              {filteredActivities!.map((activity) => (
                <Box mb={1}>
                  <Box display="flex" gap={2} alignItems="center">
                    <CustomAvatar
                      collaborator={
                        getCollaboratorById(collaborators, activity!.author)!
                      }
                      sx={{ width: 30, height: 30 }}
                    />
                    <Typography>
                      {
                        getCollaboratorById(collaborators, activity.author)!
                          .col_code
                      }
                    </Typography>
                    <Typography>
                      {getFormattedMxDate(activity.registeredAt)}
                    </Typography>
                    <Typography>{activity.content}</Typography>
                  </Box>
                </Box>
              ))}
            </>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
