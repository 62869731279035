import React, { useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import { Task } from "../../../../../shared/types/Task";
import { CustomAvatar } from "../../../../../components/avatar/CustomAvatar";
import { getCollaboratorById } from "../../../../../helpers/collaboratorsHelpers";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { getFormattedMxDate } from "../../../../../helpers/dateHelpers";
import { fireSwalError } from "../../../../../helpers";

type Props = {
  task: Task;
  activityId: string;
  onSave: (updatedTask: Task) => void;
};

export const EditableActivity: React.FC<Props> = ({
  task,
  activityId,
  onSave,
}) => {
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);
  const activity = task.activities!.find((a) => a._id === activityId);

  const [editedValue, setEditedValue] = useState<string>(
    activity?.content || ""
  );
  const [isEditable, setIsEditable] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedValue(event.target.value);
  };

  const handleSave = async () => {
    if (!editedValue) {
      fireSwalError("The content must be set");
      setIsEditable(false);
      return;
    }

    const updatedActivities = task.activities!.map((a) =>
      a._id === activityId ? { ...a, content: editedValue } : a
    );

    await onSave({ ...task, activities: updatedActivities });
    setIsEditable(false);
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  return (
    <>
      {isEditable ? (
        <>
          <TextField
            value={editedValue}
            onChange={handleChange}
            multiline
            rows={3}
          />
          <Button onClick={handleSave}>Save</Button>
        </>
      ) : (
        <Box
          sx={{
            border: 2,
            borderColor: "divider",
            mb: 1,
            borderRadius: 1,
            padding: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 8,
          }}
          key={activity!._id}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              marginBottom: 2,
              alignItems: "center",
            }}
          >
            <CustomAvatar
              collaborator={
                getCollaboratorById(collaborators, activity!.author)!
              }
              sx={{ width: 40, height: 40 }}
            />
            <Typography variant="body1">
              {getFormattedMxDate(activity!.registeredAt)}
            </Typography>
          </Box>
          <Typography variant="body1">
            {activity!.content.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index !== activity!.content.split("\n").length - 1 && <br />}
              </React.Fragment>
            ))}
          </Typography>
          <Button onClick={handleEdit}>Edit</Button>
        </Box>
      )}
    </>
  );
};
