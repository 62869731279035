import { Branch, IBranch } from "../types";

export const getBranchName = (
  branches: Partial<IBranch>[],
  branchId?: string
) => {
  return branches.find((branch) => branch.id === branchId)?.name;
};

export const sortBranchesByRelevance = (branches: IBranch[]) => {
  const order = ["urban", "harbor", "montejo"];
  return branches.sort((a, b) => {
    const aIndex = order.findIndex((name) =>
      a.name.toLowerCase().includes(name)
    );
    const bIndex = order.findIndex((name) =>
      b.name.toLowerCase().includes(name)
    );
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    } else if (aIndex !== -1) {
      return -1;
    } else if (bIndex !== -1) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });
};
