import hvpAuthenticatedApi from "../api/hvpAuthenticatedApi";
import { IJob } from "../shared/types/";
import { IOption } from "../shared/types/option.types";
import { BaseService } from "./base.service";

export class JobService extends BaseService<IJob> {
  constructor() {
    super("/jobs");
  }

  async getPaymentTypes(): Promise<IOption[]> {
    const response = await hvpAuthenticatedApi.get("/jobs/payment-types");
    return response.data.data;
  }
}
