import React from "react";
import { AuthTabs } from "./AuthTabs";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import { hasAuthorization } from "../../../helpers";
import { WebAppRole } from "../../../shared/types/collaborators.types";
import { startCheckingNew } from "../../../store/thunks/authThunks";

export const AuthPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.authReducer);
  const role2 = user ? user.role : undefined;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (token) {
      console.log("setting token", token);
      localStorage.setItem("token", token);
      dispatch(startCheckingNew());
    }
  }, []);

  useEffect(() => {
    if (hasAuthorization(role2, WebAppRole.collaborator)) {
      return navigate("/dashboard", { replace: true });
    }

    if (hasAuthorization(role2, WebAppRole.user)) {
      return navigate("/clients", { replace: true });
    }
  }, [role2, navigate]);

  return (
    <div className="auth__main">
      <div className="auth__tabContainer">
        <AuthTabs />

        <div className="mt-5 d-flex justify-content-end">
          <Link to="/">
            <button className="btn btn-lg btn-primary fs-2">
              Regresar a la página principal
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
