import React from "react";
import { Box, Typography, IconButton, Collapse, Button } from "@mui/material";
import {
  ICollaborator,
  ExtendedCollaboratorDayShift,
  CollaboratorDayShift,
} from "../../../../shared/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useFormStatusHook } from "../../../../shared/hooks";
import { useAppDispatch } from "../../../../hooks";
import { setHasUnsavedChanges } from "../../../../store/slice";
import { WeekShiftsTable } from "./WeekShiftsTable";

type Props = {
  shifts: ExtendedCollaboratorDayShift[];
  weekDates: string[];
  collaborators: ICollaborator[];
  isCollapsed: boolean;
  toggleSection: (section: "workShiftsTable") => void;
  handleSubmit: () => void;
  setShifts: React.Dispatch<React.SetStateAction<CollaboratorDayShift[]>>;
};

export const WeekShiftsTableSection = ({
  shifts,
  weekDates,
  collaborators,
  isCollapsed,
  toggleSection,
  handleSubmit,
  setShifts,
}: Props) => {
  const editable = true;
  const { isSaving, setIsSaving: setIsSaving } = useFormStatusHook();
  const dispatch = useAppDispatch();
  const clearAllShifts = () => {
    setShifts([]);
    dispatch(setHasUnsavedChanges({ hasUnsavedChanges: true }));
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">Work Shifts</Typography>
            <IconButton onClick={() => toggleSection("workShiftsTable")}>
              {!isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Button
              disabled={isSaving || !editable}
              color="error"
              onClick={clearAllShifts}
              sx={{ marginLeft: 2 }} // Add spacing to the left of the button
            >
              Clear all shifts
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
            disabled={isSaving || !editable}
          >
            Save shifts
          </Button>
        </Box>
        <Collapse in={!isCollapsed}>
          <WeekShiftsTable
            shifts={shifts}
            weekDates={weekDates}
            collaborators={collaborators}
            editable={true}
            setShifts={setShifts}
          />
        </Collapse>
      </Box>
    </>
  );
};
