import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  Button,
} from "@mui/material";
import { ICollaborator } from "../../../../shared/types"; // Adjust imports based on your setup
import { CustomAvatar } from "../../../../components/avatar/CustomAvatar";
import { useJobsHook } from "../../../../shared/hooks";
import { DisplayBooleanWithLabel } from "../../../../components";
import { formatDate } from "../../../../shared/helpers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useCollaboratorHook } from "../../../../hooks";
import { collaboratorThunks } from "../../../../store/thunks";
import { ImageUrl } from "../../../../shared/value-objects";
import StarIcon from "@mui/icons-material/Star";

import { CollaboratorImgModal } from "./CollaboratorImgModal";
import ImageIcon from "@mui/icons-material/Image";
import { useNavigate } from "react-router-dom";

type Props = {
  collaborator: ICollaborator;
};
export const CollaboratorCard = ({ collaborator }: Props) => {
  const { handleEditCollaborator } = useCollaboratorHook();
  const { getJobTitleById } = useJobsHook();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDelete = async (id: string) => {
    dispatch(collaboratorThunks.deleteItem(id));
  };

  const [selectedImage, setSelectedImage] = useState<ImageUrl | null>(null);

  const handleImageClick = (image: ImageUrl) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleNavigateToImages = () => {
    navigate(`/dashboard/collaborators/${collaborator.id}/images`);
  };

  return (
    <Card style={{ maxWidth: 800, margin: "0 auto", padding: 20 }}>
      <CardContent>
        <Grid container spacing={4}>
          {/* Main */}
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Box
                  display="flex"
                  justifyContent={{ xs: "center", md: "flex-start" }}
                >
                  <CustomAvatar
                    collaborator={collaborator}
                    sx={{
                      width: { xs: 100, md: 200 },
                      height: { xs: 100, md: 200 },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h5" gutterBottom>
                    {collaborator.first_name} {collaborator.last_name} (
                    {collaborator.col_code})
                  </Typography>
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => handleEditCollaborator(collaborator.id)}
                      sx={{ color: "#2196f3" }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDelete(collaborator.id)}
                      sx={{ color: "#d32f2f" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Typography variant="h6" color="textSecondary">
                  {getJobTitleById(collaborator.jobId!)}
                </Typography>

                <Typography variant="body1">
                  App role: {collaborator?.role}
                </Typography>
                <Typography variant="body1">
                  Email: {collaborator?.email}
                </Typography>
                <Typography variant="body1">
                  Collaborator number: {collaborator.col_numId}
                </Typography>

                <DisplayBooleanWithLabel
                  boolean={collaborator.isActive}
                  label="Active"
                />
                <DisplayBooleanWithLabel
                  boolean={collaborator.isDisplayedWeb}
                  label="Displayed on Web"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}></Grid>
          </Grid>
          {/* General Information */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6">General Information</Typography>
            <Typography variant="body1">
              Gender: {collaborator?.gender || "N/A"}
            </Typography>
            <Typography variant="body1">
              Degree: {collaborator?.degree || "N/A"}
            </Typography>
            <Typography variant="body1">
              Phone: {collaborator?.phoneNumber}
            </Typography>
            <Typography variant="body1">
              Phone 2: {collaborator?.phoneNumber2}
            </Typography>
            <Typography variant="body1">
              Address:{" "}
              {collaborator?.address
                ? Object.values(collaborator.address).filter(Boolean).join(", ")
                : "N/A"}
            </Typography>
            <Typography variant="body1">
              CURP: {collaborator?.curp || "N/A"}
            </Typography>
            <Typography variant="body1">
              IMSS Number: {collaborator?.imssNumber || "N/A"}
            </Typography>
            <Typography variant="body1">
              Emergency Contact: {collaborator?.emergencyContact || "N/A"}
            </Typography>
            <Typography variant="body1">
              Emergency Phone: {collaborator?.emergencyContactPhone || "N/A"}
            </Typography>
          </Grid>

          {/* Job information */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Job Information</Typography>
            <Typography variant="body1">
              Start Date: {formatDate(collaborator?.startDate)}
            </Typography>
            <Typography variant="body1">
              End Date: {formatDate(collaborator?.endDate)}
            </Typography>
            <Typography variant="body1">
              Weekly Hours: {collaborator?.weeklyHours || "N/A"}
            </Typography>
            <DisplayBooleanWithLabel
              boolean={collaborator?.coverShift ?? false}
              label="Cover shifts"
            />
            <Typography variant="body1">
              Payment Type: {collaborator?.paymentType || "N/A"}
            </Typography>
            <Typography variant="body1">
              Additional Compensation:{" "}
              {collaborator?.additionalCompensation || "N/A"}
            </Typography>
            <Typography variant="body1">
              IMSS Enrollment Date:{" "}
              {formatDate(collaborator?.imssEnrollmentDate)}
            </Typography>
          </Grid>

          {/* Web app information */}
          <Grid item xs={12}>
            <Typography variant="h6">Web App Information</Typography>
            <Typography variant="body1">
              Access Code: {collaborator?.accessCode || "N/A"}
            </Typography>
            <DisplayBooleanWithLabel
              boolean={collaborator?.isRegistered}
              label="Registered"
            />
            <Typography variant="body1">
              Registered Date: {formatDate(collaborator?.registeredDate)}
            </Typography>
            <Typography variant="body1">
              Vacations Taken Before 2021:{" "}
              {collaborator?.vacationsTakenBefore2021 || "N/A"}
            </Typography>
          </Grid>

          {/* Presentation */}
          <Grid item xs={12}>
            <Typography variant="h6">Presentation</Typography>
            <Typography variant="body1">
              {collaborator?.textPresentation || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h6">Images</Typography>
              <Button
                variant="outlined"
                startIcon={<ImageIcon />}
                onClick={handleNavigateToImages}
              >
                Manage Images
              </Button>
            </Box>
            {collaborator?.images && collaborator?.images.length > 0 ? (
              <ImageList
                cols={
                  window.innerWidth < 600 ? 2 : window.innerWidth < 960 ? 3 : 5
                } // Adjust based on window size
                gap={4}
              >
                {collaborator?.images.map((image) => (
                  <ImageListItem
                    key={image.publicId}
                    onClick={() => handleImageClick(image)}
                    sx={{ width: 100, height: 100 }}
                  >
                    <img
                      src={image.thumbnailUrl}
                      alt={`Collaborator ${image.publicId}`}
                      loading="lazy"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                    {image.isMain && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          borderRadius: "50%",
                          padding: "2px",
                        }}
                      >
                        <StarIcon color="primary" fontSize="small" />
                      </Box>
                    )}
                  </ImageListItem>
                ))}
              </ImageList>
            ) : (
              <Typography variant="body2">No images available</Typography>
            )}

            <CollaboratorImgModal
              selectedImage={selectedImage}
              handleCloseModal={handleCloseModal}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
