import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import { MenuRoute, ModuleMenu } from "../../../components/menu/ModuleMenu";
import { WeekShiftsRouter } from "./WeeksShiftsRouter";
import { useAuthHook } from "../../../hooks";

export const WeekShiftsLayout = () => {
  const isLoading = false;
  const { isAdminOrManager } = useAuthHook();

  const routes: MenuRoute[] = [
    { name: "Main", path: "" },
    { name: "Edit", path: "edit" },
  ];

  return (
    <Grid container spacing={4}>
      {isAdminOrManager && (
        <Grid item xs={12}>
          <ModuleMenu routes={routes} />
        </Grid>
      )}
      <Grid item xs={12}>
        {isLoading ? <CircularProgress /> : <WeekShiftsRouter />}
      </Grid>
    </Grid>
  );
};
