import React from "react";
import { Grid, Typography } from "@mui/material";
import {
  AcivityRegisterColStats,
  ActivityRegisterCurrent,
  ActivityRegisterNew,
  ActivityRegistersTable,
} from ".";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  fireSwalConfirmation,
  fireSwalError,
  fireSwalQuestionInput,
  fireSwalQuestionSelect,
  getOptionLabel,
  sortArrayByDateProperty,
} from "../../../../helpers";
import { createActivityRegister } from "../../../../store/thunks";

export const ActivityRegiterColView = () => {
  const dispatch = useAppDispatch();

  const {
    currentRegister,
    activityRegisterTypes,
    lastActivityRegister,
    currentCollaboratoractivityRegisters,
  } = useAppSelector((state) => state.activityRegisterReducer);
  const { user } = useAppSelector((state) => state.authReducer);

  const sortedRegisters = sortArrayByDateProperty(
    "startingTime",
    currentCollaboratoractivityRegisters
  );

  const handleContinueLastActivity = async () => {
    if (!lastActivityRegister)
      return await fireSwalError(
        "No tienes ninguna actividad previa registrada"
      );

    const isConfirmed = await fireSwalConfirmation(
      `¿Quieres continuar con la actividad de ${getOptionLabel(
        activityRegisterTypes,
        lastActivityRegister.activity
      )}?`
    );

    if (isConfirmed) {
      dispatch(
        createActivityRegister({
          ...lastActivityRegister,
          startingTime: new Date(),
          endingTime: undefined,
        })
      );
    }
  };

  const handleCreateNewActivity = async () => {
    const activity = await fireSwalQuestionSelect(
      "Select an activity",
      activityRegisterTypes,
      "Select an activity"
    );

    if (!activity) return;
    const desc = await fireSwalQuestionInput(
      "Enter a description",
      "Description"
    );

    dispatch(
      createActivityRegister({
        activity,
        desc,
        collaborator: user!.uid!,
        startingTime: new Date(),
      })
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" component="h1" gutterBottom>
          Activity Register
        </Typography>
      </Grid>

      {currentRegister && (
        <Grid item xs={12}>
          <ActivityRegisterCurrent />
        </Grid>
      )}

      <Grid item xs={12}>
        <ActivityRegisterNew
          onContinueLastActivity={handleContinueLastActivity}
          onCreateNewActivity={handleCreateNewActivity}
        />
      </Grid>

      <Grid item xs={12}>
        <AcivityRegisterColStats />
      </Grid>

      <Grid item xs={12}>
        <ActivityRegistersTable activityRegisters={sortedRegisters} />
      </Grid>
    </Grid>
  );
};
