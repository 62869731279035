import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FormSatusState {
  hasUnsavedChanges: boolean;
  unsavedChangesMessage: string | null;
  isSaving: boolean;
}

const initialState: FormSatusState = {
  hasUnsavedChanges: false,
  unsavedChangesMessage: null,
  isSaving: false,
};

export const formStatusSlice = createSlice({
  name: "unsavedChanges",
  initialState,
  reducers: {
    setHasUnsavedChanges: (
      state,
      action: PayloadAction<{
        hasUnsavedChanges: boolean;
        message?: string;
      }>
    ) => {
      state.hasUnsavedChanges = action.payload.hasUnsavedChanges;
      state.unsavedChangesMessage =
        action.payload.message ??
        "You have unsaved changes. Do you want to leave?";
    },
    clearUnsavedChanges: (state) => {
      state.hasUnsavedChanges = false;
      state.unsavedChangesMessage = null;
    },
    setIsSaving: (state, action: PayloadAction<boolean>) => {
      state.isSaving = action.payload;
    },
  },
});

export const {
  setHasUnsavedChanges: setHasUnsavedChanges,
  clearUnsavedChanges,
  setIsSaving,
} = formStatusSlice.actions;
