import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";
import { Chip, Button, Select, MenuItem, Avatar, Box } from "@mui/material";
import { Task } from "../../../../../shared/types/Task";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import {
  getCollaboratorById,
  getCollaboratorImgUrlById,
  getSortedCollaborators,
} from "../../../../../helpers/collaboratorsHelpers";
import { ICollaborator } from "../../../../../shared/types/collaborators.types";
import { CustomAvatar } from "../../../../../components/avatar/CustomAvatar";

type Props = {
  task: Task;
  onSave: (updatedTask: Task) => void;
};

export const EditableAssignees: React.FC<Props> = ({ task, onSave }) => {
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);
  const sortedCollaborators = getSortedCollaborators(collaborators);
  const [assignees, setAsignees] = useState<string[]>(task.assignees!);
  const [newCollaborator, setNewCollaborator] = useState<string>("");
  const [isEditable, setIsEditable] = useState(false);

  const handleAdd = () => {
    setAsignees([...assignees, newCollaborator]);
    setNewCollaborator("");
  };

  const handleDeleteTag = (tagToDelete: string) => {
    setAsignees(assignees.filter((tag) => tag !== tagToDelete));
  };

  const handleSave = async () => {
    await onSave({ ...task, assignees });
    setIsEditable(false);
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  console.log("assignees", assignees);

  return (
    <>
      {isEditable ? (
        <>
          {assignees.map((assignee) => (
            <Chip
              avatar={
                <Avatar
                  alt="Natacha"
                  src={getCollaboratorImgUrlById(sortedCollaborators, assignee)}
                />
              }
              key={assignee}
              label={
                getCollaboratorById(sortedCollaborators, assignee)?.col_code
              }
              onDelete={() => handleDeleteTag(assignee)}
            />
          ))}
          <Select
            value={newCollaborator}
            onChange={(e) => setNewCollaborator(e.target.value)}
          >
            {sortedCollaborators.map((col: ICollaborator) => (
              <MenuItem key={col.id} value={col.id}>
                {col.col_code}
              </MenuItem>
            ))}
          </Select>
          <Button onClick={handleAdd}>Add</Button>
          <Button onClick={handleSave}>Save</Button>
        </>
      ) : (
        <>
          {assignees.map((assignee) => (
            <Box key={assignee} marginRight={1} marginLeft={1}>
              <CustomAvatar
                collaborator={
                  getCollaboratorById(sortedCollaborators, assignee)!
                }
                sx={{ width: 40, height: 40 }}
              />
            </Box>
          ))}
          <Button onClick={handleEdit}>
            <EditIcon />
          </Button>
        </>
      )}
    </>
  );
};
